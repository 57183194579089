import { FormLayout } from "@shopify/polaris"
import {
  ExternalLabel,
  MultipleChoiceField,
  Select,
  TextField,
} from "components/widgets/CollectPage/forms/fields/CollectFields"
import Form from "components/widgets/CollectPage/forms/Form"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useFormConfig, useFormContext } from "../../FormContext"
import { NODE_TYPE_COUNTRY_TEAM } from "utils/constants/organization/nodeTypes"
import {
  DECENTRALIZED_INVENTORY_DIGITAL_TEAM_COMMUTE,
  DECENTRALIZED_INVENTORY_EXTERNAL_DIGITAL_TEAM_COMMUTE,
} from "utils/constants/collects/centralizationFilesId"
import { useAppContext } from "components/AppContext"
import { Module } from "components/widgets/CollectPage/forms/Module"
import { INVENTORY_TECH_TEAM_INVENTORIES } from "utils/constants/centralization/inventoryItems"
import ITTravelsInventory from "components/widgets/CollectPage/forms/inventories/environment/ITTravelsInventory"
import ExternalUploadMolecule from "views/molecules/upload/worplace/ExternalUploadMolecule"
import UploadOrganism from "views/organisms/upload/UploadOrganism"
import CentralizedUploadMolecule from "views/molecules/upload/CentralizedUploadMolecule"
import TemplateFileLinkMolecule from "views/molecules/files/TemplateFileLinkMolecule"
import { useCollectContext } from "components/widgets/CollectPage/CollectContextProvider"
import ModalTechTeamInventoryChoice, {
  INVENTORY_CHOICE,
} from "views/molecules/collects/tech_team_inventories/ModalTechTeamInventoryChoice"

const YearlyTransportModes = [
  "plane",
  "train",
  "company_car",
  "rental_car",
  "taxi",
]

const fields = [
  "travel_airplane_duration_policy",
  "travel_mobility_subscription_policy",
  "travel_carpool_policy",
  "travel_coworking_policy",
  "travel_other_policy",
  "travel_company_car",
  "travel_company_car_unit",
  "travel_plane",
  "travel_plane_unit",
  "travel_rental_car",
  "travel_rental_car_unit",
  "travel_taxi",
  "travel_taxi_unit",
  "travel_train",
  "travel_train_unit",
  "odd13_it_team_commute_co2",
  "odd13_it_team_remote_average",
  "average_daily_distance",
  "transportation_type",
  "remote_working_days",
  "on_site_days",
  "electric_car",
  "thermal_car",
  "electric_two_wheeler",
  "thermal_two_wheeler",
  "public_transport",
  "walking",
  "tech_team_inventories_source",
]
const translationPath = "13.it_team"

const Objective13ITTeamForm = () => {
  const { t } = useTranslation()
  const { formData, setFormData } = useFormContext()
  const { currentNode } = useAppContext()
  const { collect } = useCollectContext()

  useFormConfig({ fields, translationPath })

  const updateTravelUnit = (unit) => {
    // all travel units should have the same unit
    setFormData({
      ...formData,
      travel_plane_unit: unit,
      travel_train_unit: unit,
      travel_company_car_unit: unit,
      travel_rental_car_unit: unit,
      travel_taxi_unit: unit,
    })
  }

  const isTechTeamInventoryCentralized = !!collect?.centralization
    ?.tech_team_inventories

  const generateTableMarkup = useCallback(() => {
    const items = YearlyTransportModes.map((mode, index) => {
      const id = `travel_${mode}`
      const id_unit = `travel_${mode}_unit`

      return (
        <tr key={`mode-${index}`}>
          <td>{t(`collect:questions.13.it_team.table.${id}.title`)}</td>
          <td>
            <TextField id={id} type="number" />
          </td>
          <td>
            <Select
              id={id_unit}
              value={String((formData && formData.id_unit) ?? "distance")}
              options={[
                {
                  label: t(
                    "collect:questions.13.it_team.table.input_unit.distance"
                  ),
                  value: "distance",
                },
                {
                  label: t(
                    "collect:questions.13.it_team.table.input_unit.CO2_emissions"
                  ),
                  value: "co2_emissions",
                },
              ]}
              onChange={updateTravelUnit}
            />
          </td>
        </tr>
      )
    })
    return (
      <div>
        <ExternalLabel translationPathName={"table"} />
        <table>
          <tbody>{items}</tbody>
        </table>
      </div>
    )
  }, [formData, t])

  const fileId =
    currentNode?.type === NODE_TYPE_COUNTRY_TEAM
      ? DECENTRALIZED_INVENTORY_EXTERNAL_DIGITAL_TEAM_COMMUTE
      : DECENTRALIZED_INVENTORY_DIGITAL_TEAM_COMMUTE

  if (!collect) return null

  return (
    <Form>
      <FormLayout>
        <div className="columns">
          <div className="column">
            <FormLayout>
              <Module module={INVENTORY_TECH_TEAM_INVENTORIES}>
                <TextField
                  id="odd13_it_team_commute_co2"
                  type="number"
                  label={t(
                    "collect:questions.13.it_team.odd13_it_team_commute_co2"
                  )}
                />
                <TextField
                  id="odd13_it_team_remote_average"
                  type="number"
                  label={t(
                    "collect:questions.13.it_team.odd13_it_team_remote_average"
                  )}
                />
                <div className="icon-text-lined">
                  <ExternalLabel translationPathName="tech_team_inventory_file" />
                  {!isTechTeamInventoryCentralized && (
                    <ModalTechTeamInventoryChoice />
                  )}
                </div>
                {collect?.tech_team_inventories_source === INVENTORY_CHOICE ||
                isTechTeamInventoryCentralized ? (
                  <UploadOrganism
                    centralisedComponent={
                      <CentralizedUploadMolecule
                        inventoryType={INVENTORY_TECH_TEAM_INVENTORIES}
                        displayDownloadIcon
                      />
                    }
                    decentralisedComponent={
                      <ExternalUploadMolecule
                        fileIdExternal="decentralized_inventory_external_digital_team_commute"
                        fileId="decentralized_inventory_digital_team_commute"
                        inventoryType={INVENTORY_TECH_TEAM_INVENTORIES}
                        title={t(
                          "collect:questions.13.it_team.tech_team_inventory_file.label"
                        )}
                        precision={t(
                          "collect:questions.13.it_team.tech_team_inventory_file.precision"
                        )}
                        displayDownloadIcon
                        templateSelectionComponent={
                          <TemplateFileLinkMolecule id={fileId} />
                        }
                      />
                    }
                  />
                ) : (
                  <ITTravelsInventory />
                )}
              </Module>
            </FormLayout>
          </div>
          <div className="column">
            <FormLayout>
              <Module module={INVENTORY_TECH_TEAM_INVENTORIES}>
                {generateTableMarkup()}
                <br />
                <TextField
                  id="travel_airplane_duration_policy"
                  type="number"
                  label={t(
                    "collect:questions.13.it_team.travel_airplane_duration_policy"
                  )}
                />
                <MultipleChoiceField
                  id="policies"
                  choices={[
                    "travel_mobility_subscription_policy",
                    "travel_carpool_policy",
                    "travel_coworking_policy",
                  ]}
                  otherField="travel_other_policy"
                />
              </Module>
            </FormLayout>
          </div>
        </div>
      </FormLayout>
    </Form>
  )
}

export default Objective13ITTeamForm
