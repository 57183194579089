import React from "react"
import { PILLAR_ENVIRONMENTAL } from "utils/constants/pillars"
import PropTypes from "prop-types"
import ChartSDG13WebsitesGhgMolecule from "views/molecules/canvases/charts/websites/ChartSDG13WebsitesGhgMolecule"
import ChartQuantitativeCo2ImpactsMolecule from "views/molecules/canvases/charts/websites/ChartQuantitativeCo2ImpactsMolecule"
import ChartSdg13MaturityScoresMolecule from "views/molecules/canvases/charts/websites/ChartSDG13MaturityScoresMolecule"
import ChartSDG13AccessibilityErrorsMolecule from "views/molecules/canvases/charts/websites/ChartSDG13AccessibilityErrorsMolecule"
import ChartSDG13BpPointsMolecule from "views/molecules/canvases/charts/websites/ChartSDG13BpPointsMolecule"
import ScoreLayoutOrganism from "views/organisms/scores/ScoreLayoutOrganism"
import ChartAnalyzeWebsiteSplitPerItDomain from "views/molecules/canvases/charts/websites/ChartAnalyzeWebsiteSplitPerItDomain"
import { useTranslation } from "react-i18next"

const WebsiteAnalyseChartOrganism = ({ score, isOnGlobalDashboard }) => {
  const transSuffix = "analyze:historization.node."
  const websitesScore = score.website_analyse
  const { t } = useTranslation()

  return (
    <ScoreLayoutOrganism
      forceMaturity
      maturity={websitesScore.pillar_websites_maturity}
      pillar={PILLAR_ENVIRONMENTAL}
      transSuffix={transSuffix}
      chart1={
        <ChartSDG13WebsitesGhgMolecule
          websiteEmissions={score.odd13_software_CO2_websites}
          totalEmissions={score.odd13_CO2}
          ghgSingleVisitorEmisison={websitesScore?.ghg_average_visit_CO2}
          hidePercentageOfEmissions={isOnGlobalDashboard}
        />
      }
      chart2={
        <ChartSDG13AccessibilityErrorsMolecule
          accessibilityErrors={websitesScore.accessibility_errors}
          showAverage
        />
      }
      chart3={
        <ChartQuantitativeCo2ImpactsMolecule
          mixedChart={websitesScore.quantitative_co2_impacts}
          title={t(
            "score:score.13.chartGHGWebsitesEmissions.modal.chartQuantitativeCO2Impacts.title"
          )}
        />
      }
      chart4={
        <ChartSdg13MaturityScoresMolecule
          maturityScore={websitesScore.maturity_scores}
        />
      }
      chart5={<ChartSDG13BpPointsMolecule bpPoints={websitesScore.bp_points} />}
      chart6={
        <ChartAnalyzeWebsiteSplitPerItDomain websiteScore={websitesScore} />
      }
    />
  )
}

WebsiteAnalyseChartOrganism.propTypes = {
  score: PropTypes.shape(
    {
      odd13_software_CO2_websites: PropTypes.string.isRequired,
      odd13_CO2: PropTypes.string.isRequired,
      website_analyse: PropTypes.object,
    }.isRequired
  ),
  isOnGlobalDashboard: PropTypes.bool,
}

WebsiteAnalyseChartOrganism.defaultProps = {
  isOnGlobalDashboard: false,
}

export default WebsiteAnalyseChartOrganism
