import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import References from "utils/functions/references"
import { T_CO2E } from "services/units/units"
import BarChartTemplate from "views/molecules/charts/template/BarChartTemplate"

const ChartODD13ScopePerSubScopeBreakdownMolecule = ({
  score,
  protocol,
  scope,
  helpText
}) => {
  const { t } = useTranslation()

  const getData = () => {
    switch (scope) {
      case "scope1":
        if (protocol === "ghg_protocol") {
          return score?.emissions_scope1_breakdown_per_ghg_sub_scope
        } else if (protocol === "bilan_carbone") {
          return score?.emissions_scope1_breakdown_per_bc_sub_scope
        }

        break
      case "scope2":
        if (protocol === "ghg_protocol") {
          return score?.emissions_scope2_breakdown_per_ghg_sub_scope
        } else if (protocol === "bilan_carbone") {
          return score?.emissions_scope2_breakdown_per_bc_sub_scope
        }

        break
      case "scope3":
        if (protocol === "ghg_protocol") {
          return score?.emissions_scope3_breakdown_per_ghg_sub_scope
        } else if (protocol === "bilan_carbone") {
          return score?.emissions_scope3_breakdown_per_bc_sub_scope
        }

        break
    }
  }

  const generateData = useCallback(() => {
    const dataBeforeTranslations = References.cloneDeep(getData())

    if (!dataBeforeTranslations || !dataBeforeTranslations.length) {
      return []
    }

    for (const element of dataBeforeTranslations) {
      element.label = t(`analyze:ghg_scopes.sub_scopes.${element.label}`)
    }

    return dataBeforeTranslations
  }, [score])
  const labels = generateData().map((data) => data.label)
  const data = generateData().map((data) => data.data)

  return (
    <BarChartTemplate
      title={t(`analyze:ghg_scopes.chartODD13ScopePerSubScopeBreakdownMolecule.title.${scope}`)}
      data={data}
      labelKeys={labels}
      unitPath={"units:units.emission"}
      unitKey={"t_unit"}
      helpText={helpText}
    />
  )
}

ChartODD13ScopePerSubScopeBreakdownMolecule.propTypes = {
  score: PropTypes.object,
  protocol: PropTypes.string.isRequired,
  scope: PropTypes.string.isRequired,
  helpText: PropTypes.string.isRequired
}

export default ChartODD13ScopePerSubScopeBreakdownMolecule
