import {
  HIDE_PROVIDER_MATURITY_FEATURE,
  SHOW_PROVIDER_MIGRATED_MATURITY_FEATURE,
} from "hooks/useFeatureFlag"

export const showMaturity = (
  currentOrganization,
  currentRootNode,
  currentNode,
  isFeatureActive
) => {
  return !(
    (currentRootNode?.is_provider || currentOrganization?.is_partial_partner) &&
    isFeatureActive(HIDE_PROVIDER_MATURITY_FEATURE) &&
    (!isFeatureActive(SHOW_PROVIDER_MIGRATED_MATURITY_FEATURE) ||
      (isFeatureActive(SHOW_PROVIDER_MIGRATED_MATURITY_FEATURE) &&
        !currentNode?.only_migrated_modules))
  )
}
