import PropTypes from "prop-types"
import React from "react"
import TextChartODD13GHGEmissionsLocalDataRoomMolecule from "views/molecules/canvases/charts/TextChartODD13GHGEmissionsLocalDataRoomMolecule"
import TextChartODD13GHGEmissionsMajorDCMolecule from "views/molecules/canvases/charts/TextChartODD13GHGEmissionsMajorDCMolecule"
import ChartODD13EquipmentTypeVolumeAverageGHGMolecule from "views/molecules/canvases/charts/ChartODD13EquipmentTypeVolumeAverageGHGMolecule"
import ChartODD13DatacenterEquipmentAverageGHGMolecule from "views/molecules/canvases/charts/ChartODD13DatacenterEquipmentAverageGHGMolecule"
import TextChartOdd13GhgEmissionTab from "views/molecules/canvases/charts/TextChartODD13GHGEmissionTab"
import ScoreLayoutOrganism from "views/organisms/scores/ScoreLayoutOrganism"
import ChartOdd13NetworkAndHostingEquipementsGHGEmissionsMolecule from "views/molecules/canvases/charts/ChartOdd13NetworkAndHostingEquipementsGHGEmissionsMolecule"

const DatacenterInternalAnalysisOrganism = ({ score }) => {
  return (
    <ScoreLayoutOrganism
      maturity={score?.odd13_datacenter_internal_maturity ?? 0}
      chart1={
        <TextChartOdd13GhgEmissionTab
          ghgEmissions={score?.odd13_datacenter_internal_CO2}
          ghgEmissionsCollect={score?.odd13_CO2}
          node={score?.node}
        />
      }
      chart2={<TextChartODD13GHGEmissionsMajorDCMolecule score={score} />}
      chart3={
        <ChartODD13DatacenterEquipmentAverageGHGMolecule
          mixedChart={score?.odd13_datacenter_ghg_equipment_average}
        />
      }
      chart4={
        <ChartODD13EquipmentTypeVolumeAverageGHGMolecule
          mixedChart={score?.odd13_internal_hosting_volume_emission_equipments}
          type="hosting"
        />
      }
      chart5={<TextChartODD13GHGEmissionsLocalDataRoomMolecule score={score} />}
      chart6={
        <ChartOdd13NetworkAndHostingEquipementsGHGEmissionsMolecule
          stackedChart={score?.odd13_internal_hosting_equipments_emissions_top}
        />
      }
    />
  )
}

DatacenterInternalAnalysisOrganism.propTypes = {
  score: PropTypes.object.isRequired,
}
export default DatacenterInternalAnalysisOrganism
