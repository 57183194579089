import PropTypes from "prop-types"
import React from "react"
import TextAnalysisCloudGHGEmission from "views/molecules/canvases/charts/cloud/TextAnalysisCloudGHGEmission"
import TextAnalysisCloudService from "views/molecules/canvases/charts/cloud/TextAnalysisCloudService"
import TextAnalysisCloudServiceInCarbonCountry from "views/molecules/canvases/charts/cloud/TextAnalysisCloudServiceInCarbonCountry"
import ScoreLayoutOrganism from "views/organisms/scores/ScoreLayoutOrganism"
import { useTranslation } from "react-i18next"
import ChartCloudServiceCostsDispatcher from "views/organisms/canvases/charts/ITDomainAnalysis/dispatcher/ChartCloudServiceCostsDispatcher"
import ChartCloudRegionsDispatcher from "views/organisms/canvases/charts/ITDomainAnalysis/dispatcher/ChartCloudRegionsDispatcher"
import ChartCloudLowUseDispatcher from "views/organisms/canvases/charts/ITDomainAnalysis/dispatcher/ChartCloudLowUseDispatcher"

const CloudAnalysisOrganism = ({ score, isOnGlobalDashboard }) => {
  const { t } = useTranslation()
  const { cloud_score } = score

  const service_biggest_emission = cloud_score?.service_biggest_emission
  const biggest_emissions_of_low_service_use =
    cloud_score?.biggest_emissions_of_low_service_use

  const top_clouder_per_region = score?.cloud_score?.top_clouder_per_region

  return (
    <ScoreLayoutOrganism
      maturity={score?.odd13_datacenter_external_cloud_maturity}
      chart1={
        <TextAnalysisCloudGHGEmission
          score={score}
          hidePercentageOfEmissions={isOnGlobalDashboard}
        />
      }
      chart2={
        <TextAnalysisCloudService
          serviceName={`${service_biggest_emission.provider} ${service_biggest_emission.service_name}`}
          value={service_biggest_emission?.co2e}
          percentage={service_biggest_emission?.percentage}
        />
      }
      chart3={<ChartCloudRegionsDispatcher envScore={score} />}
      chart4={<ChartCloudServiceCostsDispatcher envScore={score} />}
      chart5={
        <TextAnalysisCloudServiceInCarbonCountry
          value={score?.cloud_score?.high_ghg_intensity_percentage}
        />
      }
      chart6={<ChartCloudLowUseDispatcher envScore={score} />}
    />
  )
}

CloudAnalysisOrganism.propTypes = {
  score: PropTypes.object.isRequired,
  isOnGlobalDashboard: PropTypes.bool,
}

CloudAnalysisOrganism.defaultProps = {
  isOnGlobalDashboard: false,
}

export default CloudAnalysisOrganism
