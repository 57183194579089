import React from "react"
import { FIELDS } from "components/widgets/CollectPage/forms/inventories/environment/ITTravelsInventory"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import ModalITTeam from "views/molecules/collects/IT_Team_inventories/ModalITTeam"

const ITTeamTableLine = ({ techTeam, afterUpdateAction }) => {
  const { t } = useTranslation()
  const prefix = "collect:questions.13.it_team_modal."
  const updatedTechTeam = {
    ...techTeam,
    transport_mode: t(prefix + techTeam.transport_mode),
    avg_daily_commuting_distance: techTeam.avg_daily_commuting_distance + " km",
  }
  return (
    <tr>
      {FIELDS.map((field) => (
        <td key={field}>{updatedTechTeam[field]}</td>
      ))}

      <td>
        <ModalITTeam item={techTeam} postAction={afterUpdateAction} />
      </td>
    </tr>
  )
}

ITTeamTableLine.propTypes = {
  techTeam: PropTypes.object.isRequired,
  afterUpdateAction: PropTypes.func.isRequired,
}

export default ITTeamTableLine
