import { Modal } from "@shopify/polaris"
import { useAppContext } from "components/AppContext"
import React from "react"
import { useTranslation } from "react-i18next"
import { useFormConfig } from "components/widgets/CollectPage/forms/FormContext"
import InventoryChoiceConfig from "views/molecules/collects/cloud_inventories/InventoryChoiceConfig"
import RadioButton from "components/widgets/CollectPage/forms/fields/RadioButton"
import { useModuleContext } from "components/widgets/CollectPage/forms/Module"
import ModalInventoryChoiceTemplate from "views/molecules/collects/ModalInventoryChoiceTemplate"

export const MANUAL_CHOICE = "manual"
export const INVENTORY_CHOICE = "inventory"
export const BOTH_CHOICE = "both"

const ModalCloudInventories = () => {
  const { moduleIsActive } = useModuleContext()
  useFormConfig({
    fields: [
      "cloud_inventory_choice",
      "cloud_inventory_aws",
      "cloud_inventory_gcp",
      "cloud_inventory_azure",
      "cloud_inventory_aws_choice",
      "cloud_inventory_gcp_choice",
      "cloud_inventory_azure_choice",
    ],
    translationPath: "13.datacenter_external.cloud",
  })
  const { envCollect } = useAppContext()
  const { t } = useTranslation()

  const cloudInventoryChoice = envCollect.cloud_inventory_choice

  const text = t("collect:questions.13.datacenter_external.cloud", {
    returnObjects: true,
  })

  return (
    <ModalInventoryChoiceTemplate
      title={text.title}
      showUpdateWarning={envCollect.has_cloud_usages}
    >
      <Modal.Section>
        <RadioButton
          id="cloud_inventory_choice"
          choices={[
            {
              label: t(
                "collect:questions.13.datacenter_external.cloud.cloud_inventory_choice.options.manual"
              ),
              value: MANUAL_CHOICE,
            },
            {
              label: t(
                "collect:questions.13.datacenter_external.cloud.cloud_inventory_choice.options.inventory"
              ),
              value: INVENTORY_CHOICE,
            },
            {
              label: t(
                "collect:questions.13.datacenter_external.cloud.cloud_inventory_choice.options.both"
              ),
              value: BOTH_CHOICE,
            },
          ]}
          disabled={!moduleIsActive}
        />
      </Modal.Section>

      {[INVENTORY_CHOICE, BOTH_CHOICE].includes(cloudInventoryChoice) && (
        <InventoryChoiceConfig disabled={!moduleIsActive} />
      )}
    </ModalInventoryChoiceTemplate>
  )
}

export default ModalCloudInventories
