const disabledUnderstandITDomain = (
  currentNode,
  disabledFeatures,
  allTabsHidden
) => {
  const onlyOneModule =
    currentNode?.has_ccf_usages_only ||
    currentNode?.has_workplace_equipment_only ||
    currentNode?.has_network_equipment_only ||
    currentNode?.has_datacenter_equipment_only

  return !!disabledFeatures.envScore || onlyOneModule || allTabsHidden
}
export default disabledUnderstandITDomain
