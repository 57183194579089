import React from "react"
import { Modal } from "@shopify/polaris"
import RadioButton from "components/widgets/CollectPage/forms/fields/RadioButton"
import { useTranslation } from "react-i18next"
import { useModuleContext } from "components/widgets/CollectPage/forms/Module"
import { RETURN_OBJECTS } from "services/translations/config"
import { useAppContext } from "components/AppContext"
import ModalInventoryChoiceTemplate from "views/molecules/collects/ModalInventoryChoiceTemplate"

export const MANUAL_CHOICE = "manual"
export const INVENTORY_CHOICE = "inventory"

const ModalTechTeamInventoryChoice = () => {
  const { moduleIsActive } = useModuleContext()
  const { envCollect } = useAppContext()

  const { t } = useTranslation()
  const translation = t(
    "collect:questions.13.it_team.tech_team_inventory_file.modal",
    RETURN_OBJECTS
  )

  return (
    <ModalInventoryChoiceTemplate
      title={translation.title}
      showUpdateWarning={envCollect.has_tech_team_inventories}
    >
      <Modal.Section>
        <RadioButton
          id="tech_team_inventories_source"
          choices={[
            {
              label: translation.choices.manual,
              value: MANUAL_CHOICE,
            },
            {
              label: translation.choices.inventory,
              value: INVENTORY_CHOICE,
            },
          ]}
          disabled={!moduleIsActive}
        />
      </Modal.Section>
    </ModalInventoryChoiceTemplate>
  )
}

export default ModalTechTeamInventoryChoice
