import _ from "lodash"
import classNames from "classnames/bind"
import ProgressBar from "components/widgets/ProgressBar"
import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import "stylesheets/ThemeTabs.scss"
import { OBJECTIVES_THEMES } from "utils/constants/pillars"
import { useAppContext } from "../AppContext"
import { IMPROVE_MATURITY_PAGE } from "utils/constants/improve/pages"
import { useEntity } from "hooks/useAxios"
import EnvCollectActivatedThemeEntity from "services/entities/EnvCollectActivatedThemeEntity"
import ThemeTabActivator from "components/widgets/ThemeTabActivator"
import { useCollectContext } from "components/widgets/CollectPage/CollectContextProvider"
import { POSITIVE_IMPACT_MODULES } from "utils/constants/collects/modules"

const ThemeTabs = ({
  pageName,
  pillar,
  objective,
  theme,
  progress,
  collect,
}) => {
  const { t } = useTranslation()
  const themes = OBJECTIVES_THEMES[objective]
  const {
    subscription,
    reloadEnvCollect,
    currentRootNode,
    currentOrganization,
  } = useAppContext()
  const activateThemeEntity = useEntity(EnvCollectActivatedThemeEntity)

  const toggleTheme = async (theme) => {
    const callback = collect?.activated_themes?.includes(theme)
      ? activateThemeEntity.deactivate
      : activateThemeEntity.activate

    await callback(theme)
    reloadEnvCollect()
  }

  const themeListMarkup = useCallback(() => {
    if (themes === undefined || themes.count === 0) return null //first condition in case objective is undefined (when we want to be able to click on a pillar only), second condition in case objectivesThemes[objective] is an empty array

    const items = _.reject(themes, (existingTheme) => {
      switch (pageName) {
        case IMPROVE_MATURITY_PAGE:
          return (
            existingTheme === "positive_impact" || existingTheme === "cloud"
          )

        case "collect":
          return existingTheme === "cloud"
        default:
          return false
      }
    }).map((existingTheme, i) => {
      // All positive impact themes have the same themeId
      const customTheme =
        existingTheme === "positive_impact"
          ? `odd${objective}_${existingTheme}`
          : existingTheme

      const isActivated =
        (collect?.activated_themes?.includes(customTheme) &&
          (currentRootNode.is_provider ||
            currentOrganization?.is_partial_partner)) ||
        (currentRootNode.is_corporate &&
          !currentOrganization?.is_partial_partner)
      const showProgressBar =
        isActivated ||
        (currentRootNode.is_corporate &&
          !currentOrganization?.is_partial_partner)
      const showCheckbox =
        currentRootNode.is_provider || currentOrganization?.is_partial_partner

      const activatedThemes = collect?.env_collect_activated_themes

      const activatedTheme =
        activatedThemes &&
        activatedThemes.find(
          (editableTheme) => editableTheme.name === customTheme
        )

      const isEditable = !activatedTheme ? true : activatedTheme.is_editable

      return (
        <li key={i}>
          <div
            className={classNames(pillar, "container", {
              selected: theme === existingTheme,
              activated: isActivated,
            })}
          >
            <Link to={`/${pageName}/${pillar}/${objective}/${existingTheme}`}>
              <button
                className={classNames(pillar, {
                  selected: theme === existingTheme,
                  activated: isActivated,
                })}
              >
                {t(
                  `collect:pillars.${pillar}.objectives.${objective}.themes.${existingTheme}.title`
                )}
                {progress && showProgressBar && (
                  <ProgressBar
                    progress={progress}
                    pillarId={pillar}
                    objectiveId={objective}
                    themeId={existingTheme}
                  />
                )}
              </button>
            </Link>
            {showCheckbox && (
              <ThemeTabActivator
                isActivated={isActivated}
                toggle={() => toggleTheme(customTheme)}
                isEditable={isEditable}
              />
            )}
          </div>
        </li>
      )
    })
    return items
  }, [objective, theme, progress])

  return (
    <ul
      className={classNames(pillar, "ThemeTabs", {
        "--empty": themes === undefined,
      })}
    >
      {themeListMarkup()}
    </ul>
  )
}

ThemeTabs.propTypes = {
  pageName: PropTypes.string.isRequired,
  pillar: PropTypes.string.isRequired,
  objective: PropTypes.string,
  theme: PropTypes.string,
  progress: PropTypes.object,
  collect: PropTypes.object,
}

export default ThemeTabs
