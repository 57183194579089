import HelpWidget from "components/widgets/ScorePage/widgets/HelpWidget"
import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import "stylesheets/ScorePage/Score.scss"
import { PILLAR_ENVIRONMENTAL } from "utils/constants/pillars"
import ScoreLayoutOrganism from "views/organisms/scores/ScoreLayoutOrganism"

const ITDomainAnalysisLayoutOrganism = ({
  maturity,
  chart1,
  chart2,
  chart3,
  chart4,
  chart5,
  chart6,
  transSuffix,
  forceMaturity
}) => {
  const pillarId = PILLAR_ENVIRONMENTAL
  const { objectiveId } = useParams()
  const { t } = useTranslation()
  const translationSuffix = objectiveId
    ? `domain_analysis:analysis.${objectiveId}.`
    : transSuffix

  return (
    <ScoreLayoutOrganism
      forceMaturity={forceMaturity}
      maturity={maturity}
      pillarId={pillarId}
      transSuffix={transSuffix}
      chart1={
        <>
          <HelpWidget text={t(translationSuffix + "chart1.help")} />
          {chart1}
        </>
      }
      chart2={
        <>
          <HelpWidget text={t(translationSuffix + "chart2.help")} />
          {chart2}
        </>
      }
      chart3={
        <>
          <HelpWidget text={t(translationSuffix + "chart3.help")} />
          {chart3}
        </>
      }
      chart4={
        <>
          <HelpWidget text={t(translationSuffix + "chart4.help")} />
          {chart4}
        </>
      }
      chart5={
        <>
          <HelpWidget text={""} />
          {chart5}
        </>
      }
      chart6={
        <>
          <HelpWidget text={t(translationSuffix + "chart6.help")} />
          {chart6}
        </>
      }
    />
  )
}

ITDomainAnalysisLayoutOrganism.propTypes = {
  maturity: PropTypes.number,
  chart1: PropTypes.element.isRequired,
  chart2: PropTypes.element.isRequired,
  chart3: PropTypes.element.isRequired,
  chart4: PropTypes.element.isRequired,
  chart5: PropTypes.element.isRequired,
  chart6: PropTypes.element.isRequired,
  transSuffix: PropTypes.string,
  forceMaturity: PropTypes.bool
}

ITDomainAnalysisLayoutOrganism.defaultProps = {
  maturity: null,
  transSuffix: null,
  forceMaturity: false
}

export default ITDomainAnalysisLayoutOrganism
