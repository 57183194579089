import Config from "assets/configs/score"
import { COLORS } from "utils/constants/colors"
import i18n from "i18next"

export const BOTTOM_LEGEND = {
  position: "bottom",
}

export const RIGHT_LEGEND = {
  position: "right",
}

export const HIDDEN_LEGEND = {
  display: false,
}

export const COMPARE_LEGENDS = () => [
  {
    text: i18n.t("analyze:compare.charts.chart3.titleLabels.lowest"),
    fillStyle: Config.common.colorsEvaluation.green,
    strokeStyle: COLORS.white,
    lineWidth: 1,
  },
  {
    text: i18n.t("analyze:compare.charts.chart3.titleLabels.flat"),
    fillStyle: Config.common.colorsEvaluation.yellow,
    strokeStyle: COLORS.white,
    lineWidth: 1,
  },
  {
    text: i18n.t("analyze:compare.charts.chart3.titleLabels.highest"),
    fillStyle: Config.common.colorsEvaluation.red,
    strokeStyle: COLORS.white,
    lineWidth: 1,
  },
]

export const COMPARE_LEGENDS_DECREASE_AND_INCREASE = () => [
  {
    text: i18n.t("score:score.trends.decrease"),
    fillStyle: Config.common.colorsEvaluation.green,
    strokeStyle: COLORS.white,
    lineWidth: 1,
  },
  {
    text: i18n.t("score:score.trends.flat"),
    fillStyle: Config.common.colorsEvaluation.yellow,
    strokeStyle: COLORS.white,
    lineWidth: 1,
  },
  {
    text: i18n.t("score:score.trends.increase"),
    fillStyle: Config.common.colorsEvaluation.red,
    strokeStyle: COLORS.white,
    lineWidth: 1,
  },
]

export const COMPARE_LEGENDS_GHG_SPLIT_TREND = () => [
  {
    text: i18n.t("score:score.trends.infra"),
    fillStyle: Config.common.colorsEvaluation.green,
    strokeStyle: COLORS.white,
    lineWidth: 1,
  },
  {
    text: i18n.t("score:score.trends.network"),
    fillStyle: Config.common.colorsEvaluation.yellow,
    strokeStyle: COLORS.white,
    lineWidth: 1,
  },
  {
    text: i18n.t("score:score.trends.device"),
    fillStyle: Config.common.colorsEvaluation.red,
    strokeStyle: COLORS.white,
    lineWidth: 1,
  },
]

export const COMPARE_LEGENDS_GHG_ASSETS = () => [
  {
    text: i18n.t("score:score.trends.infra"),
    fillStyle: Config.common.colorsEvaluation.green,
    strokeStyle: COLORS.white,
    lineWidth: 1,
  },
  {
    text: i18n.t("score:score.trends.network"),
    fillStyle: Config.common.colorsEvaluation.yellow,
    strokeStyle: COLORS.white,
    lineWidth: 1,
  },
  {
    text: i18n.t("score:score.trends.workplace"),
    fillStyle: Config.common.colorsEvaluation.red,
    strokeStyle: COLORS.white,
    lineWidth: 1,
  },
]
