import React from "react"
import { Card, Layout, Link, Page } from "@shopify/polaris"
import { Trans, useTranslation } from "react-i18next"
import { isArray } from "@rails/webpacker/package/utils/helpers"

const Privacy = () => {
  const { t } = useTranslation()
  return (
    <Page fullWidth title={t("privacy_policy:title")}>
      <Layout>
        <Layout.Section>
          {
            //Check if the sections is an array if true render the sections
            isArray(t("privacy_policy:sections", { returnObjects: true })) &&
              t("privacy_policy:sections", { returnObjects: true }).map(
                (section, index) => (
                  <Card title={section.title} key={index}>
                    {
                      //Check if the sub_sections is an array if true render the sub_sections
                      isArray(section.sub_sections) &&
                        section.sub_sections.map((sub_section, index) => (
                          <Card.Section title={sub_section.title} key={index}>
                            {
                              //Check if the content is an array if true render the content
                              isArray(sub_section.content) &&
                                sub_section.content.map((paragraph, index) => (
                                  <p key={index}>
                                    <Trans
                                      i18nKey={paragraph}
                                      components={{
                                        Link: <Link external />,
                                      }}
                                    />
                                  </p>
                                ))
                            }
                          </Card.Section>
                        ))
                    }
                  </Card>
                )
              )
          }
        </Layout.Section>
      </Layout>
    </Page>
  )
}

export default Privacy
