import PropTypes from "prop-types"
import React from "react"
import TextChartODD13GHGEmissionsEmployeeMolecule from "views/molecules/canvases/charts/TextChartODD13GHGEmissionsEmployeeMolecule"
import TextChartODD13GHGEmissionsMolecule from "views/molecules/canvases/charts/TextChartODD13GHGEmissionsMolecule"
import TextChartODD13EnergyMixMolecule from "views/molecules/canvases/charts/TextChartODD13EnergyMixMolecule"
import { NODE_TYPE_SITE } from "utils/constants/organization/nodeTypes"
import ScoreLayoutOrganism from "views/organisms/scores/ScoreLayoutOrganism"
import Objective13FlexibilityChartMapping from "views/molecules/charts/flexibility/Objective13FlexibilityChartMapping"
import TextChartODD13HighestITDomainEmissionsMolecule from "views/molecules/canvases/charts/TextChartODD13HighestITDomainEmissionsMolecule"

const Objective13PartialScoreLayoutOrganism = ({ score }) => {
  const chartKeys = score?.sdg13_partial_collect_charts || []

  return (
    <ScoreLayoutOrganism
      maturity={score.odd13_maturity ?? 0}
      chart1={
        <TextChartODD13GHGEmissionsMolecule
          score={score}
          ghgEmissions={score?.odd13_CO2}
        />
      }
      chart2={
        score?.node?.has_children ? (
          <TextChartODD13GHGEmissionsEmployeeMolecule score={score} />
        ) : (
          <TextChartODD13EnergyMixMolecule
            energyMixStatus={score?.odd13_energy_mix_status}
            energyMixValue={Number(score?.odd13_energy_mix_value)}
            country={
              score?.node?.type === NODE_TYPE_SITE
                ? score?.node?.parent?.name
                : score?.node?.name
            }
          />
        )
      }
      chart3={
        <Objective13FlexibilityChartMapping
          chartKey={chartKeys[0]}
          envScore={score}
        />
      }
      chart4={
        <Objective13FlexibilityChartMapping
          chartKey={chartKeys[1]}
          envScore={score}
        />
      }
      chart5={
        score?.node?.has_children ? (
          <TextChartODD13HighestITDomainEmissionsMolecule score={score} />
        ) : (
          <TextChartODD13EnergyMixMolecule
            energyMixStatus={score?.odd13_energy_mix_status}
            energyMixValue={Number(score?.odd13_energy_mix_value)}
            country={
              score?.node?.type === NODE_TYPE_SITE
                ? score?.node?.parent?.name
                : score?.node?.name
            }
          />
        )
      }
      chart6={
        <Objective13FlexibilityChartMapping
          chartKey={chartKeys[2]}
          envScore={score}
        />
      }
    />
  )
}

Objective13PartialScoreLayoutOrganism.propTypes = {
  score: PropTypes.object.isRequired,
}

Objective13PartialScoreLayoutOrganism.defaultProps = {}

export default Objective13PartialScoreLayoutOrganism
