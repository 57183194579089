import PropTypes from "prop-types"
import React, { useCallback } from "react"
import DoughnutChart from "components/widgets/ScorePage/widgets/DoughnutChart"
import { useTranslation } from "react-i18next"
import NodeOrganization from "services/organizations/NodeOrganization"
import NumberFormatterApp from "services/apps/numberFormatterApp"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import { useAppContext } from "components/AppContext"
import { round } from "services/units/round"
import DonutChartTemplate from "views/molecules/charts/template/DonutChartTemplate"

const ChartODD13EmissionBreakdownPerRegionMolecule = ({
  score,
  translationScope = "",
}) => {
  const { t } = useTranslation()
  const { currentRootNode } = useAppContext()

  const help = currentRootNode?.is_corporate
    ? t("score:score.13.chartGHGEmissionsPerEmployee.corporate.help")
    : t("score:score.13.chartGHGEmissionsPerEmployee.provider.help")

  const getTranslationPath = () => {
    if (translationScope === "global") {
      return `score:score.13.chartEmissionBreakdownPerEntity`
    } else {
      return `score:score.13.chartEmissionBreakdownPerRegion`
    }
  }

  return (
    <HelpWidgetChartInteractiveMolecule text={help}>
      <DonutChartTemplate
        title={t(`${getTranslationPath()}.title`)}
        data={score?.emissions_breakdown?.data}
        labelKeys={score?.emissions_breakdown?.labels}
        centerValue={score?.emissions_breakdown?.doughnut_charts_data_total} // number
        unitPath="units:units.emission"
        unitKey={score?.emissions_breakdown?.unit_key}
        helpText={help}
      />
    </HelpWidgetChartInteractiveMolecule>
  )
}

ChartODD13EmissionBreakdownPerRegionMolecule.propTypes = {
  score: PropTypes.object,
  translationScope: PropTypes.string.isRequired,
}

export default ChartODD13EmissionBreakdownPerRegionMolecule
