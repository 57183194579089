import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import DonutChartTemplate from "views/molecules/charts/template/DonutChartTemplate"
import { round } from "services/units/round"
import { convertValueToReadable } from "services/units/valueConversion"
import { CO2_EMISSION_UNITS } from "services/units/units"

const ChartODD13RegionNetworkBreakdownMolecule = ({ score }) => {
  const { t } = useTranslation()

  const convertedCenterData = convertValueToReadable(
    score?.odd13_network_co2_top?.doughnut_charts_data_total,
    score?.odd13_network_co2_top?.unit_key,
    CO2_EMISSION_UNITS
  )

  const roundedData = round(convertedCenterData.value)
  const centerUnit = t(`units:units.emission.${convertedCenterData.unit}`)

  return (
    <DonutChartTemplate
      title={t(`score:score.13.chartRegionNetworkBreakdown.title`)}
      data={score?.odd13_network_co2_top?.data}
      labelKeys={score?.odd13_network_co2_top?.labels}
      centerValue={roundedData}
      centerValueUnit={centerUnit}
      kpiConfig={45}
      unitPath={"units:units.emission"}
      unitKey={score?.odd13_network_co2_top?.unit_key}
      helpText={t(
        `analyze:it_domain.consolidated.transversal.chartRegionNetworkBreakdown.help`
      )}
    />
  )
}

ChartODD13RegionNetworkBreakdownMolecule.propTypes = {
  score: PropTypes.object.isRequired,
}

export default ChartODD13RegionNetworkBreakdownMolecule
