const TechTeamInventoryEntity = (backClient) => {
  return {
    index: async () => await backClient.get(`/api/tech_team_inventories`),
    create: async (data) =>
      await backClient.post(`/api/tech_team_inventories`, data),
    update: async (id, data) =>
      await backClient.put(`/api/tech_team_inventories/${id}`, data),
    delete: async (id) =>
      await backClient.delete(`/api/tech_team_inventories/${id}`),
  }
}

export default TechTeamInventoryEntity
