import PropTypes from "prop-types"
import React from "react"
import ITDomainAnalysisLayoutOrganism from "views/organisms/canvases/charts/ITDomainAnalysisLayoutOrganism"
import ChartODD13ScopePerSubScopeBreakdownMolecule from "views/molecules/canvases/charts/GHGProtocol/ChartODD13ScopePerSubScopeBreakdownMolecule"
import TextChartODD13ScopeMolecule from "views/molecules/canvases/charts/GHGProtocol/TextChartODD13ScopeMolecule"
import {
  TextChart,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import { useTranslation } from "react-i18next"
import TextChartODD13GHGEmissionsMolecule from "views/molecules/canvases/charts/TextChartODD13GHGEmissionsMolecule"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"

const GHGScopesOrganism = ({ score, protocol }) => {
  const { t } = useTranslation()

  const chart3_content_no_scope_1 = {
    kpi_text: t("analyze:ghg_scopes.chart_title_no_scope_1"),
  }

  const content_for_display_chart_3 = (
    <TextChartMinor content={chart3_content_no_scope_1} />
  )

  return (
    <ITDomainAnalysisLayoutOrganism
      maturity={score?.odd13_maturity ?? 0}
      chart1={
        <TextChartODD13GHGEmissionsMolecule
          score={score}
          ghgEmissions={score?.odd13_CO2}
        />
      }
      chart2={
        <HelpWidgetChartInteractiveMolecule
          text={t(
            `analyze:ghg_scopes.textChartODD13ScopeMolecule.title.scope2Help`
          )}
        >
          <TextChartODD13ScopeMolecule
            score={score}
            // scope1="scope1"
            scope1="scope2"
          />
        </HelpWidgetChartInteractiveMolecule>
      }
      chart3={

          <ChartODD13ScopePerSubScopeBreakdownMolecule
            score={score}
            protocol={protocol}
            scope="scope2"
            helpText={t(`analyze:ghg_scopes.chartODD13ScopePerSubScopeBreakdownMolecule.title.scope2Help`)}
          />
      }
      chart4={
        <HelpWidgetChartInteractiveMolecule
          text={t(
            `analyze:ghg_scopes.textChartODD13ScopeMolecule.title.scope1Help`
          )}
        >
          <TextChart content={content_for_display_chart_3} />
        </HelpWidgetChartInteractiveMolecule>
      }
      chart5={
        <HelpWidgetChartInteractiveMolecule
          text={t(
            `analyze:ghg_scopes.textChartODD13ScopeMolecule.title.scope3Help`
          )}
        >
          <TextChartODD13ScopeMolecule score={score} scope1="scope3" />
        </HelpWidgetChartInteractiveMolecule>
      }
      chart6={
          <ChartODD13ScopePerSubScopeBreakdownMolecule
            score={score}
            protocol={protocol}
            scope="scope3"
            helpText={t(`analyze:ghg_scopes.chartODD13ScopePerSubScopeBreakdownMolecule.title.scope3Help`)}
          />
      }
    />
  )
}

GHGScopesOrganism.propTypes = {
  score: PropTypes.object.isRequired,
  protocol: PropTypes.string.isRequired,
}

export default GHGScopesOrganism
