import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import References from "utils/functions/references"
import CumulativeBarChartTemplate from "views/molecules/charts/template/CumulativeBarChartTemplate"
import { T_UNIT } from "utils/constants/numbers"

const ChartODD13ScopesStackPerGroupBreakdownMolecule = ({
  score,
  protocol,
  group,
  stack,
  helpText,
}) => {
  const { t } = useTranslation()

  const getData = () => {
    switch (group) {
      case "scopes":
        if (protocol === "ghg_protocol") {
          return score?.emissions_scopes_breakdown_per_ghg_sub_scope
        } else if (protocol === "bilan_carbone") {
          return score?.emissions_scopes_breakdown_per_bc_sub_scope
        }

        break
      case "sub_scopes":
        if (protocol === "ghg_protocol") {
          return score?.emissions_ghg_sub_scopes_breakdown_per_kpi
        } else if (protocol === "bilan_carbone") {
          return score?.emissions_bc_sub_scopes_breakdown_per_kpi
        }

        break
    }
  }

  const generateData = useCallback(() => {
    const dataBeforeTranslations = References.cloneDeep(getData())

    if (!dataBeforeTranslations) {
      return {}
    }

    for (const dataset of dataBeforeTranslations.datasets) {
      dataset.label = t(`analyze:ghg_scopes.${stack}.${dataset.label}`)
    }

    dataBeforeTranslations.labels = dataBeforeTranslations.labels.map(
      (element) => {
        return t(`analyze:ghg_scopes.${group}.${element}`)
      }
    )

    return dataBeforeTranslations
  }, [score])

  const data = generateData().datasets.map((dataset) => ({
    label: dataset.label,
    data: dataset.data,
  }))

  const labels = generateData().labels.map((label) => label)

  return (
    <CumulativeBarChartTemplate
      title={t(
        `analyze:ghg_scopes.chartODD13ScopesStackPerGroupBreakdownMolecule.title.${group}_and_${stack}`
      )}
      datasets={data}
      labelKeys={labels}
      unitPath="units:units.emission"
      unitKey="t_unit"
      helpText={helpText}
      hideLegend
    />
  )
}

ChartODD13ScopesStackPerGroupBreakdownMolecule.propTypes = {
  score: PropTypes.object,
  protocol: PropTypes.string.isRequired,
  group: PropTypes.string.isRequired,
  stack: PropTypes.string.isRequired,
}

export default ChartODD13ScopesStackPerGroupBreakdownMolecule
