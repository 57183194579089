import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import NodeOrganization from "services/organizations/NodeOrganization"
import BarChartTemplate from "views/molecules/charts/template/BarChartTemplate"

const ChartODD13TopGHGPerCountryMolecule = ({ score }) => {
  const { t } = useTranslation()
  const ghgEmissionsCountries = useCallback(() => {
    const ghg_emissions_countries = score?.ghg_emissions_countries || []
    return ghg_emissions_countries.map((node) => {
      return {
        label: NodeOrganization.getName(node?.label?.name, node?.type, {
          countryShortName: true
        }),
        data: node?.data,
      }
    })
  }, [score])

  const emissionsData = ghgEmissionsCountries()
  const labels = emissionsData.map(item => item.label.name)
  const allLabelsIdentical = labels.every(label => label === labels[0])
  const allLabelsDifferent = new Set(labels).size === labels.length
  const formattedLabels = allLabelsIdentical ? labels.map(() => emissionsData[0].label.parent_names) : allLabelsDifferent ? labels : labels.map(label => {
    const parentLabel = emissionsData.find(item => item.label.name === label)?.label.parent_names
    return `${label} (${parentLabel})`
  })
  const data = emissionsData.map(item => item.data)
  const title = t(`score:score.13.chartTopGHGPerCountry.title`)

  return (
    <BarChartTemplate
      title={title}
      data={data}
      labelKeys={formattedLabels}
      dataBarTitle={t(`score:score.13.chartTopGHGPerCountry.dataTitle`)}
      unitPath={"units:units.emission"}
      unitKey={score?.ghg_emissions_countries[0]?.unit}
      pillar={"environmental"}
      helpText={t(`score:score.13.chartTopGHGPerCountry.help`)}
    />
  )
}

ChartODD13TopGHGPerCountryMolecule.propTypes = {
  score: PropTypes.object
}

export default ChartODD13TopGHGPerCountryMolecule