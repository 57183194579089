import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import { EMISSION_UNIT, KG_UNIT, T_UNIT } from "utils/constants/numbers"
import useDomainBreakdownChart from "views/molecules/canvases/charts/useDomainBreakdownChart"
import { CO2_EMISSION_UNITS } from "services/units/units"
import CumulativeBarChartTemplate from "views/molecules/charts/template/CumulativeBarChartTemplate"
import Config from "assets/configs/score"
import { COLORS } from "utils/constants/colors"

const STRUCTURE = [
  {
    label: "workplace",
    fab: "odd13_workplace_CO2_fab_real",
    use: "odd13_workplace_CO2_use",
  },
  {
    label: "datacenter",
    fab: "odd13_datacenter_CO2_fab_real",
    use: "odd13_datacenter_CO2_equipments_use",
  },
  {
    label: "network",
    fab: "odd13_network_CO2_fab_real",
    use: "odd13_network_CO2_use",
  },
  {
    label: "business",
    fab: "odd13_business_CO2_fab_real",
    use: "odd13_business_CO2_use",
  },
]

const translationPrefix = "score:score.13.chartEquipementsGHGEmissions"

const ChartODD13EquipmentsGHGEmissionsMolecule = ({ score }) => {
  const { t } = useTranslation()

  const options = {
    baseUnit: T_UNIT,
    lowerUnit: KG_UNIT,
    unitType: EMISSION_UNIT,
    units: CO2_EMISSION_UNITS,
    manufacturingLabel: t(`${translationPrefix}.manufacturing`),
    useLabel: t(`${translationPrefix}.use`),
  }

  const props = useDomainBreakdownChart(STRUCTURE, score, options)
  const labels = props.labelKeys.map((labelKey) =>
    t(`${translationPrefix}.${labelKey}`)
  )

  let datasets = [
    {
      label: props.data.map((label) => label.label)[1],
      data: props.use_data_to_be_displayed,
      backgroundColor: props.use_data_to_be_displayed.map((data, index) =>
        data > props.fab_data_to_be_displayed[index]
          ? Config.common.colorsEvaluation.yellow
          : COLORS.planet.primary
      ),
    },
    {
      label: props.data.map((label) => label.label)[0],
      data: props.fab_data_to_be_displayed,
      backgroundColor: props.fab_data_to_be_displayed.map((data, index) =>
        data > props.use_data_to_be_displayed[index]
          ? Config.common.colorsEvaluation.yellow
          : COLORS.planet.primary
      ),
    },
  ]

  return (
    <CumulativeBarChartTemplate
      title={t(`${translationPrefix}.title`)}
      datasets={datasets}
      labelKeys={labels}
      unitPath={"units:units.emission"}
      showLabelAndUnitsOnBar
      useBackgroundColorsInDatasets
      brAfterLabel
      unitKey={props.unit}
      hiddenLegend
      helpText={t(`${translationPrefix}.help`)}
    />
  )
}

ChartODD13EquipmentsGHGEmissionsMolecule.propTypes = {
  score: PropTypes.shape({
    odd13_workplace_CO2_fab_real: PropTypes.string,
    odd13_workplace_CO2_use: PropTypes.string,
    odd13_datacenter_CO2_fab_real: PropTypes.string,
    odd13_datacenter_CO2_use: PropTypes.string,
    odd13_network_CO2_fab_real: PropTypes.string,
    odd13_network_CO2_use: PropTypes.string,
  }),
}

export default ChartODD13EquipmentsGHGEmissionsMolecule
