import React from "react"
import { useTranslation } from "react-i18next"
import { Doughnut } from "react-chartjs-2"
import useTranslatedLabels from "services/charts/translatedLabels"
import { round } from "services/units/round"
import SCORE_CONFIG from "assets/configs/score"
import { COLORS } from "utils/constants/colors"
import { DEFAULT_OPTIONS } from "services/charts/default"
import { ChartContainer } from "views/organisms/organization/tables/ChartContainer"
import PropTypes from "prop-types"
import { FilterTooLittleValues } from "services/charts/ConvertData"
import { logger } from "@rails/actioncable"
import { convertValuesToReadable } from "services/units/valueConversion"
import { DETERMINE_UNITS } from "services/units/units"

const DonutChartTemplate = ({
  title,
  data,
  labelPath,
  labelKeys,
  centerValue,
  centerValueUnit,
  unitPath,
  unitKey,
  ifUnitsIsArray,
  pillar,
  showValueOnBar,
  helpText,
  modifiedBackgroundColor,
}) => {
  const { t } = useTranslation()
  const translatedLabels = useTranslatedLabels(labelPath, labelKeys)
  const convertedValue = convertValuesToReadable(
    data.filter((value) => +value !== 0),
    unitKey,
    DETERMINE_UNITS(unitKey)
  )
  unitKey = convertedValue.unit
  const roundedData = convertedValue.values.map(round)

  const convertToPercentage = (value) => {
    return (value / roundedData.reduce((a, b) => a + b, 0)) * 100
  }

  const centerUnit = centerValueUnit
    ? centerValueUnit
    : t(`${unitPath}.${unitKey}`)

  const { filteredData, filteredLabels } = FilterTooLittleValues(
    roundedData,
    translatedLabels
  )

  const datasets = {
    labels: filteredLabels,
    datasets: [
      {
        data: filteredData,
        backgroundColor:
          modifiedBackgroundColor === undefined
            ? SCORE_CONFIG.common.colors
            : modifiedBackgroundColor,
      },
    ],
  }

  const options = {
    ...DEFAULT_OPTIONS,
    cutoutPercentage: 66.7,
    legend: {
      labels: {
        filter: (item) => !!item.text,
      },
      position: "right",
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          return `${data.labels[tooltipItem.index]}: ${
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
          } ${
            (
              ifUnitsIsArray
                ? t(`${unitPath}.${unitKey[tooltipItem.index]}`)
                : unitPath === undefined || unitKey === undefined
            )
              ? ""
              : ifUnitsIsArray
              ? t(`${unitPath}.${unitKey[tooltipItem.index]}`)
              : t(`${unitPath}.${unitKey}`)
          }`
        },
      },
    },
    plugins: {
      datalabels: {
        display: function (context) {
          return (
            convertToPercentage(context.dataset.data[context.dataIndex]) > 5
          )
        },
        formatter: function (value, context) {
          const convertedData = convertToPercentage(
            context.dataset.data[context.dataIndex]
          )
          return showValueOnBar
            ? context.dataset.data[context.dataIndex]
            : round(convertedData) + "%"
        },
        color: COLORS.white,
        font: {
          weight: "bold",
        },
      },
    },
  }
  const plugins = centerValue
    ? [
        {
          beforeDraw: function (chart) {
            const ctx = chart.ctx

            ctx.restore()

            ctx.font = "700 1.7em sans-serif"
            ctx.textBaseline = "middle"
            ctx.textAlign = "center"
            ctx.fontStyle = "bold"
            ctx.fillStyle = COLORS.planet.c70

            const text = `${centerValue} ${centerUnit}`
            const textWidth = ctx.measureText(text).width

            const chartArea = chart.chartArea
            const centerX = (chartArea.left + chartArea.right) / 2
            const centerY = (chartArea.top + chartArea.bottom) / 2
            const innerRadius = chart.innerRadius

            const maxWidth = innerRadius * 2 * 0.8
            let fontSize = parseInt(ctx.font)

            while (textWidth > maxWidth && fontSize > 10) {
              fontSize--
              ctx.font = `700 ${fontSize}px sans-serif`
            }

            ctx.fillText(text, centerX, centerY)
            ctx.save()
          },
        },
      ]
    : []

  return (
    <ChartContainer title={title} helpText={helpText} pillar={pillar}>
      <Doughnut data={datasets} options={options} plugins={plugins} />
    </ChartContainer>
  )
}

export default DonutChartTemplate

DonutChartTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  labelPath: PropTypes.string,
  labelKeys: PropTypes.array.isRequired,
  centerValue: PropTypes.number,
  unitPath: PropTypes.string,
  unitKey: PropTypes.string,
  helpText: PropTypes.string.isRequired,
  centerValueUnit: PropTypes.string,
  pillar: PropTypes.string,
  showValueOnBar: PropTypes.bool,
  showDatalabelsThreshold: PropTypes.number,
  modifiedBackgroundColor: PropTypes.array,
  ifUnitsIsArray: PropTypes.bool,
}

DonutChartTemplate.defaultProps = {
  kpiConfig: 60,
  unitPath: "units:units.emission",
  unitKey: "kg_unit",
  helpText: PropTypes.string.isRequired,
}
