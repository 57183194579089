import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import CumulativeBarChartTemplate from "views/molecules/charts/template/CumulativeBarChartTemplate"

const getLabel = (label) => {
  if (label.version_name) {
    return `${label.name} (${label.version_name})`
  }
  return label.name
}

const ChartODD13EmissionByMonth = ({ score }) => {
  const { t } = useTranslation()

  const generateMonthLabel = ({ label, year }) => {
    if (label) {
      return (
        t(`score:score.13.chartEmissionByMonth.months.${label}`) + " " + year
      )
    }
    return year
  }

  const { datasets, periods, unit_key } =
  score?.odd13_emissions_by_month_consolidated || {}

  const newDatasets = datasets.map((dataset) => {
    return {
      label: getLabel(dataset?.label_key),
      data: dataset?.data
    }
  })

  return (
    <CumulativeBarChartTemplate
      title={t(`score:score.13.chartEmissionsByMonth.title`)}
      datasets={newDatasets}
      labelKeys={periods.map((period) => generateMonthLabel(period))}
      unitPath={"units:units.emission"}
      unitKey={unit_key}
      helpText={t(`score:score.13.chartEmissionsByMonth.help`)}
    />
  )
}

ChartODD13EmissionByMonth.propTypes = {
  score: PropTypes.object
}

export default ChartODD13EmissionByMonth