import React from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import ChartODD13RegionWorkplaceBreakdownMolecule from "views/molecules/canvases/charts/ChartODD13RegionWorkplaceBreakdownMolecule"
import ChartOdd13WorkplaceVolumeAverageGhg from "views/molecules/canvases/charts/ChartODD13WorkplaceVolumeAverageGHG"

const ChartAssetsWorkplaceDispatcher = ({ envScore }) => {
  const { t } = useTranslation()

  if (envScore.odd13_global_node_children_defined) {
    return (
        <ChartODD13RegionWorkplaceBreakdownMolecule score={envScore} />
    )
  } else {
    return (
      <ChartOdd13WorkplaceVolumeAverageGhg
        chartData={envScore?.odd13_workplace_volume_emission_equipment}
      />
    )
  }
}

ChartAssetsWorkplaceDispatcher.propTypes = {
  envScore: PropTypes.object.isRequired,
}

export default ChartAssetsWorkplaceDispatcher
