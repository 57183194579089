import PropTypes from "prop-types"
import React from "react"
import TextChartODD13GHGEmissionsMolecule from "views/molecules/canvases/charts/TextChartODD13GHGEmissionsMolecule"
import TextChartODD13VolumeGHGTelcoMolecule from "views/molecules/canvases/charts/TextChartODD13VolumeGHGTelcoMolecule"
import TextChartODD13AverageEquipmentsPerFteMolecule from "views/molecules/canvases/charts/TextChartODD13AverageEquipmentsPerFteMolecule"
import ScoreLayoutOrganism from "views/organisms/scores/ScoreLayoutOrganism"
import ChartAssetsNetworkDispatcher from "views/organisms/canvases/charts/ITDomainAnalysis/dispatcher/ChartAssetsNetworkDispatcher"
import ChartAssetsHostingDispatcher from "views/organisms/canvases/charts/ITDomainAnalysis/dispatcher/ChartAssetsHostingDispatcher"
import ChartAssetsWorkplaceDispatcher from "views/organisms/canvases/charts/ITDomainAnalysis/dispatcher/ChartAssetsWorkplaceDispatcher"

const TransversalITDomainAnalysisOrganism = ({ score }) => {
  return (
    <ScoreLayoutOrganism
      maturity={score.odd13_maturity ?? 0}
      chart1={
        <TextChartODD13GHGEmissionsMolecule
          score={score}
          ghgEmissions={score?.odd13_it_domain_co2}
        />
      }
      chart2={
        <TextChartODD13VolumeGHGTelcoMolecule
          telcoAveragePerFte={+score?.odd13_telco_co2_per_fte}
          volumeAveragePerFte={+score?.odd13_volume_of_data_per_fte}
        />
      }
      chart3={<ChartAssetsHostingDispatcher envScore={score} />}
      chart4={<ChartAssetsWorkplaceDispatcher envScore={score} />}
      chart5={<TextChartODD13AverageEquipmentsPerFteMolecule score={score} />}
      chart6={<ChartAssetsNetworkDispatcher envScore={score} />}
    />
  )
}

TransversalITDomainAnalysisOrganism.propTypes = {
  score: PropTypes.object.isRequired,
}

export default TransversalITDomainAnalysisOrganism
