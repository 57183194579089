import React from "react"
import PropTypes from "prop-types"
import WebsiteAnalyseChartOrganism from "views/organisms/canvases/charts/analyzes/website/WebsiteAnalyseChartOrganism"
import Objective13ScoreOrganism from "views/organisms/canvases/charts/Objective13ScoreOrganism"
import { useAppContext } from "components/AppContext"
import CloudAnalysisOrganism from "views/organisms/canvases/charts/ITDomainAnalysis/CloudAnalysisOrganism"
import WorkplaceBrokerAnalysisOrganism from "views/organisms/canvases/charts/ITDomainAnalysis/WorkplaceBrokerAnalysisOrganism"

const Objective13ScoreSwitch = ({ score, collect }) => {
  const { currentNode } = useAppContext()

  const moduleComponentPair = {
    has_websites_only: WebsiteAnalyseChartOrganism,
    has_ccf_usages_only: CloudAnalysisOrganism,
    has_workplace_equipment_only: WorkplaceBrokerAnalysisOrganism,
  }

  const oneModuleComponentKey = Object.keys(moduleComponentPair).find(
    (key) => !!currentNode[key]
  )

  const Component = oneModuleComponentKey
    ? moduleComponentPair[oneModuleComponentKey]
    : Objective13ScoreOrganism

  return <Component score={score} collect={collect} isOnGlobalDashboard />
}

Objective13ScoreSwitch.propTypes = {
  score: PropTypes.object,
  collect: PropTypes.object,
}
export default Objective13ScoreSwitch
