import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useEntity } from "hooks/useAxios"
import { Stack } from "@shopify/polaris"
import useFieldIsLocked from "components/widgets/CollectPage/forms/fields/useFieldIsLocked"
import ModalITTeam from "views/molecules/collects/IT_Team_inventories/ModalITTeam"
import TechTeamInventoryEntity from "services/entities/TechTeamInventoryEntity"
import ITTeamTableLine from "components/widgets/CollectPage/forms/inventories/environment/ITTeamTableLine"

const ITTeamPrefix = "collect:questions.13.it_team_modal."

export const FIELDS = [
  "avg_daily_commuting_distance",
  "transport_mode",
  "remote_days",
  "days_on_site",
  "amount",
]

const ITTravelsInventory = () => {
  const techTeamInventoryEntity = useEntity(TechTeamInventoryEntity)
  const { t } = useTranslation()
  const [techTeamInventories, setTechTeamInventories] = useState([])
  const { isLocked } = useFieldIsLocked()

  const getTechTeamInventory = async () => {
    try {
      const response = await techTeamInventoryEntity.index()
      const filtered_response = response.data.filter(
        (team) => team.source === "manual"
      )
      setTechTeamInventories(filtered_response)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getTechTeamInventory()
  }, [])

  return (
    <table className="Verdikt-DynamicTable">
      <thead>
        <tr className="leftAligned">
          {FIELDS.map((field) => (
            <th key={field}>{t(ITTeamPrefix + field)}</th>
          ))}
          <th />
          <th>
            <ModalITTeam postAction={getTechTeamInventory} />
          </th>
        </tr>
      </thead>
      <tbody>
        {techTeamInventories.map((techTeamInventory) => (
          <ITTeamTableLine
            key={techTeamInventory.id}
            techTeam={techTeamInventory}
            afterUpdateAction={getTechTeamInventory}
          />
        ))}

        {techTeamInventories.length === 0 && (
          <tr>
            <td colSpan={9}>
              <Stack
                distribution="center"
                alignment="center"
                spacing="extraTight"
              >
                <p>{t("collect:questions.13.it_team_modal.no_it_team")}</p>
                {!isLocked && <ModalITTeam postAction={getTechTeamInventory} />}
              </Stack>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default ITTravelsInventory
