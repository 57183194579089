import React from "react"
import { ChoiceList as PChoiceList } from "@shopify/polaris"
import { checkHover } from "./Functions"
import PropTypes from "prop-types"
import useFieldIsLocked from "./useFieldIsLocked"
import { useFormContext } from "../FormContext"
import _ from "lodash"

const RadioButton = ({ id, onChange, disabled, pathOverride, choices }) => {
  const {
    formData,
    setFormData,
    disabledFields,
    errors,
    translationPath,
  } = useFormContext()
  const { isLocked } = useFieldIsLocked()

  const translationPrefix = `collect:questions.${
    pathOverride || translationPath
  }.${id}.`

  console.log({ translationPrefix })

  return (
    <div className="Verdikt-Field-YesNoRadio">
      <PChoiceList
        title={checkHover(translationPrefix)}
        choices={choices}
        selected={`${formData?.[id]}` ?? ""}
        onChange={(value) => {
          onChange
            ? onChange(value)
            : setFormData({ ...formData, [id]: value[0] })
        }}
        error={errors[id]}
        disabled={isLocked || disabled || _.get(disabledFields, id, false)}
      />
    </div>
  )
}

RadioButton.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  errors: PropTypes.object,
  disabled: PropTypes.bool,
  pathOverride: PropTypes.string,
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default RadioButton
