import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import DonutChartTemplate from "views/molecules/charts/template/DonutChartTemplate"
import prepareDoughnutChartData from "utils/functions/charts/DoughnutChartFunctions"

const ChartODD13RegionWorkplaceBreakdownMolecule = ({ score }) => {
  const { t } = useTranslation()

  const generateData = useCallback(
    () =>
      prepareDoughnutChartData(
        score?.odd13_workplace_co2_top?.doughnut_charts,
        score?.odd13_workplace_co2_top?.doughnut_charts_data_total,
        score?.odd13_workplace_co2_top?.unit_key
      ),
    [score]
  )

  const generatedData = generateData()

  return (
    <DonutChartTemplate
      title={t(`score:score.13.chartRegionWorkplaceBreakdown.title`)}
      data={score?.odd13_workplace_co2_top?.data}
      labelKeys={score?.odd13_workplace_co2_top?.labels}
      centerValue={generatedData?.formatedTotal}
      centerValueUnit={generatedData?.totalUnit}
      unitPath={"units:units.emission"}
      unitKey={score?.odd13_workplace_co2_top?.unit_key}
      helpText={t(
        `analyze:it_domain.consolidated.transversal.chartRegionWorkplaceBreakdown.help`
      )}
    />
  )
}

ChartODD13RegionWorkplaceBreakdownMolecule.propTypes = {
  score: PropTypes.object.isRequired,
}

export default ChartODD13RegionWorkplaceBreakdownMolecule
