import PropTypes from "prop-types"
import React, { useContext, useState } from "react"
import { useAppContext } from "components/AppContext"

const ChartContext = React.createContext()

export const useChart = () => {
  return useContext(ChartContext)
}

export const markIsCurrentNodeBelow = (tree) => {
  if (!tree) return

  const isCurrentNodeBelow =
    tree.is_current_node || tree.children.some(markIsCurrentNodeBelow)

  tree.isCurrentNodeBelow = isCurrentNodeBelow

  return isCurrentNodeBelow
}

const ChartProvider = ({ children, organize }) => {
  const [toastStatus, setToastStatus] = useState(0)
  const { isTreeLoading, tree, reloadTree, reloadCurrentNode } = useAppContext()

  const reload = (
    { organization, currentNode } = { organization: true, currentNode: true }
  ) => {
    if (organization) {
      reloadTree()
    }
    if (currentNode) {
      reloadCurrentNode()
    }
  }

  return (
    <ChartContext.Provider
      value={{
        organization: tree,
        isLoading: isTreeLoading,

        toastStatus,
        setToastStatus,

        organize,
        reload,
      }}
    >
      {children}
    </ChartContext.Provider>
  )
}

ChartProvider.propTypes = {
  currentScope: PropTypes.object,
  children: PropTypes.any,
  organize: PropTypes.bool,
}

ChartProvider.defaultProps = {
  organize: false,
}

export default ChartProvider
