import { round } from "services/units/round"
import i18n from "i18next"

/**
 * Converts datasets by stacking small values (< 2% of total) into an "Others" category
 * @param {number[]} data
 * @param {string[]} labels
 * @returns {{filteredData: number[], filteredLabels: string[]}}
 */

export const FilterTooLittleValues = (data, labels) => {
  const threshold = 0.02
  const total = data.reduce((acc, value) => acc + value, 0)
  const newData = data.map((value) => {
    if (value / total < threshold) {
      return 0
    }
    return value
  })

  const others = data.reduce((acc, value) => {
    if (value / total < threshold) {
      return acc + value
    }
    return acc
  }, 0)
  newData.push(others)
  const newLabels = labels?.map((label, index) => {
    if (newData[index] === 0) {
      return
    }
    return label
  })
  if (others !== 0) newLabels.push(i18n.t("common:equipment_types.other"))

  const filteredData = newData
    .filter((value) => value !== 0)
    .map((value) => round(value))

  const filteredLabels = newLabels?.filter((label) => label !== undefined)

  return { filteredData, filteredLabels }
}

/**
 * Converts datasets by stacking small values (< 2% of total) into an "Others" category
 * @param datasets
 * @param labelKeys
 * @returns {*}
 */

export const FilterTooLittleValuesInDatasets = (datasets, labelKeys) => {
  return datasets.map((dataset) => {
    const { filteredData, filteredLabels } = FilterTooLittleValues(
      dataset.data,
      labelKeys
    )
    return {
      ...dataset,
      data: filteredData,
      labelKeys: filteredLabels,
    }
  })
}
