import React from "react"
import PropTypes from "prop-types"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import { useTranslation } from "react-i18next"
import {
  TextChart,
  TextChartMajor,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import Unit from "views/atoms/reports/Unit"
import NumberFormatterApp from "services/apps/numberFormatterApp"
import { EMISSION_UNIT } from "utils/constants/numbers"
import ChartBadge from "views/atoms/badges/ChartBadge"
import CountryApp from "services/apps/countryApp"
import ChartOdd13ExternalAppEmissions from "views/molecules/canvases/charts/ChartOdd13ExternalAppEmissions"
import ScoreLayoutOrganism from "views/organisms/scores/ScoreLayoutOrganism"
import TCO2e from "views/atoms/units/TCO2e"
import KGCO2e from "views/atoms/units/KGCO2e"
import GCO2e from "views/atoms/units/GCO2e"
import DonutChartTemplate from "views/molecules/charts/template/DonutChartTemplate"
import BarChartTemplate from "views/molecules/charts/template/BarChartTemplate"
import { convertValuesToReadable } from "services/units/valueConversion"
import { CO2_EMISSION_UNITS, T_CO2E } from "services/units/units"
import { round } from "services/units/round"

const SaasAnalysisOrganism = ({ score }) => {
  const { t } = useTranslation()
  const tPrefix = "analyze:it_domain.consolidated.saas"

  const units = {
    t: <TCO2e />,
    tCO2e: <TCO2e />,
    tSimple: t(`units:units.emission.t_unit`),
    kg: <KGCO2e />,
    g: <GCO2e />,
    gCO2e: <GCO2e />,
    kgCO2e: <KGCO2e />,
    gb: t(`units:units.storage.gb_unit`),
  }
  units.gPerGb = (
    <>
      <Unit unit={NumberFormatterApp.getI18nUnit(EMISSION_UNIT, "g_unit")} />{" "}
      {t(`${tPrefix}.per`)} {units.gb}
    </>
  )

  const perFte = (baseUnit) => (
    <>
      {baseUnit} {t(`${tPrefix}.per_fte`)}
    </>
  )

  const perGb = (baseUnit) => (
    <>
      {baseUnit} {t(`${tPrefix}.per_gb`)}
    </>
  )

  const [
    totalEmissions,
    totalEmissionsUnitEnum,
  ] = NumberFormatterApp.getNumberFormatter(
    Number(score.CO2_emissions.value) * 1000,
    "emission",
    "kg_unit"
  )
  const totalEmissionsUnit = (
    <Unit
      unit={NumberFormatterApp.getI18nUnit(
        EMISSION_UNIT,
        totalEmissionsUnitEnum
      )}
    />
  )

  const chart1 = () => {
    const content = {
      kpi: totalEmissions,
      kpi_unit: totalEmissionsUnit,
      kpi_text: t(`${tPrefix}.emissions.title`),
      reference: score.CO2_emissions.percentage,
      reference_text: t(`analyze:websites.ghg_emissions.sub_text`),
      reference_unit: "%",
    }

    return (
      <HelpWidgetChartInteractiveMolecule text={t(`${tPrefix}.emissions.help`)}>
        <TextChart content={<TextChartMajor content={content} />} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }

  const statsForAppFunction = (appFunction) => {
    const localPrefix = `${tPrefix}.${appFunction}`
    const stats = score[appFunction]

    const content = {
      kpi: stats.emissions_per_fte,
      kpi_unit: perFte(
        units[stats.emissions_per_fte_unit] || stats.emissions_per_fte_unit
      ),
      kpi_text: t(`${localPrefix}.kpi_text`),
      sub_kpi: stats.emission_factor,
      sub_kpi_unit: units.gPerGb,
      reference: (
        <ChartBadge
          value={stats.emission_factor}
          lowerLimit={2}
          upperLimit={3}
          inverse
        />
      ),
    }

    return (
      <HelpWidgetChartInteractiveMolecule text={t(`${localPrefix}.help`)}>
        <TextChart content={<TextChartMinor content={content} />} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }

  const averageEmissions = () => {
    const localPrefix = `${tPrefix}.average_CO2`
    const stats = score.average_CO2

    const content = {
      kpi: round(stats.per_fte),
      kpi_unit: perFte(units[stats.unit]),
      kpi_text: t(`${localPrefix}.title`),
    }

    return (
      <HelpWidgetChartInteractiveMolecule text={t(`${localPrefix}.help`)}>
        <TextChart content={<TextChartMinor content={content} />} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }

  const averageEmissionFactor = () => {
    const localPrefix = `${tPrefix}.emission_factor`
    const { value, unit } = score.emission_factor

    const content = {
      kpi: value,
      kpi_unit: perGb(units[unit]),
      kpi_text: t(`${localPrefix}.title`),
    }

    return (
      <HelpWidgetChartInteractiveMolecule text={t(`${localPrefix}.help`)}>
        <TextChart content={<TextChartMinor content={content} />} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }

  const emissionsPerApp = () => {
    const localPrefix = `${tPrefix}.emissions_per_app`
    const { value, unit } = score.emissions_per_application

    const content = {
      kpi: value ? value : "N/A",
      kpi_unit: value ? t(`units:units.emission_per_app.${unit}`) : undefined,
      kpi_text: t(`${localPrefix}.title`),
    }

    return (
      <HelpWidgetChartInteractiveMolecule text={t(`${localPrefix}.help`)}>
        <TextChart content={<TextChartMinor content={content} />} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }

  const chart3 = () => {
    const localPrefix = `${tPrefix}.locations`

    const data = score.locations.map(({ label, data }) => ({
      data,
      label: label.length === 2 ? CountryApp.getNameI18n(label) : label,
    }))

    const transformedData = data.reduce(
      (acc, { label, data }) => {
        acc.labels.push(label)
        acc.data.push(data)
        return acc
      },
      { labels: [], data: [] }
    )

    return (
      <DonutChartTemplate
        title={t(`${localPrefix}.title`)}
        data={transformedData.data}
        labelKeys={transformedData.labels}
        unitPath={"units:units.emission"}
        unitKey={totalEmissionsUnitEnum}
        helpText={t(`${localPrefix}.help`)}
      />
    )
  }

  const chart6 = () => {
    const localPrefix = `${tPrefix}.providers`

    const convertedData = convertValuesToReadable(
      score.providers.map(({ data }) => data),
      T_CO2E,
      CO2_EMISSION_UNITS
    )
    const labels = score.providers.map(({ label }) => label)

    return (
      <BarChartTemplate
        title={t(`${localPrefix}.title`)}
        data={convertedData.values}
        labelKeys={labels}
        unitPath={"units:units.emission"}
        unitKey={convertedData.unit}
        helpText={t(`${localPrefix}.help`)}
      />
    )
  }
  return (
    <ScoreLayoutOrganism
      maturity={score?.score ?? 0}
      chart1={chart1()}
      chart2={score.mail ? statsForAppFunction("mail") : averageEmissions()}
      chart3={chart3()}
      chart4={<ChartOdd13ExternalAppEmissions score={score} />}
      chart5={
        score.collab
          ? statsForAppFunction("collab")
          : score.emission_factor.value !== null
          ? averageEmissionFactor
          : emissionsPerApp()
      }
      chart6={chart6()}
    />
  )
}

const summaryPropType = PropTypes.shape({
  emissions_per_fte: PropTypes.number.isRequired,
  emissions_per_fte_unit: PropTypes.string.isRequired,
  emission_factor: PropTypes.number.isRequired,
  badge: PropTypes.string.isRequired,
})

SaasAnalysisOrganism.propTypes = {
  score: PropTypes.shape({
    score: PropTypes.number.isRequired,
    CO2_emissions: PropTypes.shape({
      value: PropTypes.number.isRequired,
      percentage: PropTypes.number.isRequired,
    }).isRequired,
    mail: summaryPropType.isRequired,
    collab: summaryPropType.isRequired,
    average_CO2: PropTypes.shape({
      per_fte: PropTypes.number.isRequired,
      unit: PropTypes.string.isRequired,
    }).isRequired,
    emission_factor: PropTypes.number.isRequired,
    locations: PropTypes.array.isRequired,
    applications: PropTypes.object.isRequired,
    providers: PropTypes.object.isRequired,
  }).isRequired,
}

export default SaasAnalysisOrganism
