import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import StackedBarChartTemplate from "views/molecules/charts/template/StackedBarChartTemplate"
import SCORE_CONFIG from "assets/configs/score"

const ChartOdd13NetworkAndHostingEquipementsGHGEmissionsMolecule = ({
  stackedChart = [],
}) => {
  const { t } = useTranslation()
  const translationPrefix = "score:score.13."
  const unit = stackedChart[0]?.unit
  const labels = stackedChart.map((node) =>
    t(`common:equipment_types.${node.label}`)
  )

  const dataFab = stackedChart.map(
    (node) => node.fab_data_to_be_displayed_hover
  )
  const dataUse = stackedChart.map(
    (node) => node.use_data_to_be_displayed_hover
  )

  const getFabAndUseCompareBarColors = (values, valuesToCompare) => {
    return values.map((value, index) => {
      const valueToCompare = valuesToCompare[index]

      if (value < valueToCompare) {
        return SCORE_CONFIG.common.colorsEvaluation.green
      } else if (value > valueToCompare) {
        return SCORE_CONFIG.common.colorsEvaluation.yellow
      } else {
        return SCORE_CONFIG.common.colorsEvaluation.yellow2
      }
    })
  }

  let data = [
    {
      label: t(
        `score:score.13.chartNetworkEquipementsGHGEmissions.manufacturer`
      ),
      data: dataFab,
      values: stackedChart.map((node) => node.fab_data_to_be_displayed),
      backgroundColor: getFabAndUseCompareBarColors(dataFab, dataUse),
      unit,
    },
    {
      label: t(`score:score.13.chartNetworkEquipementsGHGEmissions.use`),
      data: dataUse,
      values: stackedChart.map((node) => node.use_data_to_be_displayed),
      backgroundColor: getFabAndUseCompareBarColors(dataUse, dataFab),
      unit,
    },
  ]

  return (
    <StackedBarChartTemplate
      title={t(`${translationPrefix}chartNetworkEquipementsGHGEmissions.title`)}
      datasets={data}
      labelKeys={labels}
      unitPath="units:units.emission"
      unitKey={unit}
      helpText={t(
        `${translationPrefix}chartNetworkEquipementsGHGEmissions.help`
      )}
      hiddenLegend
      useBackgroundColorInDatasets
      showLabelAndUnitOnBar
      showLabelOnBarAboveValue
      fabAndUseCompareBarColors
    />
  )
}

ChartOdd13NetworkAndHostingEquipementsGHGEmissionsMolecule.propTypes = {
  stackedChart: PropTypes.array.isRequired,
}

export default ChartOdd13NetworkAndHostingEquipementsGHGEmissionsMolecule
