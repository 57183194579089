import { COLORS } from "utils/constants/colors"
import "chartjs-plugin-datalabels"
import _ from "lodash"

export const dataLabelsPlugin = (
  unit = undefined,
  label = undefined,
  valueAndUnitOn2lines = false
) => {
  return {
    datalabels: {
      display: function (context) {
        const summedData = context.chart.data.datasets.map(
          (dataset, datasetIndex) => {
            return _.sumBy(context.chart.data.datasets, (dataset) =>
              dataset.type === "line" ? 0 : dataset.data[datasetIndex]
            )
          }
        )
        const valueMax = Math.max(..._.without(summedData, null))

        const currentValue = context.dataset.data[context.dataIndex]
        return currentValue / valueMax > 0.05
      },

      formatter: function (value, context) {
        return unit && label
          ? `${label[context.dataIndex]}\n${value} ${unit}`
          : unit
          ? valueAndUnitOn2lines
            ? `${value}\n${unit}`
            : value + " " + unit
          : value
      },
      color: COLORS.white,
      font: {
        weight: "bold",
      },
      align: "center",
      textAlign: "center",
    },
  }
}

export const dataLabelsPluginComparative = (unit) => {
  return {
    datalabels: {
      display: function (context) {
        return context.dataset.data[context.dataIndex] !== 0
      },

      formatter: function (value, context) {
        return (
          `${context.dataset.label[0]}\n` +
          context.dataset.value[context.dataIndex] +
          " " +
          unit
        )
      },
      textAlign: "center",
      color: COLORS.white,
      font: {
        weight: "bold",
      },
    },
  }
}
