import React, { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import LocalCommunityInitiative from "components/widgets/CollectPage/forms/inventories/social/LocalCommunityInitiative"
import GoodProject from "components/widgets/CollectPage/forms/inventories/social/GoodProject"
import { Checkbox, FormLayout } from "@shopify/polaris"
import {
  ExternalLabel,
  TextField,
  YesNoRadio,
} from "components/widgets/CollectPage/forms/fields/CollectFields"
import { useTranslation } from "react-i18next"
import Form from "components/widgets/CollectPage/forms/Form"
import FormContextProvider, {
  useFormConfig,
  useFormContext,
} from "../../FormContext"
import useNullifyingField from "../../../useNullifyingField"
import { useCollectContext } from "../../../CollectContextProvider"

const PositiveImpactForm = () => {
  return (
    <div className="columns">
      <div className="column">
        <FormContextProvider>
          <LocalCommunityInitiative />
        </FormContextProvider>
      </div>
      <div className="column">
        <FormContextProvider>
          <GoodProject />
        </FormContextProvider>
      </div>
    </div>
  )
}

const fields = [
  "patronage_existence",
  "foundation_support",
  "social_intrapreneuriat_existence",
  "patronage_number_of_intiatives",
  "foundation_number_of_intiatives",
  "social_intrapreneuriat_number_of_intiatives",
  "patronage_budget",
  "foundation_budget",
  "social_intrapreneuriat_budget",
  "group_societal_commitments_existence",
  "engagement_monitored_by_DSI",
  "societal_commitments_comment",
  "mission_driven_company",
]
const translationPath = "1_2_11.societal_commitments"

const Societal_commitments = () => {
  const { t } = useTranslation()
  const { isCollectLocked } = useCollectContext()
  const [disabled, setDisabled] = useState({})

  const { formData, setFormData } = useFormContext()

  useFormConfig({ fields, translationPath })

  // Set disabled fields
  useEffect(() => {
    const checkbox1 = formData.patronage_existence
    const checkbox2 = formData.foundation_support
    const checkbox3 = formData.social_intrapreneuriat_existence
    setDisabled({
      ...disabled,
      // Checkbox1: Patronage
      patronage_number_of_intiatives: !checkbox1,
      patronage_budget: !checkbox1,
      // Checkbox2: Fondation
      foundation_number_of_intiatives: !checkbox2,
      foundation_budget: !checkbox2,
      // Checkbox3: Social Intrapreneuriat
      social_intrapreneuriat_number_of_intiatives: !checkbox3,
      social_intrapreneuriat_budget: !checkbox3,
      // Societal commitment comments
      societal_commitments_comment:
        formData.engagement_monitored_by_DSI === false,
    })
  }, [formData])

  useNullifyingField(
    "patronage_existence",
    ["patronage_number_of_intiatives", "patronage_budget"],
    false
  )
  useNullifyingField(
    "foundation_support",
    ["foundation_number_of_intiatives", "foundation_budget"],
    false
  )
  useNullifyingField(
    "social_intrapreneuriat_existence",
    [
      "social_intrapreneuriat_number_of_intiatives",
      "social_intrapreneuriat_budget",
    ],
    false
  )
  useNullifyingField(
    "engagement_monitored_by_DSI",
    ["societal_commitments_comment"],
    false
  )

  const prefix = "collect:questions.1_2_11.societal_commitments"

  const tableMarkup = () => {
    return (
      <table style={{ tableLayout: "fixed" }}>
        <thead>
          <tr>
            <th></th>
            <th>{t(`${prefix}.table.column_1`)}</th>
            <th>{t(`${prefix}.table.column_2`)}</th>
            <th>{t(`${prefix}.table.column_3`)}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t(`${prefix}.table.row_1`)}</td>
            {[
              "patronage_existence",
              "foundation_support",
              "social_intrapreneuriat_existence",
            ].map((id) => {
              return (
                <td key={id} className="centered">
                  <Checkbox
                    id={id}
                    checked={formData[id] == true}
                    onChange={(value) =>
                      setFormData({ ...formData, [id]: value })
                    }
                    disabled={isCollectLocked}
                  />
                </td>
              )
            })}
          </tr>
          <tr>
            <td>{t(`${prefix}.table.row_2`)}</td>
            {[
              "patronage_number_of_intiatives",
              "foundation_number_of_intiatives",
              "social_intrapreneuriat_number_of_intiatives",
            ].map((id) => {
              return (
                <td key={id}>
                  <TextField id={id} type="number" disabled={disabled[id]} />
                </td>
              )
            })}
          </tr>
          <tr>
            <td>{t(`${prefix}.table.row_3`)}</td>
            {[
              "patronage_budget",
              "foundation_budget",
              "social_intrapreneuriat_budget",
            ].map((id) => {
              return (
                <td key={id}>
                  <TextField
                    id={id}
                    type="number"
                    suffix={t(`${prefix}.table.unit`)}
                    disabled={disabled[id]}
                  />
                </td>
              )
            })}
          </tr>
        </tbody>
      </table>
    )
  }

  return (
    <Form>
      <div className="columns">
        <div className="column column-23">
          <ExternalLabel translationPathName="" />
          <br />
          {tableMarkup()}{" "}
        </div>
        <div className="column">
          <FormLayout>
            <YesNoRadio id="mission_driven_company" />
            <YesNoRadio id="group_societal_commitments_existence" />
            <YesNoRadio
              id="engagement_monitored_by_DSI"
              disabled={!formData.group_societal_commitments_existence}
            />
            <div className="SDG-1-2-11-TextInput">
              <TextField
                id="societal_commitments_comment"
                multiline={4}
                rows={4}
                disabled={!formData.engagement_monitored_by_DSI}
                type="text"
              />
            </div>
          </FormLayout>
        </div>
      </div>
    </Form>
  )
}

export default () => {
  const { themeId } = useParams()
  const themeFormMarkup = useCallback(() => {
    switch (themeId) {
      case "positive_impact":
        return <PositiveImpactForm />
      case "societal_commitments":
        return <Societal_commitments />
      default: {
        return <p>Aucun formulaire pour ce thème.</p>
      }
    }
  }, [themeId])
  return themeFormMarkup()
}
