import React from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import _ from "lodash"
import { useFormContext } from "components/widgets/CollectPage/forms/FormContext"

const ResponsibleConsumptionTableHeaderMolecule = ({ equipmentTypes, domain }) => {
  const { t } = useTranslation()
  const { formData } = useFormContext()
  let getUpstreamLifetimeArray = []

  const hiddenUpstreamLifetimeColumn = equipmentTypes.every((equipmentType) => {
    const collectedSecondHandRatio = _.get(formData, `collected_equipments.[${domain}].[${equipmentType}].second_hand_ratio`, null)
    getUpstreamLifetimeArray.push(collectedSecondHandRatio)
    return +collectedSecondHandRatio === 0 || collectedSecondHandRatio === null || collectedSecondHandRatio === undefined
  })

  return (
    <thead>
    <tr>
      <th></th>
      <th>
        {t(`collect:questions.12.sustainable_consumption.table_1.column_2`)}
      </th>
      <th hidden={hiddenUpstreamLifetimeColumn}>
        {t(`collect:questions.12.sustainable_consumption.table_1.column_4`)}
      </th>
      {domain === "workplace" && (
        <th>
          {t(`collect:questions.12.sustainable_consumption.table_1.column_3`)}
        </th>
      )}
    </tr>
    </thead>
  )
}

export default ResponsibleConsumptionTableHeaderMolecule

ResponsibleConsumptionTableHeaderMolecule.propTypes = {
  domain: PropTypes.string.isRequired
}