import React from "react"
import ChartAnalysisCloudServicesAndCosts from "views/molecules/canvases/charts/cloud/ChartAnalysisCloudServicesAndCosts"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import ChartAnalysisCloudMainProvider from "views/molecules/canvases/charts/cloud/ChartAnalysisCloudMainProvider"

const ChartCloudLowUseDispatcher = ({ envScore }) => {
  const { t } = useTranslation()
  const top_clouder_per_region = envScore?.cloud_score?.top_clouder_per_region
  const biggest_emissions_of_low_service_use =
    envScore?.cloud_score?.biggest_emissions_of_low_service_use

  return biggest_emissions_of_low_service_use ? (
    <ChartAnalysisCloudServicesAndCosts
      datasets={biggest_emissions_of_low_service_use?.datasets || []}
      labels={biggest_emissions_of_low_service_use?.label_keys || []}
      unitKey={biggest_emissions_of_low_service_use?.unit_key || ""}
      lineUnitKey={biggest_emissions_of_low_service_use?.line_unit_key || ""}
      title={t(
        "analyze:it_domain.cloud.charts.biggest_emissions_of_low_service_use.title"
      )}
    />
  ) : (
    <ChartAnalysisCloudMainProvider
      datasets={top_clouder_per_region?.datasets || []}
      labels={top_clouder_per_region?.label_keys || []}
      unitKey={top_clouder_per_region?.unit_key || ""}
    />
  )
}

ChartCloudLowUseDispatcher.propTypes = {
  envScore: PropTypes.object.isRequired,
}

export default ChartCloudLowUseDispatcher
