import PropTypes from "prop-types"
import React from "react"
import ITDomainAnalysisLayoutOrganism from "views/organisms/canvases/charts/ITDomainAnalysisLayoutOrganism"
import TextChartODD13ScopeMolecule from "views/molecules/canvases/charts/GHGProtocol/TextChartODD13ScopeMolecule"
import ChartODD13ScopesBreakdownMolecule from "views/molecules/canvases/charts/GHGProtocol/ChartODD13ScopesBreakdownMolecule"
import ChartODD13ScopesStackPerGroupBreakdownMolecule from "views/molecules/canvases/charts/GHGProtocol/ChartODD13ScopesStackPerGroupBreakdownMolecule"
import TextChartODD13GHGEmissionsMolecule from "views/molecules/canvases/charts/TextChartODD13GHGEmissionsMolecule"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import { useTranslation } from "react-i18next"

const GHGScopesDetailsOrganism = ({ score, protocol }) => {
  const { t } = useTranslation()

  return (
    <ITDomainAnalysisLayoutOrganism
      maturity={score?.odd13_maturity ?? 0}
      chart1={
        <TextChartODD13GHGEmissionsMolecule
          score={score}
          ghgEmissions={score?.odd13_CO2}
        />
      }
      // chart1={
      //   <TextChartODD13ScopeMolecule
      //     score={score}
      //     scope1="total"
      //     // referenceScope1="total_share_global"
      //   />
      // }
      chart2={
        <HelpWidgetChartInteractiveMolecule
          text={t(
            `analyze:ghg_scopes.textChartODD13ScopeMolecule.title.scope2Help`
          )}
        >
          <TextChartODD13ScopeMolecule
            score={score}
            scope1="scope2"
            // referenceScope1="percentage_scope2_share_global"
          />
        </HelpWidgetChartInteractiveMolecule>
      }
      chart3={
          <ChartODD13ScopesStackPerGroupBreakdownMolecule
            score={score}
            protocol={protocol}
            group="scopes"
            stack="sub_scopes"
            helpText={t(`analyze:ghg_scopes.chartODD13ScopesStackPerGroupBreakdownMolecule.title.scopes_and_sub_scopes_help`)}
          />
      }
      chart4={
        <HelpWidgetChartInteractiveMolecule
          text={t(`analyze:ghg_scopes.chartODD13ScopesBreakdownMolecule.help`)}
        >
          <ChartODD13ScopesBreakdownMolecule
            emissions_breakdown_per_scope={score?.emissions_breakdown_per_scope}
          />
        </HelpWidgetChartInteractiveMolecule>
      }
      chart5={
        <HelpWidgetChartInteractiveMolecule
          text={t(
            `analyze:ghg_scopes.textChartODD13ScopeMolecule.title.scope3Help`
          )}
        >
          <TextChartODD13ScopeMolecule
            score={score}
            scope1="scope3"
            // referenceScope1="percentage_scope3_share_global"
          />
        </HelpWidgetChartInteractiveMolecule>
      }
      chart6={
          <ChartODD13ScopesStackPerGroupBreakdownMolecule
            score={score}
            protocol={protocol}
            group="sub_scopes"
            stack="kpis"
            helpText={t(`analyze:ghg_scopes.chartODD13ScopesStackPerGroupBreakdownMolecule.title.sub_scopes_and_kpis_help`)}
          />
      }
    />
  )
}

GHGScopesDetailsOrganism.propTypes = {
  score: PropTypes.object.isRequired,
  protocol: PropTypes.string.isRequired,
}

export default GHGScopesDetailsOrganism
