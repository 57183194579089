import { BehaviorMajor } from "@shopify/polaris-icons"
import { Icon } from "@shopify/polaris"
import React from "react"
import NodeActionWithConfirmMolecule from "components/widgets/Dashboard/OrganizationChart/NodeActionWithConfirmMolecule"
import { useTranslation } from "react-i18next"

const UnavailableConsolidateAction = () => {
  const { t } = useTranslation()

  const { title, text } = t(
    "dashboard:chart.node_actions.consolidation_unavailable",
    {
      returnObjects: true,
    }
  )

  const icon = (
    <div className="nodeIcon--unavailable">
      <Icon source={BehaviorMajor} />
    </div>
  )

  const props = { title, text, icon }

  return <NodeActionWithConfirmMolecule {...props} />
}

export default UnavailableConsolidateAction
