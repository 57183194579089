import React, { useCallback } from "react"
import classNames from "classnames"
import ConditionalLink from "components/widgets/ConditionalLink"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import ScoreValue from "components/widgets/ScoreValue"
import ScoreIcon from "components/widgets/ScoreIcon"
import Cell from "components/widgets/Dashboard/Cell"
import CO2EValue from "components/widgets/CO2EValue"
import { useAppContext } from "components/AppContext"
import { useFeatureFlag } from "hooks/useFeatureFlag"
import { showMaturity } from "services/score/maturity/showMaturity"

const Column = ({ score, pillarId, disabled, cells, quantitative }) => {
  const { currentRootNode, currentOrganization, currentNode } = useAppContext()
  const { isFeatureActive } = useFeatureFlag()
  const { t } = useTranslation()
  const isDisabled = disabled || score == null

  const headerScoreMarkup = useCallback(() => {
    if (isDisabled) {
      return null
    }

    return (
      <div className="Verdikt-Score-Column-Header-Score">
        {!showMaturity(
          currentOrganization,
          currentRootNode,
          currentNode,
          isFeatureActive
        ) ? (
          <CO2EValue value={score["odd13_CO2"]} light pillarId={pillarId} />
        ) : (
          <ScoreValue
            score={score["pillar_maturity"]}
            light
            pillarId={pillarId}
          />
        )}
        <ScoreIcon score={score["pillar_maturity"]} />
      </div>
    )
  }, [pillarId, score, isDisabled])

  return (
    <div className={classNames(pillarId, "column")}>
      <ConditionalLink to={`/score/${pillarId}`} condition={!isDisabled}>
        <div
          className={classNames({
            header: true,
            colored: true,
            quantitative,
            disabled: isDisabled,
          })}
        >
          <h2>{t(`common:${pillarId}`)}</h2>
          {headerScoreMarkup()}
        </div>
      </ConditionalLink>

      <ul className="sdg">
        {cells.map((cell) => (
          <Cell
            key={cell.sdg}
            score={score}
            pillarId={pillarId}
            cell={cell}
            isDisabled={isDisabled}
            quantitative={quantitative}
          />
        ))}
      </ul>
    </div>
  )
}

Column.propTypes = {
  score: PropTypes.object,
  pillarId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  setShowObjectiveSwitch: PropTypes.func,
  cells: PropTypes.arrayOf(
    PropTypes.shape({
      sdg: PropTypes.number.isRequired,
      maturity_field: PropTypes.string.isRequired,
      quantitative_field: PropTypes.string.isRequired,
      quantitative_text: PropTypes.bool,
      unit: PropTypes.string,
      take_more_space: PropTypes.bool,
    })
  ),
  quantitative: PropTypes.bool,
}

Column.defaultProps = {
  disabled: false,
  quantitative: false,
}

export default Column
