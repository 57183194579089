import Config from "assets/configs/score.js"
import SCORE_CONFIG from "assets/configs/score.js"
import {
  TextChart,
  TextChartMajor,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import ChartBadge from "views/atoms/badges/ChartBadge"
import BarChartMolecule from "views/molecules/charts/BarChartMolecule"
import { useAppContext } from "components/AppContext"
import ScoreLayoutOrganism from "views/organisms/scores/ScoreLayoutOrganism"
import { COLORS } from "utils/constants/colors"
import { PILLAR_ENVIRONMENTAL } from "utils/constants/pillars"
import { round } from "services/units/round"
import StackedBarChartTemplate from "views/molecules/charts/template/StackedBarChartTemplate"
import AvgBarChartTemplate from "views/molecules/charts/template/AvgBarChartTemplate"

const colorByReference = (value, reference) => {
  return value > reference ? COLORS.danger.primary : COLORS.planet.primary
}

const YEARLY_PRINTING_PER_FTE_MIN = 1200
const YEARLY_PRINTING_PER_FTE_MAX = 2500

const Objective15Score = ({ score, collect }) => {
  const { currentRootNode } = useAppContext()
  const { t } = useTranslation()
  const translationPrefix = "score:score.15."

  const chart1 = useCallback(() => {
    let avoided_waste =
      Math.round(score["odd15_scarce_resources_avoided_waste"]) ?? 0
    let unit = translationPrefix + "chart1.kg_unit"

    if (avoided_waste > 500) {
      avoided_waste = (avoided_waste / 1000).toFixed(1)
      unit = translationPrefix + "chart1.t_unit"
    }

    const content = {
      kpi: avoided_waste,
      kpi_unit: t(unit),
      kpi_text: t(translationPrefix + "chart1.text"),
      translationPrefix_chartX: translationPrefix + "chart1.",
    }

    const content_for_display = <TextChartMajor content={content} />

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart1.help")}
      >
        <TextChart content={content_for_display} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  const chart2 = useCallback(() => {
    const printing_tree_volume = score["odd15_printing_tree_volume"]

    const badge = (
      <ChartBadge
        value={printing_tree_volume}
        lowerLimit={40}
        upperLimit={70}
        inverse={true}
      />
    )

    const content = {
      reference: badge,
      kpi: printing_tree_volume,
      kpi_unit: t(translationPrefix + "chart2.unit"),
      kpi_text: t(translationPrefix + "chart2.text"),
    }

    const content_for_display = <TextChartMinor content={content} />

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart2.help")}
      >
        <TextChart content={content_for_display} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  const chart3 = useCallback(() => {
    const config = Config["15"]["chart3"]
    let deee = score["odd15_electronic_waste_deee_per_employee"]
    let paper = (
      Number(score["odd15_printing_print_per_fte"]) *
      Number(config["paper_weight"])
    ).toFixed(0)

    let deee_reference = Number(config["thresold"]["deee"])
    let paper_reference = Number(config["thresold"]["paper"])

    return (
      <AvgBarChartTemplate
        title={t(translationPrefix + "chart3.title")}
        data={[deee, paper]}
        dataBarTitle={t("score:score.common.datasets.client_data")}
        referenceData={[deee_reference, paper_reference]}
        referenceDataDiplay={false}
        labelKeys={[
          t(`score:score.15.chart3.deee`),
          t(`score:score.15.chart3.paper`),
        ]}
        unitPath={"units:units.mass"}
        unitKey={"kg_unit"}
        helpText={t(translationPrefix + "chart3.help")}
      />
    )
  }, [score, collect])

  const chart4 = useCallback(() => {
    const odd15_scarce_resources = score.odd15_scarce_resources

    const datasets = odd15_scarce_resources?.datasets?.map((dataset) => {
      dataset.label = t(`score:score.15.chart4.axes.${dataset.label_key}`)
      dataset.backgroundColor =
        SCORE_CONFIG["15"].chart4.scarce_resources[dataset.label_key]
      return dataset
    })

    const labels = odd15_scarce_resources?.labels?.map((label) => {
      const translatedLabel = t(`score:score.15.chart4.labels.${label}`)
      if (label === "material") {
        return `${translatedLabel} (${t(
          `units:units.mass.${odd15_scarce_resources.units[0]}`
        )})`
      }
      return `${translatedLabel} (${t(
        `units:units.mass.${odd15_scarce_resources.units[1]}`
      )})`
    })

    let data = {
      labels: labels,
      datasets,
    }

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart4.help")}
      >
        <BarChartMolecule
          title={t(translationPrefix + "chart4.title")}
          data={data}
          plainData={odd15_scarce_resources?.plain_data}
          yLabel="%"
          max={100}
          labelCallback={(tooltipItem, data) => {
            const value =
              odd15_scarce_resources?.datasets[tooltipItem.datasetIndex]?.data[
                tooltipItem.index
              ]
            return `${
              data?.datasets[tooltipItem.datasetIndex]?.label
            }: ${value}%`
          }}
          datalabelsFormatter={(value, context) => {
            if (value <= 5) {
              return null
            }

            const plainValue =
              odd15_scarce_resources?.plain_data[context.datasetIndex][
                context.dataIndex
              ]

            const unit = t(
              `units:units.mass.${
                odd15_scarce_resources?.datasets[context.datasetIndex]?.unit
              }`
            )

            return plainValue + unit
          }}
          pillarId={PILLAR_ENVIRONMENTAL}
        />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  const chart5 = useCallback(() => {
    let printing_print_per_fte = score["odd15_printing_print_per_fte"]
    let unit = ""

    const lowerValue = YEARLY_PRINTING_PER_FTE_MIN * (score.period_months / 12)
    const upperValue = YEARLY_PRINTING_PER_FTE_MAX * (score.period_months / 12)

    const badge = (
      <ChartBadge
        value={printing_print_per_fte}
        lowerLimit={lowerValue}
        upperLimit={upperValue}
        inverse={true}
      />
    )

    // Check to see if number is too high, if so divise by 1000 and adds additional unit
    if (printing_print_per_fte > 10000) {
      printing_print_per_fte = (printing_print_per_fte / 1000).toFixed(0)
      unit = translationPrefix + "chart5.unit"
    }

    const text = currentRootNode?.is_corporate
      ? t("score:score.15.chart5.corporate.text")
      : t("score:score.15.chart5.provider.text")

    const content = {
      reference: badge,
      kpi: printing_print_per_fte,
      kpi_unit: t(unit),
      kpi_text: text,
    }

    const content_for_display = <TextChartMinor content={content} />

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart5.help")}
      >
        <TextChart content={content_for_display} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  const chart6 = useCallback(() => {
    const odd15_adp_resources = score.odd15_adp_resources

    function expo(x, f) {
      return Number.parseFloat(x).toExponential(f)
    }

    function convertDecimalToScientific(data) {
      return data.map((value) =>
        round(value) === 0 ? expo(value, 2) : round(value)
      )
    }

    const data = {
      labels: odd15_adp_resources.labels.map((label) =>
        t(`score:score.15.chart6.labels.${label}`)
      ),
      datasets: odd15_adp_resources.datasets.map((dataset) => ({
        data: (dataset.data = dataset.data.map((value) =>
          value === 0 ? null : value
        )),
        label: t(`common:equipment_types.${dataset.label}`),
        values: convertDecimalToScientific(dataset.values),
        unit: dataset.unit,
      })),
    }

    return (
      <StackedBarChartTemplate
        title={t(translationPrefix + "chart6.title")}
        datasets={data.datasets}
        labelKeys={data.labels}
        unitPath={"units:units.chemical_equivalent_units"}
        ifUnitsInDatasets
        helpText={t(translationPrefix + "chart6.help")}
        showValueAndUnitOn2lines
      />
    )
  }, [score])

  return (
    <ScoreLayoutOrganism
      maturity={score.odd15_maturity ?? 0}
      chart1={chart1()}
      chart2={chart2()}
      chart3={chart3()}
      chart4={chart4()}
      chart5={chart5()}
      chart6={chart6()}
    />
  )
}

Objective15Score.propTypes = {
  score: PropTypes.object.isRequired,
  collect: PropTypes.object.isRequired,
}

export default Objective15Score
