import { useTranslation } from "react-i18next"

const useTranslatedLabels = (labelPath, labelKeys) => {
  const { t } = useTranslation()

  return (
    labelKeys?.map((label) => {
      if (!labelPath) {
        return label
      }
      return t(`${labelPath}.${label}`)
    }) || []
  )
}

export default useTranslatedLabels
