import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import { compareNodeLabels } from "services/score/charts/labels/compareNodeLabels"
import MixedChartTemplate from "views/molecules/charts/template/MixedChartTemplate"

/**
 * @param mixedChart odd13_co2_equipment_emissions_top
 * @returns {JSX.Element}
 * @constructor
 */
const ChartODD13EquipmentFteTopEmissionsLocMolecule = ({ mixedChart = [] }) => {
  const { t } = useTranslation()
  const translationPrefix = "analyze:it_domain.consolidated.workplace."

  const labels = mixedChart.map((node) =>
    compareNodeLabels(node.label, true, true)
  )
  const bar = mixedChart.map((node) => node.bar)
  const line = mixedChart.map((node) => node.line)

  return (
    <MixedChartTemplate
      title={t(translationPrefix + "chartEquipmentFteTopEmissionsLoc.title")}
      leftDataInputs={line}
      leftDataBarTitle={t(translationPrefix + "chartEquipmentFteTopEmissionsLoc.legend.line")}
      rightDataInputs={bar}
      rightDataBarTitle={t(translationPrefix + "chartEquipmentFteTopEmissionsLoc.legend.bar")}
      labelKeys={labels}
      leftUnitPath="analyze:it_domain.consolidated.workplace"
      leftUnitKey="chartEquipmentFteTopEmissionsLoc.unit_left_axis"
      rightUnitPath="units:units.emission"
      rightUnitKey={mixedChart[0].bar_unit}
      helpText={t(`analyze:it_domain.consolidated.workplace.chartEquipmentFteTopEmissionsLoc.help`)}
    />
  )
}

ChartODD13EquipmentFteTopEmissionsLocMolecule.propTypes = {
  mixedChart: PropTypes.array.isRequired
}

export default ChartODD13EquipmentFteTopEmissionsLocMolecule