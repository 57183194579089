import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import NodeOrganization from "services/organizations/NodeOrganization"
import { STATUS_TO_COLORS } from "utils/constants/analyze/kpi/chartStatus"
import MixedChartTemplate from "views/molecules/charts/template/MixedChartTemplate"
import { convertValuesToReadable } from "services/units/valueConversion"
import { CO2_EMISSION_UNITS, KG_CO2E } from "services/units/units"

/**
 * @param score Api::charts::MixedChartSerializer
 * @param type String - used to display proper chart title
 * @returns {JSX.Element}
 * @constructor
 */

const ChartODD13EquipmentTypeVolumeAverageGHGMolecule = ({
  mixedChart = [],
  type = null,
}) => {
  const { t } = useTranslation()
  const translationPrefix = "score:score.13."

  const labels = mixedChart.map((node) => {
    switch (node?.type) {
      case "EquipmentScore": {
        return t(`common:equipment_types.${node.label}`)
      }

      default:
        NodeOrganization.getName(node?.label, node?.type, {
          countryShortName: true,
        })
    }
  })
  const bar = mixedChart.map((node) => node.bar)
  const line = mixedChart.map((node) => node.line)

  const title = type
    ? t(
        translationPrefix +
          "chartEquipmentTypeVolumeAverageGHG.specific_domain_title." +
          type
      )
    : t(translationPrefix + "chartEquipmentTypeVolumeAverageGHG.title")

  const barBackgroundColors = mixedChart.map(
    ({ status }) => STATUS_TO_COLORS[status]
  )

  const convertedLine = convertValuesToReadable(
    line,
    KG_CO2E,
    CO2_EMISSION_UNITS
  )

  const convertedBar = convertValuesToReadable(bar, KG_CO2E, CO2_EMISSION_UNITS)

  return (
    <MixedChartTemplate
      title={title}
      leftDataInputs={convertedLine.values}
      leftDataBarTitle={t(
        translationPrefix + "chartEquipmentTypeVolumeAverageGHG.legend.line"
      )}
      rightDataInputs={convertedBar.values}
      rightDataBarTitle={t(
        translationPrefix + "chartEquipmentTypeVolumeAverageGHG.legend.bar"
      )}
      labelKeys={labels}
      leftUnitPath="units:units.emission"
      leftUnitKey={convertedLine.unit}
      rightUnitPath="units:units.emission"
      rightUnitKey={convertedBar.unit}
      hideUnitOnBar
      modifiedBackgroundColor={barBackgroundColors}
      helpText={t(
        `analyze:it_domain.consolidated.workplace.chartEquipmentTypeVolumeAverageGHG.help`
      )}
    />
  )
}

ChartODD13EquipmentTypeVolumeAverageGHGMolecule.propTypes = {
  mixedChart: PropTypes.array.isRequired,
  type: PropTypes.string,
  afterBody: PropTypes.func,
}

ChartODD13EquipmentTypeVolumeAverageGHGMolecule.defaultProps = {
  type: null,
}

export default ChartODD13EquipmentTypeVolumeAverageGHGMolecule
