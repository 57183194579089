import React from "react"
import classNames from "classnames"
import { Bar } from "react-chartjs-2"
import Config from "assets/configs/score.js"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import { PILLAR_ENVIRONMENTAL } from "utils/constants/pillars"
import { COLORS } from "utils/constants/colors"
import { DEFAULT_OPTIONS } from "services/charts/default"
import { HIDDEN_LEGEND } from "services/charts/legend"
import { yAxes } from "services/charts/yAxes"

const TRANSLATION_PREFIX = "analyze:it_domain.cloud.charts.main_provider"
const TITLE_T_PATH = `${TRANSLATION_PREFIX}.title`
const REGIONS_T_PATH = `${TRANSLATION_PREFIX}.regions`

const ChartAnalysisCloudMainProvider = ({ datasets, labels, unitKey }) => {
  const { t } = useTranslation()

  const cutLabel = (label) => {
    return label.length > 10 ? `${label.slice(0, 10)}...` : label
  }

  const title = t(TITLE_T_PATH)
  const regionLabels = t(REGIONS_T_PATH, { returnObjects: true })

  const unit = t(`units:units.emission.${unitKey}`)

  const data = {
    labels: labels.map((label) => regionLabels[label]),
    datasets: datasets.map((dataset, index) => {
      return {
        label: cutLabel(dataset.label_key),
        data: dataset.data,
        backgroundColor:
          Config.common.colors[index % Config.common.colors.length],
        borderColor: COLORS.white,
        borderWidth: 1,
        stack: "default",
        showLabelInData: true,
        unit,
      }
    }),
  }

  const options = {
    ...DEFAULT_OPTIONS,
    legend: HIDDEN_LEGEND,
    ...yAxes(unit),
  }

  return (
    <div className={classNames("Verdikt-Score-BarChart", PILLAR_ENVIRONMENTAL)}>
      <h1>{title}</h1>
      <div className="content">
        <Bar options={options} data={data} />
      </div>
    </div>
  )
}

ChartAnalysisCloudMainProvider.propTypes = {
  datasets: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired,
  unitKey: PropTypes.string.isRequired,
}

export default ChartAnalysisCloudMainProvider
