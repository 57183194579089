import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import { isNodeLeaf } from "utils/constants/organization/nodeTypes"
import { OFFICE_365_FEATURE, useFeatureFlag } from "hooks/useFeatureFlag"
import { useAppContext } from "components/AppContext"

export const useITDomainTabs = ({ envScore, currentNode }) => {
  const { themeId } = useParams()
  const { t } = useTranslation()
  const history = useHistory()
  const { isFeatureActive } = useFeatureFlag()
  const { currentRootNode } = useAppContext()

  const isCorporate = currentRootNode?.is_corporate

  const hideTabsByNodeType = useMemo(() => isNodeLeaf(currentNode), [
    currentNode,
  ])

  const countChartsWithData = (envScore) => {
    const charts = [
      envScore?.odd13_internal_hosting_volume_emission_equipments,
      envScore?.odd13_network_volume_emission_equipment,
      envScore?.odd13_workplace_volume_emission_equipment,
    ]
    return charts.filter((chart) => chart && chart.length > 0).length
  }

  const chartsWithDataCount = countChartsWithData(envScore)

  const tabs = useMemo(() => {
    const tabsConfig = [
      {
        name: t(
          `collect:pillars.environmental.objectives.13.themes.equipments.title`
        ),
        hidden:
          (isCorporate === true ? false : chartsWithDataCount === 1) ||
          +envScore?.odd13_it_domain_co2 === 0,
        selected: themeId === "equipments",
        url: "/analyze/it-domain/environmental/13/equipments",
        co2e: envScore?.odd13_it_domain_co2 || 0,
      },
      {
        name: t(
          `collect:pillars.environmental.objectives.13.themes.workplace.title`
        ),
        selected: themeId === "workplace",
        hidden:
          hideTabsByNodeType ||
          !envScore?.odd13_workplace_CO2 ||
          +envScore?.odd13_workplace_CO2 === 0,
        url: "/analyze/it-domain/environmental/13/workplace",
        co2e: envScore?.odd13_workplace_CO2 || 0,
      },
      {
        name: t(
          `collect:pillars.environmental.objectives.13.themes.datacenter_internal.title`
        ),
        selected: themeId === "datacenter_internal",
        url: "/analyze/it-domain/environmental/13/datacenter_internal",
        hidden:
          hideTabsByNodeType ||
          !envScore?.odd13_datacenter_internal_CO2 ||
          +envScore?.odd13_datacenter_internal_CO2 === 0,
        co2e: envScore?.odd13_datacenter_internal_CO2 || 0,
      },
      {
        name: t(
          `collect:pillars.environmental.objectives.13.themes.network.title`
        ),
        selected: themeId === "network",
        hidden:
          hideTabsByNodeType ||
          !envScore?.odd13_network_CO2 ||
          +envScore?.odd13_network_CO2 === 0,
        url: "/analyze/it-domain/environmental/13/network",
        co2e: envScore?.odd13_network_CO2 || 0,
      },
      {
        name: t("analyze:it_domain.consolidated.apps.title"),
        selected: themeId === "apps",
        hidden: !envScore?.apps,
        url: "/analyze/it-domain/environmental/13/apps",
        co2e: envScore?.apps?.CO2_emissions?.value || 0,
      },
      {
        name: t(`analyze:tabs.cloud`),
        selected: themeId === "cloud",
        hidden: !envScore?.cloud_score,
        url: "/analyze/it-domain/environmental/13/cloud",
        co2e: envScore?.cloud_score?.co2e || 0,
      },
      {
        name: t("analyze:it_domain.consolidated.saas.title"),
        selected: themeId === "saas",
        hidden:
          !envScore ||
          !envScore.saas ||
          envScore.odd13_software_CO2_external_apps < 1,
        url: "/analyze/it-domain/environmental/13/saas",
        co2e: envScore?.saas?.CO2_emissions?.value || 0,
      },
      {
        name: t(
          `collect:pillars.environmental.objectives.13.themes.office365.title`
        ),
        selected: themeId === "office365",
        hidden: !(
          isFeatureActive(OFFICE_365_FEATURE) && envScore?.o365_emissions
        ),
        url: "/analyze/it-domain/environmental/13/office365",
        co2e: envScore?.o365_emissions?.total_emissions?.number || 0,
      },
      {
        name: t(
          `collect:pillars.environmental.objectives.13.themes.websites.title`
        ),
        selected: themeId === "websites",
        hidden:
          !envScore?.website_analyse?.number_of_websites ||
          envScore?.website_analyse?.number_of_websites === 0,
        url: "/analyze/it-domain/environmental/13/websites",
        co2e: envScore?.odd13_software_CO2_websites || 0,
      },
    ].sort((a, b) => b.co2e - a.co2e)

    return tabsConfig
  }, [t, chartsWithDataCount, envScore, hideTabsByNodeType, themeId])

  const allTabsHidden = useMemo(() => {
    if (isCorporate) {
      return false
    }
    return tabs.every((tab) => tab.hidden)
  }, [tabs, isCorporate])

  const redirectIfNoAvailableTab = (
    isEnvScoreLoading,
    isCurrentNodeLoading
  ) => {
    const currentTabAvailable = !!tabs.find(
      (tab) => tab.selected && !tab.hidden
    )
    if (
      !currentTabAvailable &&
      !(isEnvScoreLoading || isCurrentNodeLoading || !envScore)
    ) {
      const availableTab = tabs.find((tab) => !tab.hidden)
      if (availableTab) {
        history.push(availableTab.url)
      }
    }
  }

  return {
    tabs,
    allTabsHidden,
    redirectIfNoAvailableTab,
  }
}
