import { useCallback, useEffect, useState } from "react"
import { useEntity } from "./useAxios"
import useRequest from "hooks/useRequest"
import { useDebounce } from "hooks/useDebounce"
import useSearchTemplate from "hooks/useSearchTemplate"
import { useAppContext } from "components/AppContext"
import UserStateEntity from "services/entities/UserStateEntity"
import useRootNode from "hooks/useRootNode"
import useSearchKeyboardNavigation from "hooks/useSearchKeyboardNavigation"
import _ from "lodash"
import NodeOrganization from "services/organizations/NodeOrganization"

const DEBOUNCE_DELAY = 300
const DASHBOARD_REDIRECTION = "/dashboard"
const ORGANIZE_REDIRECTION = "/organize"
const SEARCH_STRUCTURE = { nodes: [], roots: [], organizations: [] }

const scrollToElement = (element) => {
  if (element) {
    element.scrollIntoView({ behavior: "smooth", block: "nearest" })
  }
}

const focusElementByIndex = (searchListId, index) => {
  setTimeout(() => {
    const element = document.querySelectorAll(
      `[role="${searchListId}"] button`
    )[index]

    if (element) {
      element.focus()
      scrollToElement(element)
    }
  }, 50)
}

const useSearch = ({ onNodeAction, searchRequester, searchListId, params }) => {
  const {
    reloadCurrentRoot,
    currentOrganization,
    currentUser,
    currentRootNode,
  } = useAppContext()
  const [isSearchActive, setIsSearchActive] = useState(false)
  const [searched, setSearched] = useState(SEARCH_STRUCTURE)
  const [searchValue, setSearchValue] = useState("")
  const { debouncedValue } = useDebounce(searchValue, DEBOUNCE_DELAY)
  const [selectedNode, setSelectedNode] = useState(null)
  const [nodes, setNodes] = useState([])

  const { data: searchResponse, isError } = useRequest(
    () => searchRequester({ value: searchValue, ...params }),
    [debouncedValue, isSearchActive],
    { condition: isSearchActive && searchValue.length >= 3 }
  )

  const { index, resetIndex } = useSearchKeyboardNavigation(
    isSearchActive,
    searchResponse?.count || 0,
    () => {
      if (selectedNode) {
        selectedNode.onAction
      }
    },
    () => handleSearchResultsDismiss()
  )

  const userStateEntity = useEntity(UserStateEntity)

  const onNodeFocusAction = (node) =>
    handleNodeChange(async () => await onNodeAction(node))

  const onRootAction = (node) =>
    handleNodeChange(async () => {
      const rootResponse = await userStateEntity.setCurrentRoot(node.id)

      await changeRoot(rootResponse, DASHBOARD_REDIRECTION)
    })

  const onOrganizationAction = (organization) =>
    handleNodeChange(async () => {
      await userStateEntity.setOrganization(organization.id)
      reloadCurrentRoot()
      history.push(ORGANIZE_REDIRECTION)
    })

  const onParentOrganizationAction = () =>
    handleNodeChange(async () => {
      await userStateEntity.resetOrganization()
      reloadCurrentRoot()
      history.push("/dashboard")
    })

  const {
    nodeTemplateBuilder,
    rootTemplateBuilder,
    organizationTemplateBuilder,
    parentOrganizationTemplateBuilder,
  } = useSearchTemplate(
    onNodeFocusAction,
    onRootAction,
    onOrganizationAction,
    onParentOrganizationAction
  )
  const { changeRoot } = useRootNode()
  const searchConfigByCategory = [
    {
      key: "nodes",
      template: nodeTemplateBuilder,
      sortBy: NodeOrganization.getNodeName,
    },
    { key: "roots", template: rootTemplateBuilder, sortBy: "name" },
    {
      key: "organizations",
      template: organizationTemplateBuilder,
      sortBy: "name",
    },
  ]

  const onFocus = async () => setIsSearchActive(true)

  const handleSearchChange = useCallback((value) => {
    setSearchValue(value)
    setIsSearchActive(true)
    resetIndex()
  }, [])

  useEffect(() => {
    const dupStructure = {}

    searchConfigByCategory.forEach(({ key, template, sortBy }) => {
      dupStructure[key] = _.chain(searchResponse?.[key] || [])
        .sortBy(sortBy)
        .map(template)
        .value()
    })

    setSearched(dupStructure)
  }, [searchResponse])

  const handleNodeChange = useCallback(async (query) => {
    await query()

    setSearchValue("")
    setIsSearchActive(false)
    setSearched(SEARCH_STRUCTURE)
  }, [])

  const handleSearchResultsDismiss = useCallback(() => {
    setIsSearchActive(false)
    setSearchValue("")
    resetIndex()
    setSelectedNode(null)
  }, [])

  useEffect(() => {
    const updatedNodes = _.flatten([
      searched.roots,
      searched.nodes,
      searched.organizations,
    ])

    if (currentOrganization?.id !== currentUser?.organization_id) {
      updatedNodes.unshift(parentOrganizationTemplateBuilder(currentRootNode))
    }

    setNodes(updatedNodes)
    focusElementByIndex(searchListId, index)
    setSelectedNode(updatedNodes[index])
  }, [searched, index])

  return {
    isError,
    onFocus,
    searchValue,
    searchListId,
    isSearchActive,
    handleSearchChange,
    handleSearchResultsDismiss,
    nodes,
  }
}

export default useSearch
