import {
  TextChart,
  TextChartMajor,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import Unit from "views/atoms/reports/Unit"
import ChartBadge from "views/atoms/badges/ChartBadge"
import CloudProviderChart from "./CloudProviderChart"
import ChartSDG13WaterConsumptionMolecule from "views/molecules/canvases/charts/websites/ChartSDG13WaterConsumptionMolecule"
import ScoreLayoutOrganism from "views/organisms/scores/ScoreLayoutOrganism"
import DonutChartTemplate from "views/molecules/charts/template/DonutChartTemplate"

const YEARLY_WATER_CONSUMPTION_PER_FTE_MIN = 4
const YEARLY_WATER_CONSUMPTION_PER_FTE_MAX = 7

const Objective6Score = ({ score, websitesScore, collect }) => {
  const { t } = useTranslation()

  const cloudProviders = score.cloud_providers

  const translationPrefix = "score:score.6."

  const chart1 = useCallback(() => {
    let water_consumption = Math.round(score["odd6_water_consumption"])
    const grey_water = Math.round(
      (score["odd6_water_consumption_fab"] / score["odd6_water_consumption"]) *
        100
    )

    let unit = translationPrefix + "m3_unit"
    if (water_consumption > 500) {
      water_consumption = (water_consumption / 1000).toFixed(1)
      unit = translationPrefix + "dam3_unit"
    }

    const content = {
      kpi: water_consumption,
      kpi_unit: <Unit unit={t(unit)} />,
      kpi_text: t(translationPrefix + "chart1.text"),
      reference: grey_water,
      reference_unit: t(translationPrefix + "chart1.reference_unit"),
      reference_text: t(translationPrefix + "chart1.subtext"),
      translationPrefix_chartX: translationPrefix + "chart1.",
    }

    const content_for_display = <TextChartMajor content={content} />

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart1.help")}
      >
        <TextChart content={content_for_display} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  const chart2 = useCallback(() => {
    const waterConsumptionReuseRatio = collect["water_consumption_reuse_ratio"]

    const badge = (
      <ChartBadge
        value={waterConsumptionReuseRatio}
        lowerLimit={40}
        upperLimit={70}
      />
    )

    const content = {
      reference: badge,
      kpi: waterConsumptionReuseRatio,
      kpi_unit: t(translationPrefix + "chart2.unit"),
      kpi_text: t(translationPrefix + "chart2.text"),
    }

    const content_for_display = <TextChartMinor content={content} />

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart2.help")}
      >
        <TextChart content={content_for_display} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  // TODO: find water figures from clouders
  // TODO: fix references to config - for now points to ODD 7
  const chart3 = useCallback(
    () => <CloudProviderChart clouds={cloudProviders} />,
    [cloudProviders]
  )

  const chart4 = useCallback(() => {
    const chart4Score = score?.odd6_equipment_scores_top || {}
    const data = chart4Score.doughnut_charts?.map((dataset) => dataset.data)
    const label = chart4Score.doughnut_charts?.map((dataset) => dataset.label)

    return (
      <DonutChartTemplate
        title={t(translationPrefix + "chart4.title")}
        data={data}
        labelPath={"common:equipment_types"}
        labelKeys={label}
        centerValue={chart4Score.doughnut_charts_data_total?.number}
        unitPath={"units:units.volume"}
        unitKey={chart4Score.doughnut_charts_data_total?.unit}
        helpText={t(translationPrefix + "chart4.help")}
      />
    )
  }, [score])

  const chart5 = useCallback(() => {
    const water_consumption_from_paper_use = Number(
      score["odd6_water_consumption_from_paper_use"]
    ).toFixed(0)
    const employees = score.workforce
    let waterConsumptionFromPaperUsePerEmployees =
      employees > 0
        ? (water_consumption_from_paper_use / employees).toFixed(2)
        : water_consumption_from_paper_use
    let unit = "m3_unit"
    if (waterConsumptionFromPaperUsePerEmployees < 1) {
      waterConsumptionFromPaperUsePerEmployees *= 1000
      unit = "dm3_unit"
    }

    const lowerLimit =
      YEARLY_WATER_CONSUMPTION_PER_FTE_MIN * (score.period_months / 12)
    const upperLimit =
      YEARLY_WATER_CONSUMPTION_PER_FTE_MAX * (score.period_months / 12)

    const badge = (
      <ChartBadge
        value={Number(water_consumption_from_paper_use)}
        lowerLimit={lowerLimit}
        upperLimit={upperLimit}
        inverse
      />
    )

    const content = {
      reference: badge,
      kpi: water_consumption_from_paper_use,
      kpi_unit: <Unit unit={t(translationPrefix + "m3_unit")} />,
      kpi_text: t(translationPrefix + "chart5.text"),
      sub_kpi: waterConsumptionFromPaperUsePerEmployees,
      sub_kpi_unit: (
        <Unit
          unit={`${t(translationPrefix + unit)} / ${t(
            translationPrefix + "employees"
          )}`}
        />
      ),
    }

    const content_for_display = <TextChartMinor content={content} />

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart5.help")}
      >
        <TextChart content={content_for_display} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  return (
    <ScoreLayoutOrganism
      maturity={score["odd6_maturity"] ?? 0}
      chart1={chart1()}
      chart2={chart2()}
      chart3={chart3()}
      chart4={chart4()}
      chart5={chart5()}
      chart6={
        <ChartSDG13WaterConsumptionMolecule
          mixedChart={websitesScore?.water_consumption}
        />
      }
    />
  )
}

Objective6Score.propTypes = {
  score: PropTypes.object.isRequired,
  collect: PropTypes.object.isRequired,
  websitesScore: PropTypes.object,
}

export default Objective6Score
