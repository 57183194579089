import disabledUnderstandITDomain from "services/navigation/disabledUnderstandITDomain"

const getITDomainUrl = (
  currentNode,
  disabledFeatures,
  defaultUrl,
  allTabsHidden
) => {
  if (
    disabledUnderstandITDomain(currentNode, disabledFeatures, allTabsHidden)
  ) {
    return "/analyze/ghg-scopes"
  }

  return currentNode?.has_websites_only ? "/analyze/websites" : defaultUrl
}

export default getITDomainUrl
