import React from "react"
import { useTranslation } from "react-i18next"
import { Bar } from "react-chartjs-2"
import useTranslatedLabels from "services/charts/translatedLabels"
import { getFormattedDatasets } from "services/charts/datasets"
import { stackedAxes } from "services/charts/axes"
import { DEFAULT_OPTIONS } from "services/charts/default"
import { BOTTOM_LEGEND, HIDDEN_LEGEND } from "services/charts/legend"
import { COLORS } from "utils/constants/colors"
import { ChartContainer } from "views/organisms/organization/tables/ChartContainer"
import PropTypes from "prop-types"
import { convertValuesToReadable } from "services/units/valueConversion"
import { DETERMINE_UNITS } from "services/units/units"

const StackedBarChartTemplate = ({
  title,
  datasets,
  compareBarColors,
  fabAndUseCompareBarColors,
  labelPath,
  labelKeys,
  unitPath,
  unitKey,
  ifUnitsInDatasets,
  helpText,
  hiddenLegend,
  max,
  showLabelAndUnitOnBar,
  showLabelOnBar,
  legendLabels,
  useBackgroundColorsInDatasets,
  hideUnitOnBar,
  showValueAndUnitOn2lines,
}) => {
  const { t } = useTranslation()
  const formattedDatasets = getFormattedDatasets({
    datasets,
    compareBarColors,
    fabAndUseCompareBarColors,
    useBackgroundColorsInDatasets,
    labelKeys,
    doNotConvert: true,
  })

  if (DETERMINE_UNITS(unitKey)) {
    const convertedValue = convertValuesToReadable(
      formattedDatasets
        .map((dataset) => dataset.data)
        .filter((value) => +value !== 0),
      unitKey,
      DETERMINE_UNITS(unitKey)
    )

    formattedDatasets.forEach((dataset, index) => {
      dataset.data = convertedValue.values[index]
    })

    unitKey = convertedValue.unit
  }

  const translatedLabels = useTranslatedLabels(labelPath, labelKeys)

  let getUnitPath = unitPath === "" ? "" : t(`${unitPath}.${unitKey}`)
  const data = {
    labels: translatedLabels,
    datasets: formattedDatasets,
  }
  const unitKeyInDatasets = data.datasets.map((dataset) => dataset.unit)

  const formatLabel = (
    values,
    context,
    showLabelOnBar,
    showLabelAndUnitOnBar,
    hideUnitOnBar,
    getUnitPath
  ) => {
    if (showLabelOnBar) {
      return context.dataset.label
    } else if (showLabelAndUnitOnBar) {
      return `${context.dataset.label} \n${
        context.dataset.values[context.dataIndex]
      } ${getUnitPath}`
    } else if (hideUnitOnBar) {
      return (
        context.dataset.label + ": " + context.dataset.values[context.dataIndex]
      )
    } else {
      return `${context.dataset.values[context.dataIndex]}${
        showValueAndUnitOn2lines ? "\n" : " "
      }${getUnitPath}`
    }
  }

  const options = {
    ...DEFAULT_OPTIONS,
    legend: legendLabels
      ? {
          display: true,
          position: "bottom",
          labels: {
            generateLabels: legendLabels,
          },
        }
      : BOTTOM_LEGEND,
    scales: stackedAxes("%", max),
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          const { label } = data.datasets[tooltipItem.datasetIndex]
          return showLabelOnBar
            ? `${label}: ${
                data.datasets[tooltipItem.datasetIndex].values[
                  tooltipItem.index
                ]
              } ${t(
                `${unitPath}.${
                  ifUnitsInDatasets
                    ? unitKeyInDatasets[tooltipItem.datasetIndex]
                    : unitKey
                }`
              )}`
            : `${label}: ${tooltipItem.yLabel} %`
        },
      },
    },

    plugins: {
      datalabels: {
        display: function (context) {
          const total = data.datasets.reduce((acc, dataset) => {
            return acc + dataset.data[context.dataIndex]
          }, 0)
          return (context.dataset.data[context.dataIndex] / total) * 100 > 5
        },

        formatter: function (values, context) {
          const getUnit =
            context.dataset.unit?.[context.dataIndex] === null
              ? ""
              : t(`${unitPath}.${context.dataset.unit?.[context.dataIndex]}`)

          return showLabelOnBar
            ? context.dataset.label
            : formatLabel(
                values,
                context,
                showLabelOnBar,
                showLabelAndUnitOnBar,
                hideUnitOnBar,
                ifUnitsInDatasets ? getUnit : getUnitPath
              )
        },
        textAlign: "center",
        color: COLORS.white,
        font: {
          weight: "bold",
        },
      },
    },
  }

  if (hiddenLegend) {
    options.legend = HIDDEN_LEGEND
  }

  return (
    <ChartContainer title={title} helpText={helpText}>
      <Bar data={data} options={options} />
    </ChartContainer>
  )
}

export default StackedBarChartTemplate

StackedBarChartTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  datasets: PropTypes.array.isRequired,
  compareBarColors: PropTypes.bool,
  fabAndUseCompareBarColors: PropTypes.bool,
  labelPath: PropTypes.string,
  labelKeys: PropTypes.array.isRequired,
  unitPath: PropTypes.string,
  unitKey: PropTypes.string,
  ifUnitsInDatasets: PropTypes.bool,
  helpText: PropTypes.string.isRequired,
  hiddenLegend: PropTypes.bool,
  max: PropTypes.number,
  showLabelOnBar: PropTypes.bool,
  legendLabels: PropTypes.func.isRequired,
  useBackgroundColorInDatasets: PropTypes.bool,
  showLabelAndValueOnBar: PropTypes.bool,
  hideUnitOnBar: PropTypes.bool,
  showLabelAndUnitOnBar: PropTypes.bool,
  useBackgroundColorsInDatasets: PropTypes.bool,
  showValueAndUnitOn2lines: PropTypes.bool,
}

StackedBarChartTemplate.defaultProps = {
  max: 100,
  unitPath: "",
  unitKey: "",
  showLabelOnBar: false,
  showLabelAndUnitOnBar: false,
  compareBarColors: false,
  fabAndUseCompareBarColors: false,
  hiddenLegend: false,
  showLabelAndValueOnBar: false,
  hideUnitOnBar: false,
  legendLabels: [],
  ifUnitsInDatasets: false,
  useBackgroundColorsInDatasets: false,
  showValueAndUnitOn2lines: false,
}
