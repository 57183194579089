import React from "react"
import { Redirect, Switch } from "react-router-dom"
import CollectPage from "components/pages/CollectPage"
import ScorePage from "components/pages/ScorePage"
import {
  FAKE_SIMULATION_FEATURE,
  GHG_RECOMMENDATION_FEATURE,
  OVERVIEW_FEATURE,
  useFeatureFlag,
} from "hooks/useFeatureFlag"
import OrganizationPage from "views/pages/OrganizationPage"
import Contact from "components/pages/Contact"
import Privacy from "components/pages/Privacy"
import LoginPage from "views/pages/LoginPage"
import AnalyzeHistorizationPage from "views/pages/AnalyzeHistorizationPage"
import ImproveMaturityPage from "components/pages/ImproveMaturityPage"
import WebsiteEnvScorePage from "components/widgets/ScorePage/widgets/WebsiteEnvScorePage"
import AnalyzeITDomainPage from "views/pages/AnalyzeITDomainPage"
import AnalyzeGHGPage from "views/pages/AnalyzeGHGPage"
import UserProfile from "components/pages/UserProfile"
import SharePage from "views/pages/SharePage"
import HelpPage from "components/pages/HelpPage"
import SubscriptionPage from "views/pages/subscriptionPage"
import UserCreationPage from "views/pages/UserCreationPage"
import UserUpdatePage from "views/pages/UserUpdatePage"
import useAuth0 from "../components/auth/useAuth0"
import ImproveGHGPage from "components/pages/ImproveGHGPage"
import ReplayPage from "views/pages/ReplayPage"
import useAdminRules from "hooks/useAdminRules"
import UserListPage from "views/pages/UserListPage"
import HomePage from "views/pages/HomePage"
import ProtectedRoute from "routes/ProtectedRoute"
import { useAppContext } from "components/AppContext"
import { SentryRoute } from "services/apps/sentry"
import ComparePage from "views/pages/ComparePage"
import AnalyzeWebsitesPage from "views/pages/AnalyzeWebsitesPage"
import ImproveWebsitesPage from "components/pages/ImproveWebsitesPage"
import DataQualityPage from "components/pages/DataQualityPage"
import WebsiteComparePage from "views/pages/WebsiteComparePage"
import FakeSimulatePage from "views/pages/FakeSimulatePage"

const Routes = () => {
  const { ifFeatureActive, isFeatureActive } = useFeatureFlag()
  const { isLoading, isAuthenticated } = useAuth0()
  const { isUserAdmin } = useAdminRules()
  const {
    disabledFeatures,
    isCurrentUserLoading,
    isEnvCollectLoading,
    isEnvScoreLoading,
  } = useAppContext()

  const defaultRedirection = isAuthenticated ? (
    <Redirect exact from="/" to={disabledFeatures.redirectUrl} />
  ) : (
    <Redirect to="/" />
  )

  const cannotManageUsers = !isCurrentUserLoading && !isUserAdmin

  return (
    <Switch>
      <SentryRoute
        exact
        path="/admin"
        component={() => {
          window.location = "/admin/login"
        }}
      />
      <SentryRoute path="/contact" render={() => <Contact />} exact />
      <SentryRoute path="/privacy" render={() => <Privacy />} exact />
      {!isAuthenticated && (
        <SentryRoute path="/" render={() => <LoginPage />} exact />
      )}
      {!isLoading && defaultRedirection}

      <SentryRoute
        path="/organize"
        render={() => <OrganizationPage organize />}
      />

      <ProtectedRoute
        path="/dashboard"
        render={() => <HomePage />}
        disabled={disabledFeatures.score}
      />
      <ProtectedRoute
        path="/compare/:pillarId?"
        render={() => <ComparePage />}
        disabled={disabledFeatures.compare}
        exact
      />
      <ProtectedRoute
        path="/compare/:pillarId?/websites"
        render={() => <WebsiteComparePage />}
        disabled={disabledFeatures.compare}
        exact
      />
      {ifFeatureActive(OVERVIEW_FEATURE, [
        <ProtectedRoute
          key="/collect/overview/:pillarId?/:objectiveId?/:themeId?"
          path="/collect/overview/:pillarId?/:objectiveId?/:themeId?"
          render={() => <DataQualityPage />}
        />,
      ])}
      <ProtectedRoute
        path="/collect/:pillarId?/:objectiveId?/:themeId?"
        render={() => <CollectPage />}
        disabled={disabledFeatures.collect && !isEnvCollectLoading}
      />
      <ProtectedRoute
        path="/score/:pillarId?/:objectiveId?"
        render={() => <ScorePage />}
        disabled={disabledFeatures.score && !isEnvScoreLoading}
        exact
      />
      <ProtectedRoute
        path="/score/environmental/13/websites"
        render={() => <WebsiteEnvScorePage />}
        disabled={disabledFeatures.envScore}
      />
      {ifFeatureActive(GHG_RECOMMENDATION_FEATURE, [
        <ProtectedRoute
          key="/improve/maturity/:pillarId?/:objectiveId?/:themeId?"
          path="/improve/ghg/:pillar?/:objective?/:theme?"
          render={() => <ImproveGHGPage />}
          disabled={disabledFeatures.improveGHG}
        />,
      ])}
      <ProtectedRoute
        path="/improve/maturity/:pillarId?/:objectiveId?/:themeId?"
        render={() => <ImproveMaturityPage />}
        disabled={disabledFeatures.improveMaturity}
      />
      <ProtectedRoute
        path="/improve/websites/:id?"
        render={() => <ImproveWebsitesPage />}
        disabled={disabledFeatures.improveWebsite}
      />
      <Redirect exact from="/improve" to="/improve/maturity" />
      <ProtectedRoute
        path="/analyze/history/:pillarId?/:objectiveId?"
        exact
        render={() => <AnalyzeHistorizationPage />}
        disabled={disabledFeatures.historization}
      />
      <ProtectedRoute
        path="/analyze/it-domain/:pillarId?/:objectiveId?/:themeId?"
        render={() => <AnalyzeITDomainPage />}
        disabled={disabledFeatures.score}
      />
      <ProtectedRoute
        path="/analyze/ghg-scopes/:pillarId?/:objectiveId?/:protocolId?/:synthesisId?"
        render={() => <AnalyzeGHGPage />}
        disabled={disabledFeatures.envScore}
      />
      <ProtectedRoute
        path="/analyze/websites/:id?"
        render={() => <AnalyzeWebsitesPage />}
        disabled={disabledFeatures.envScore}
      />
      <ProtectedRoute
        path="/share"
        render={() => <SharePage />}
        exact
        disabled={disabledFeatures.score}
      />
      <ProtectedRoute
        path="/share"
        render={() => <SharePage />}
        exact
        disabled={disabledFeatures.replay}
      />
      <ProtectedRoute
        path="/simulate"
        render={() => <FakeSimulatePage />}
        exact
        disabled={
          disabledFeatures.score || !isFeatureActive(FAKE_SIMULATION_FEATURE)
        }
      />

      <SentryRoute path="/profile" render={() => <UserProfile />} />
      <SentryRoute path="/replay" render={() => <ReplayPage />} exact />
      <SentryRoute path="/help" render={() => <HelpPage />} exact />
      <SentryRoute
        path="/subscription"
        render={() => <SubscriptionPage />}
        exact
      />
      <ProtectedRoute
        disabled={cannotManageUsers}
        path="/users"
        render={() => <UserListPage />}
        exact
      />
      <ProtectedRoute
        disabled={cannotManageUsers}
        path="/users/new"
        render={() => <UserCreationPage />}
        exact
      />
      <ProtectedRoute
        disabled={cannotManageUsers}
        path="/users/update/:id"
        render={() => <UserUpdatePage />}
        exact
      />

      {!isLoading && !isAuthenticated && <Redirect to="/" />}
    </Switch>
  )
}

Routes.propTypes = {}

export default Routes
