import React from "react"
import PropTypes from "prop-types"
import { ExternalLabel } from "components/widgets/CollectPage/forms/fields/CollectFields"
import UploadCentralizedOrganism from "views/organisms/upload/UploadCentralizedOrganism"
import { INVENTORY_TYPES } from "utils/constants/centralization/type"

const CentralizedUploadMolecule = ({
  translationPathName,
  inventoryType,
  displayDownloadIcon,
  displayQualityIcon,
  disabled,
}) => {
  return (
    <>
      <ExternalLabel translationPathName={translationPathName} />
      <UploadCentralizedOrganism
        inventoryType={inventoryType}
        displayDownloadIcon={displayDownloadIcon}
        displayQualityIcon={displayQualityIcon}
        disabled={disabled}
      />
    </>
  )
}

CentralizedUploadMolecule.propTypes = {
  translationPathName: PropTypes.string,
  inventoryType: PropTypes.oneOf(INVENTORY_TYPES).isRequired,
  displayDownloadIcon: PropTypes.bool,
  displayQualityIcon: PropTypes.bool,
  disabled: PropTypes.bool,
}

CentralizedUploadMolecule.defaultProps = {
  displayDownloadIcon: false,
  displayQualityIcon: false,
  disabled: false,
}

export default CentralizedUploadMolecule
