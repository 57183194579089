import { useAppContext } from "components/AppContext"
import { useEffect, useState } from "react"

export const IT_TEAM_THEME = "it_team"

const useTheme = () => {
  const [activatedThemes, setActivatedThemes] = useState([])

  const { envCollect } = useAppContext()

  useEffect(() => setActivatedThemes(envCollect?.activated_themes || []), [
    envCollect,
  ])

  const isThemeActive = (themeId) => {
    return activatedThemes.includes(themeId)
  }

  return { isThemeActive }
}

export default useTheme
