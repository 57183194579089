import {
  Button,
  DropZone,
  Form as PForm,
  FormLayout,
  Icon,
  Modal,
  SkeletonBodyText,
  Stack,
  TextStyle,
} from "@shopify/polaris"
import _ from "lodash"
import {
  CirclePlusMajor,
  CirclePlusMinor,
  EditMinor,
  ViewMinor,
} from "@shopify/polaris-icons"
import {
  ExternalHelp,
  ExternalLabel,
  TextField,
} from "components/widgets/CollectPage/forms/fields/CollectFields"
import ConditionalBanner from "components/widgets/ConditionalBanner"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import i18next from "i18next"
import CentralizationInventoryEntity from "services/entities/CentralizationInventoryEntity"
import DropZoneCaptionTemplate from "views/templates/DropZoneCaptionTemplate"
import DropZoneFileNameMolecule from "views/molecules/text/DropZoneFileNameMolecule"
import FileUploadAtom from "views/atoms/forms/FileUploadAtom"
import StatusUploadOrganism from "views/organisms/upload/StatusUploadOrganism"
import UploadOrganism from "views/organisms/upload/UploadOrganism"
import UploadCentralizedOrganism from "views/organisms/upload/UploadCentralizedOrganism"
import { FILE_TYPE_XLSX } from "utils/constants/uploads/fileTypes"
import { TOAST_STATUS_FILE_REJECTED } from "utils/constants/toastStatuses"
import { INVENTORY_DATA_CENTER_EXTERNAL_INVENTORIES } from "utils/constants/centralization/inventoryItems"
import useFieldIsLocked from "../../fields/useFieldIsLocked"
import { useFormContext } from "../../FormContext"
import Select from "../../fields/Select"
import { locations } from "helpers/CollectHelper"
import CountryApp from "services/apps/countryApp"
import { useCollectContext } from "../../../CollectContextProvider"
import { useEntity } from "hooks/useAxios"
import TemplateFileSelectionMolecule from "views/molecules/files/TemplateFileSelectionMolecule"
import {
  DECENTRALIZED_DATA_CENTER_FULL_FILE_PATHS,
  DECENTRALIZED_DATA_CENTER_FULL_FLEX_FILE_PATHS,
} from "utils/constants/templates"
import { FLEX_INVENTORY_FEATURE, useFeatureFlag } from "hooks/useFeatureFlag"
import TemplateFileLinkMolecule from "views/molecules/files/TemplateFileLinkMolecule"
import { useAppContext } from "components/AppContext"

const translationPath = "13.datacenter_external.dc.modal"
const fullTranslationPath = "collect:questions.13.datacenter_external.dc"

// @todo to be cleanup
const DcExternalInventory = () => {
  const { t } = useTranslation()
  const { collect, setToastStatus } = useCollectContext()
  const { isLocked } = useFieldIsLocked()
  const isMounted = useRef(null)
  const [bannerError, setBannerError] = useState(null)
  const [dataCenters, setDataCenters] = useState(null)
  const [file, setFile] = useState()
  const [isModalOpen, setModalOpen] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [inventoryStatus, setInventoryStatus] = useState(null)
  const centralizationInventoryEntity = useEntity(CentralizationInventoryEntity)
  const { ifFeatureActive } = useFeatureFlag()

  const {
    formData,
    setFormData,
    setErrors,
    setTranslationPath,
  } = useFormContext()

  const { reloadEnvCollect } = useAppContext()

  useEffect(() => setTranslationPath(translationPath), [])

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  /**
   * @description Get the uploaded datacenter data
   */
  const getInventoryStatus = async () => {
    try {
      const response = await centralizationInventoryEntity.getDatacenterExternal()
      setDataCenters(response.data)
    } catch (error) {
      setErrors(error?.response?.data)
      if (error.response.data.file) {
        setFormData(error.response.data.data_center)
        let items = []
        error.response.data.file.forEach((message, index) => {
          items.push(<li key={`error-${index}`}>{message}</li>)
        })
      }
    }
  }

  useEffect(() => {
    if (dataCenters) {
      return
    }
    getInventoryStatus().then()
  }, [dataCenters])

  const openModal = () => {
    setModalOpen(true)
    setErrors({})
    setFile(null)
    setBannerError(null)
  }

  const closeModal = async () => {
    setModalOpen(false)
    setFormData({})
    setInventoryStatus(null)

    await getInventoryStatus()
  }

  /**
   * @description Add the file to the DropZone
   */
  const handleDropZoneDrop = useCallback((_dropFiles, acceptedFiles) => {
    if (acceptedFiles.length < 1) {
      setBannerError(t("collect:questions.common.file_error_banner_message"))
      setToastStatus(TOAST_STATUS_FILE_REJECTED)
    } else {
      setBannerError(null)
      setFile(acceptedFiles[0])
    }
  }, [])

  /**
   * @description Update a given datacenter in the dataCenters array with the response
   * @param {AxiosInstance} response The response from the API
   *
   * */
  const updateDataCenters = (response) => {
    let updatedDc = []
    updatedDc = dataCenters.filter(
      (dataCenter) => dataCenter.id !== formData.id
    )
    updatedDc.push(response.data)
    setDataCenters(updatedDc)
  }

  /**
   * @description Submit the form data and the uploaded file
   */
  const handleUploadSubmit = async () => {
    try {
      setIsSending(true)
      const data = new FormData()
      data.set("data_center_external_inventory[name]", formData.name ?? "")
      _.forOwn(formData, (value, key) => {
        return typeof value !== "object"
          ? data.set(`data_center_external_inventory[${key}]`, value)
          : null
      })
      if (file) {
        data.set("file[data]", file)
      }

      if (formData.id) {
        const response = await centralizationInventoryEntity.decentralizedDatacenterExternalUploadUpdate(
          formData.id,
          data
        )
        updateDataCenters(response)
      } else {
        const response = await centralizationInventoryEntity.decentralizedDatacenterExternalUpload(
          data
        )
        setDataCenters([...dataCenters, response.data])
      }
      setErrors({})
      reloadEnvCollect()
      await closeModal()
    } catch (error) {
      setErrors(error?.response?.data)
      if (error.response.data.file) {
        setFormData(error.response.data.data_center)
        let items = []
        error.response.data.file.forEach((message, index) => {
          items.push(<li key={`error-${index}`}>{message}</li>)
        })
        setBannerError(<ul>{items}</ul>)
        setToastStatus(TOAST_STATUS_FILE_REJECTED)
      }
    } finally {
      setIsSending(false)
    }
  }

  /**
   * @description Delete the uploaded file
   */
  const handleDelete = async () => {
    if (!formData.id) {
      return
    }
    try {
      setIsSending(true)
      await centralizationInventoryEntity.decentralizedDatacenterExternalUploadDelete(
        formData.id
      )
      setDataCenters(
        dataCenters.filter((dataCenter) => dataCenter.id !== formData.id)
      )
      setErrors({})
      await closeModal()
    } catch (error) {
      setErrors(error?.response?.data)
      if (error.response.data.file) {
        setFormData(error.response.data.data_center)
        let items = []
        error.response.data.file.forEach((message, index) => {
          items.push(<li key={`error-${index}`}>{message}</li>)
        })
        setBannerError(<ul>{items}</ul>)
        setToastStatus(TOAST_STATUS_FILE_REJECTED)
      }
    } finally {
      setIsSending(false)
    }
    reloadEnvCollect()
  }

  const dropZoneBannerWarning = (
    <ConditionalBanner
      title={t("collect:questions.common.file_error_banner_title_warning")}
      status="warning"
    >
      <>
        {bannerError}
        {bannerError
          ? t("collect:questions.common.file_error_dismissable")
          : ""}
      </>
    </ConditionalBanner>
  )

  const dropZoneBannerCritical = (
    <ConditionalBanner
      title={t("collect:questions.common.file_error_banner_title_critical")}
      status="critical"
    >
      {bannerError}
    </ConditionalBanner>
  )

  const isDropZoneDisabled = useCallback(() => {
    if (isLocked) {
      return true
    }

    switch (inventoryStatus?.file_processing_status) {
      case "status_uploaded":
      case "status_work_in_progress":
        return true

      default:
        return isLocked
    }
  }, [inventoryStatus, isLocked])

  const getDropZoneComponent = () => {
    if (!inventoryStatus && !file) {
      return (
        <DropZone
          accept={FILE_TYPE_XLSX}
          allowMultiple={false}
          onDrop={handleDropZoneDrop}
          disabled={isDropZoneDisabled()}
        >
          <FileUploadAtom loadedItems={formData?.equipment_count} />
        </DropZone>
      )
    } else if (file) {
      return (
        <DropZone
          accept={FILE_TYPE_XLSX}
          allowMultiple={false}
          onDrop={handleDropZoneDrop}
          disabled={isDropZoneDisabled()}
        >
          <DropZoneCaptionTemplate>
            <DropZoneFileNameMolecule file={file} />
          </DropZoneCaptionTemplate>
        </DropZone>
      )
    } else {
      return (
        <StatusUploadOrganism
          accept={FILE_TYPE_XLSX}
          allowMultiple={false}
          onDrop={handleDropZoneDrop}
          disabled={isDropZoneDisabled()}
          inventoryStatus={inventoryStatus}
          setInventoryStatus={setInventoryStatus}
          displayQualityIcon={true}
        />
      )
    }
  }

  const dropZoneMarkup = (
    <Stack vertical alignment="fill" spacing="extraTight">
      <p>
        {t("collect:questions.13.datacenter_external.dc.modal.inventory.label")}
      </p>
      <Stack distribution="center">
        <div className="Verdikt-FileField">
          {ifFeatureActive(
            FLEX_INVENTORY_FEATURE,
            <TemplateFileSelectionMolecule
              fileId="decentralized_inventory_datacenter_full"
              structuredFilePath={
                DECENTRALIZED_DATA_CENTER_FULL_FILE_PATHS[i18next.language]
              }
              flexFilePath={
                DECENTRALIZED_DATA_CENTER_FULL_FLEX_FILE_PATHS[i18next.language]
              }
            />,
            <TemplateFileLinkMolecule id="decentralized_inventory_datacenter_full" />
          )}
        </div>
      </Stack>
      {bannerError && formData.equipment_count > 0
        ? dropZoneBannerWarning
        : dropZoneBannerCritical}

      {getDropZoneComponent()}

      <TextStyle variation="subdued">
        {t("collect:questions.13.datacenter_external.dc.modal.inventory.help")}
      </TextStyle>
    </Stack>
  )

  const modalMarkup = () => {
    const countries = locations({
      additionalKeys: [
        {
          label: t(`${fullTranslationPath}.modal.country_location.empty`),
          value: null,
        },
      ],
    })
    const title = formData.id
      ? t("collect:questions.13.datacenter_external.dc.modal.edit")
      : t("collect:questions.13.datacenter_external.dc.modal.add")
    const primaryAction =
      collect?.closed_at === null
        ? {
            content: t("common:save"),
            onAction: handleUploadSubmit,
            loading: isSending,
            disabled: isLocked,
          }
        : null
    const secondaryAction =
      formData.id && collect?.closed_at === null
        ? {
            content: t("common:delete"),
            onAction: handleDelete,
            destructive: true,
            loading: isSending,
            disabled: isLocked,
          }
        : null
    return (
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        title={title}
        primaryAction={primaryAction}
        secondaryActions={secondaryAction}
      >
        <div className="environmental">
          <Modal.Section>
            <PForm onSubmit={handleUploadSubmit} implicitSubmit={false}>
              <FormLayout>
                <FormLayout.Group condensed>
                  <TextField id="name" type="string" />
                  <TextField id="provider" type="string" />
                </FormLayout.Group>

                <FormLayout.Group condensed>
                  <TextField id="area" type="number" />
                  <TextField id="stored_data_volume" type="number" />
                </FormLayout.Group>

                <FormLayout.Group condensed>
                  <TextField id="pue" type="number" step={0.1} />
                  <TextField id="tiers" type="number" />
                </FormLayout.Group>
                <Select id="country_location" options={countries} />

                <TextStyle variation="subdued">
                  {t("collect:questions.common.nota_bene")}
                </TextStyle>

                {dropZoneMarkup}
              </FormLayout>
            </PForm>
          </Modal.Section>
        </div>
      </Modal>
    )
  }

  const tableMarkup = useCallback(() => {
    if (dataCenters == null) return <SkeletonBodyText />
    const items = []
    if (dataCenters.length == 0) {
      items.push(
        <tr key="0">
          <td colSpan={9}>
            <Stack
              distribution="center"
              alignment="center"
              spacing="extraTight"
            >
              <p>
                {t("collect:questions.13.datacenter_external.dc.table.empty")}
              </p>
              {!isLocked && (
                <Button plain onClick={openModal}>
                  <Icon source={CirclePlusMajor} />
                </Button>
              )}
            </Stack>
          </td>
        </tr>
      )
    } else {
      dataCenters.forEach((dataCenter, index) => {
        items.push(
          <tr key={index}>
            <td>{dataCenter.name}</td>
            <td>{dataCenter.provider}</td>
            <td>{dataCenter.pue ?? "-"}</td>
            <td>
              {dataCenter.stored_data_volume == null
                ? "-"
                : dataCenter.stored_data_volume}
              {t(
                "collect:questions.13.datacenter_external.dc.table.stored_data_volume_suffix"
              )}
            </td>
            <td>
              {t(
                "collect:questions.13.datacenter_external.dc.table.dc_amount",
                {
                  amount:
                    dataCenter?.inventory_equipment_file_status?.item_number ||
                    dataCenter?.equipments ||
                    0,
                }
              )}
            </td>
            <td>
              {dataCenter.country_location
                ? CountryApp.getNameI18n(dataCenter.country_location)
                : t(
                    "collect:questions.13.datacenter_external.dc.table.own_collect"
                  )}
            </td>
            <td>
              <Button
                plain
                onClick={() => {
                  setFormData(dataCenter)
                  setInventoryStatus(dataCenter?.inventory_ratio)
                  openModal()
                }}
              >
                <Icon source={isLocked ? ViewMinor : EditMinor} />
              </Button>
            </td>
          </tr>
        )
      })
    }
    return (
      <div>
        <Stack vertical>
          <table className="Verdikt-DynamicTable">
            <thead>
              <tr className="leftAligned">
                <th>
                  {t("collect:questions.13.datacenter_external.dc.table.name")}
                </th>
                <th>
                  {t(
                    "collect:questions.13.datacenter_external.dc.table.provider"
                  )}
                </th>
                <th>
                  {t("collect:questions.13.datacenter_external.dc.table.pue")}
                </th>
                <th>
                  {t(
                    "collect:questions.13.datacenter_external.dc.table.stored_data_volume"
                  )}
                </th>
                <th>
                  {t("collect:questions.13.datacenter_external.dc.table.file")}
                </th>
                <th>
                  {t(
                    "collect:questions.13.datacenter_external.dc.table.country_location"
                  )}
                </th>
                {!isLocked && (
                  <th>
                    <Button plain onClick={openModal}>
                      <Icon source={CirclePlusMinor} />
                    </Button>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>{items}</tbody>
          </table>
        </Stack>
        <ExternalHelp>
          {t("collect:questions.13.datacenter_external.dc.table.help")}
        </ExternalHelp>
      </div>
    )
  }, [dataCenters])

  return (
    <div>
      <ExternalLabel
        pathOverride="13.datacenter_external"
        translationPathName="dc.table"
      />

      <UploadOrganism
        centralisedComponent={
          <UploadCentralizedOrganism
            inventoryType={INVENTORY_DATA_CENTER_EXTERNAL_INVENTORIES}
          />
        }
        decentralisedComponent={
          <>
            {modalMarkup()}
            {tableMarkup()}
          </>
        }
      />
    </div>
  )
}

export default DcExternalInventory
