import React from "react"
import { Radar } from "react-chartjs-2"
import useTranslatedLabels from "services/charts/translatedLabels"
import { round } from "services/units/round"
import SCORE_CONFIG from "assets/configs/score"
import { DEFAULT_OPTIONS } from "services/charts/default"
import { BOTTOM_LEGEND, HIDDEN_LEGEND } from "services/charts/legend"
import { radarTooltips } from "services/charts/tooltips"
import { ChartContainer } from "views/organisms/organization/tables/ChartContainer"
import PropTypes from "prop-types"

const RadarChartTemplate = ({
  title,
  datasets,
  gapSize,
  labelPath,
  labelKeys,
  chartFill,
  useBackgroundColorsInDatasets,
  useBorderColorInDatasets,
  usePointBackgroundColorInDatasets,
  hiddenLegend,
  helpText,
  pillarId,
}) => {
  const translatedLabels = useTranslatedLabels(labelPath, labelKeys)
  const Color = (index) =>
    SCORE_CONFIG.common.colors[index % SCORE_CONFIG.common.colors.length]

  const data = {
    labels: translatedLabels,
    datasets: datasets.map((dataset, index) => ({
      ...dataset,
      data: dataset.data.map(round),
      fill: chartFill,
      pointBackgroundColor: usePointBackgroundColorInDatasets
        ? dataset.pointBackgroundColor
        : Color(index),
      backgroundColor: useBackgroundColorsInDatasets
        ? dataset.backgroundColor
        : `${Color(index)}80`,
      borderColor: useBorderColorInDatasets
        ? dataset.borderColor
        : Color(index),
    })),
  }

  const options = {
    ...DEFAULT_OPTIONS,
    legend: BOTTOM_LEGEND,
    tooltips: radarTooltips,
    scale: {
      ticks: {
        beginAtZero: true,
        stepSize: gapSize,
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
  }

  if (hiddenLegend) {
    options.legend = HIDDEN_LEGEND
  }

  if (gapSize) {
    options.scale.ticks.stepSize = gapSize
  }

  return (
    <ChartContainer title={title} helpText={helpText} pillar={pillarId}>
      <Radar data={data} options={options} />
    </ChartContainer>
  )
}

export default RadarChartTemplate

RadarChartTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  datasets: PropTypes.array.isRequired,
  gapSize: PropTypes.number,
  labelPath: PropTypes.string,
  labelKeys: PropTypes.array.isRequired,
  chartFill: PropTypes.bool,
  useBackgroundColorsInDatasets: PropTypes.bool,
  useBorderColorInDatasets: PropTypes.bool,
  usePointBackgroundColorInDatasets: PropTypes.bool,
  helpText: PropTypes.string.isRequired,
  hiddenLegend: PropTypes.bool,
  pillarId: PropTypes.string,
}

RadarChartTemplate.defaultProps = {
  gapSize: 5,
  chartFill: false,
  useBackgroundColorsInDatasets: false,
  useBorderColorInDatasets: false,
  usePointBackgroundColorInDatasets: false,
  hiddenLegend: false,
}
