import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import Config from "assets/configs/score.js"
import CumulativeBarChartTemplate from "views/molecules/charts/template/CumulativeBarChartTemplate"

export const findColorByValue = (value, total) => {
  const ratio = value / total
  if (ratio < 0.1) return Config.common.colorsEvaluation.green
  if (ratio < 0.2) return Config.common.colorsEvaluation.yellow

  return Config.common.colorsEvaluation.red
}

const ChartODD13GHGEmissionsDistributionMolecule = ({ score }) => {
  const { t } = useTranslation()
  const { datasets, label_keys, unit } = score.odd13_emissions_breakdown

  const dataset = datasets.map((dataset) => ({
    label: dataset.data_label_keys.map((data_label_key) =>
      t(`score:score.13.chartGHGEmissionsDistribution.${data_label_key}`)
    ),
    data: dataset.data,
    backgroundColor: dataset.data_before_conversion.map((value) =>
      findColorByValue(value, score.odd13_CO2)
    ),
  }))

  return (
    <CumulativeBarChartTemplate
      title={t("score:score.13.chartGHGEmissionsDistribution.title")}
      datasets={dataset}
      labelPath={"score:score.13.chartGHGEmissionsDistribution"}
      labelKeys={label_keys}
      unitPath={"units:units.emission"}
      unitKey={unit}
      hiddenLegend
      useBackgroundColorsInDatasets
      labelInDatasetsIsArray
      showLabelOnBar
      helpText={t(`score:score.13.chartGHGEmissionsDistribution.help`)}
    />
  )
}

ChartODD13GHGEmissionsDistributionMolecule.propTypes = {
  score: PropTypes.object,
}

export default ChartODD13GHGEmissionsDistributionMolecule
