import i18next from "i18next"
import React from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import { Link, useParams } from "react-router-dom"
import { useAppContext } from "components/AppContext"
import classNames from "classnames"

const TemplateFileLinkMolecule = ({ id }) => {
  const { themeId, objectiveId } = useParams()
  const { t } = useTranslation()
  const { collect } = useAppContext()
  const { currentRootNode } = useAppContext()

  // All positive impact themes have the same themeId
  const customTheme =
    themeId === "positive_impact" ? `odd${objectiveId}_${themeId}` : themeId

  const isActivated =
    currentRootNode.is_corporate ||
    collect?.activated_themes?.includes(customTheme)

  return (
    <Link
      to={`/templates/collects/decentralized/${i18next.language}/${id}.${i18next.language}.xlsx`}
      target="_blank"
      download
      className={classNames({ activated: isActivated })}
    >
      {t("collect:questions.common.drop_zone.template")}
    </Link>
  )
}

TemplateFileLinkMolecule.propTypes = {
  id: PropTypes.string.isRequired,
}

export default TemplateFileLinkMolecule
