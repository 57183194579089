import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import DonutChartTemplate from "views/molecules/charts/template/DonutChartTemplate"
import { round } from "services/units/round"

const ChartODD13RegionHostingBreakdownMolecule = ({ score }) => {
  const { t } = useTranslation()
  return (
    <DonutChartTemplate
      title={t(`score:score.13.chartRegionHostingBreakdown.title`)}
      data={score?.odd13_datacenter_co2_top?.data}
      labelKeys={score?.odd13_datacenter_co2_top?.labels}
      centerValue={round(
        score?.odd13_datacenter_co2_top?.doughnut_charts_data_total
      )}
      kpiConfig={45}
      unitPath={"units:units.emission"}
      unitKey={score?.odd13_datacenter_co2_top?.unit_key}
      helpText={t(
        `analyze:it_domain.consolidated.transversal.chartRegionHostingBreakdown.help`
      )}
    />
  )
}

ChartODD13RegionHostingBreakdownMolecule.propTypes = {
  score: PropTypes.object.isRequired
}

export default ChartODD13RegionHostingBreakdownMolecule
