import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import ModalInventoriesActivator from "views/molecules/collects/ModalInventoriesActivator"
import { Banner, Modal } from "@shopify/polaris"
import PropTypes from "prop-types"

const ModalInventoryChoiceTemplate = ({
  title,
  showUpdateWarning,
  children,
}) => {
  const [open, setOpen] = useState(false)
  const closeModal = useCallback(() => setOpen(false), [])
  const handleChange = useCallback(() => setOpen(!open), [open])

  const { t } = useTranslation()

  const activator = <ModalInventoriesActivator onClick={handleChange} />

  const text = t("collect:questions.13.datacenter_external.cloud", {
    returnObjects: true,
  })

  return (
    <Modal
      activator={activator}
      open={open}
      onClose={closeModal}
      title={title}
      primaryAction={{
        content: text.submit,
        onAction: closeModal,
      }}
    >
      {showUpdateWarning && (
        <Banner title={text.banners.collected_data.title} status="warning">
          {text.banners.collected_data.description}
        </Banner>
      )}

      {children}
    </Modal>
  )
}

ModalInventoryChoiceTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  showUpdateWarning: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

ModalInventoryChoiceTemplate.defaultProps = {
  showUpdateWarning: false,
}

export default ModalInventoryChoiceTemplate
