import React from "react"
import { useTranslation } from "react-i18next"
import { Bar } from "react-chartjs-2"
import { round } from "services/units/round"
import useTranslatedLabels from "services/charts/translatedLabels"
import averagesDataset from "services/charts/averagesDatasets"
import { HIDDEN_LEGEND } from "services/charts/legend"
import { dataLabelsPlugin } from "services/charts/plugins/datalabeles"
import { getAvgBarColor } from "services/charts/ColorBar"
import { DEFAULT_OPTIONS } from "services/charts/default"
import { yAxes } from "services/charts/yAxes"
import { ChartContainer } from "views/organisms/organization/tables/ChartContainer"
import PropTypes from "prop-types"
import { FilterTooLittleValues } from "services/charts/ConvertData"
import { convertValuesToReadable } from "services/units/valueConversion"
import { DETERMINE_UNITS } from "services/units/units"

const AvgBarChartTemplate = ({
  title,
  data,
  dataBarTitle,
  referenceData,
  referenceTitle,
  referenceDataDiplay,
  labelPath,
  labelKeys,
  unitPath,
  unitKey,
  pillar,
  helpText,
}) => {
  const { t } = useTranslation()
  const roundedDataBar = data.map(round)
  const avgBarColor = getAvgBarColor(roundedDataBar, referenceData)
  const defaultReferenceTitle = t("score:score.common.datasets.market_data")
  const convertedValue = convertValuesToReadable(
    data.filter((value) => +value !== 0),
    unitKey,
    DETERMINE_UNITS(unitKey)
  )
  unitKey = convertedValue.unit
  const { filteredData, filteredLabels } = FilterTooLittleValues(
    convertedValue.values,
    labelKeys
  )
  const translatedLabels = useTranslatedLabels(labelPath, filteredLabels)

  const datasets = {
    labels: translatedLabels,
    datasets: [
      {
        label: dataBarTitle || "",
        data: filteredData,
        order: 10,
        backgroundColor: avgBarColor
      }
    ]
  }
  if (referenceDataDiplay === true) {
    datasets.datasets.push(averagesDataset(defaultReferenceTitle, referenceTitle, referenceData))
  }

  const options = {
    ...DEFAULT_OPTIONS,
    legend: HIDDEN_LEGEND,
    ...yAxes(t(`${unitPath}.${unitKey}`)),
    plugins: dataLabelsPlugin(),
  }

  return (
    <ChartContainer helpText={helpText} title={title}>
      <Bar data={datasets} options={options} />
    </ChartContainer>
      )
};

export default AvgBarChartTemplate

AvgBarChartTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  dataBarTitle: PropTypes.string,
  referenceTitle: PropTypes.string,
  referenceData: PropTypes.array.isRequired,
  referenceDataDiplay: PropTypes.bool,
  labelPath: PropTypes.string,
  labelKeys: PropTypes.array.isRequired,
  unitPath: PropTypes.string.isRequired,
  unitKey: PropTypes.string.isRequired,
  helpText: PropTypes.string.isRequired
}

AvgBarChartTemplate.defaultProps = {
  referenceDataDiplay: true
}