import PropTypes from "prop-types"
import React, { useCallback } from "react"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import {
  TextChart,
  TextChartMajor,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import { useTranslation } from "react-i18next"
import Config from "assets/configs/score.js"
import ChartBadge from "views/atoms/badges/ChartBadge"
import { COLORS } from "utils/constants/colors"
import ScoreLayoutOrganism from "views/organisms/scores/ScoreLayoutOrganism"
import StackedBarChartTemplate from "views/molecules/charts/template/StackedBarChartTemplate"
import DonutChartTemplate from "views/molecules/charts/template/DonutChartTemplate"
import RadarChartTemplate from "views/molecules/charts/template/RadarChartTemplate"

const genderColorPerValue = (value, neutralColor) => {
  if (value < 50) {
    return COLORS.neutral.c10
  }
  if (value <= 55) {
    return neutralColor
  }
  if (value < 60) {
    return COLORS.warning.primary
  }
  return COLORS.danger.secondary
}

const Objective16Score = ({ score }) => {
  const { t } = useTranslation()

  const chart1 = useCallback(() => {
    const value = score.odd16_cdr_strategy_maturity
    const badge = <ChartBadge value={value} lowerLimit={10} upperLimit={40} />

    const content = {
      kpi: value,
      kpi_unit: t("score:score.16.chart1.kpi_unit"),
      kpi_text: t("score:score.16.chart1.kpi_text"),
      reference: badge,
    }

    const contentForDisplay = <TextChartMajor content={content} />

    return (
      <HelpWidgetChartInteractiveMolecule text={t("score:score.9.chart2.help")}>
        <TextChart content={contentForDisplay} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  const chart2 = useCallback(() => {
    const value = score.odd16_personal_data_maturity
    const badge = <ChartBadge value={value} lowerLimit={33} upperLimit={66} />

    const content = {
      kpi: value,
      kpi_unit: t("score:score.16.chart2.kpi_unit"),
      kpi_text: t("score:score.16.chart2.kpi_text"),
      reference: badge,
    }

    const contentForDisplay = <TextChartMinor content={content} />

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t("score:score.16.chart2.help")}
      >
        <TextChart content={contentForDisplay} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  const chart3 = useCallback(() => {
    const {
      female_values,
      male_values,
      label_keys,
    } = score.odd16_team_split_gender_chart
    const title = t("score:score.16.chart3.title")

    const labels = label_keys.map((key) =>
      t(`score:score.16.chart3.labels.${key}`)
    )

    const maleColors = male_values.map((value) =>
      genderColorPerValue(value[1], Config.common.colors[4])
    )
    const femaleColors = female_values.map((value) =>
      genderColorPerValue(value[1], Config.common.colors[1])
    )

    const data = [
      {
        label: t("score:score.16.chart3.labels.male"),
        gender: t("score:score.16.chart3.labels.male_letter"),
        data: male_values.map((value) => value[1]),
        values: male_values.map((value) => value[0]),
        stack: 0,
        backgroundColor: maleColors,
      },
      {
        label: t("score:score.16.chart3.labels.female"),
        gender: t("score:score.16.chart3.labels.female_letter"),
        data: female_values.map((value) => value[1]),
        values: female_values.map((value) => value[0]),
        stack: 0,
        backgroundColor: femaleColors,
      },
    ]

    return (
      <StackedBarChartTemplate
        title={title}
        datasets={data}
        labelKeys={labels}
        unitPath={undefined}
        unitKey={undefined}
        hiddenLegend
        hideUnitOnBar
        useBackgroundColorsInDatasets
        helpText={t("score:score.16.chart3.help")}
      />
    )
  }, [score])

  const chart4 = useCallback(() => {
    const odd16_budget_chart = score.odd16_budget_chart
    const data = odd16_budget_chart.map((dataset) => dataset.value)
    const labels = odd16_budget_chart.map((dataset) =>
      t(`score:score.16.chart4.labels.${dataset.label_key}`)
    )
    const totalValue = data.reduce((acc, value) => acc + value, 0)

    return (
      <DonutChartTemplate
        title={t("score:score.16.chart4.title")}
        data={data}
        labelKeys={labels}
        centerValue={totalValue}
        unitPath="units:units.money"
        unitKey="k_euro_unit"
        pillar="financial"
        helpText={t("score:score.16.chart4.help")}
      />
    )
  }, [score])

  const chart5 = useCallback(() => {
    const value = score.odd16_ai_and_personal_data_maturity
    const badge = <ChartBadge value={value} lowerLimit={10} upperLimit={40} />

    const content = {
      kpi: value,
      kpi_unit: t("score:score.16.chart5.kpi_unit"),
      kpi_text: t("score:score.16.chart5.kpi_text"),
      reference: badge,
    }

    const contentForDisplay = <TextChartMinor content={content} />

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t("score:score.16.chart5.help")}
      >
        <TextChart content={contentForDisplay} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  const chart6 = useCallback(() => {
    const odd16_kpi_maturity_chart = score.odd16_kpi_maturity_chart
    const labels = odd16_kpi_maturity_chart.map((item) =>
      t(`score:score.16.chart6.labels.${item.label_key}`)
    )
    let datasets = [
      {
        label: t("score:score.16.chart6.title"),
        data: odd16_kpi_maturity_chart.map((item) => item.value),
        borderColor: COLORS.profit.primary,
        pointBackgroundColor: COLORS.profit.primary,
      },
    ]

    return (
      <RadarChartTemplate
        title={t("score:score.16.chart6.title")}
        datasets={datasets}
        labelKeys={labels}
        helpText={t("score:score.16.chart6.help")}
        chartFill
        useBorderColorInDatasets
        useBackgroundColorsInDatasets
        hiddenLegend
      />
    )
  }, [score])

  return (
    <ScoreLayoutOrganism
      maturity={score?.odd16_maturity ?? 0}
      chart1={chart1()}
      chart2={chart2()}
      chart3={chart3()}
      chart4={chart4()}
      chart5={chart5()}
      chart6={chart6()}
    />
  )
}

Objective16Score.propTypes = {
  score: PropTypes.shape({
    odd16_maturity: PropTypes.number,
    odd16_cdr_strategy_maturity: PropTypes.number.isRequired,
    odd16_personal_data_maturity: PropTypes.number.isRequired,
    odd16_ai_and_personal_data_maturity: PropTypes.number.isRequired,
    odd16_budget_chart: PropTypes.array.isRequired,
    odd16_kpi_maturity_chart: PropTypes.array.isRequired,
    odd16_team_split_gender_chart: PropTypes.shape({
      female_values: PropTypes.array.isRequired,
      male_values: PropTypes.array.isRequired,
      label_keys: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
}

export default Objective16Score
