import React, { useCallback } from "react"
import { Bar } from "react-chartjs-2"
import { round } from "services/units/round"
import { getColorByPillar } from "services/charts/ColorBar"
import { DEFAULT_OPTIONS } from "services/charts/default"
import { BOTTOM_LEGEND } from "services/charts/legend"
import { dataLabelsPlugin } from "services/charts/plugins/datalabeles"
import { useTranslation } from "react-i18next"
import useTranslatedLabels from "services/charts/translatedLabels"
import Config from "assets/configs/score"
import { MixedChartAxes } from "services/charts/axes"
import { ChartContainer } from "views/organisms/organization/tables/ChartContainer"
import PropTypes from "prop-types"
import { LINE_LEGEND_PLUGIN } from "services/charts/plugins/legend"
import { EQUIPMENT_TYPE_VOLUME_DEFAULT_LEGEND_MODIFIERS } from "views/molecules/canvases/charts/equipmentTypeVolumeDefaults"
import { FilterTooLittleValues } from "services/charts/ConvertData"
import { convertValuesToReadable } from "services/units/valueConversion"
import { DETERMINE_UNITS } from "services/units/units"

const MixedChartTemplate = ({
  title,
  leftDataInputs,
  rightDataInputs,
  leftDataBarTitle,
  rightDataBarTitle,
  labelPath,
  labelKeys,
  leftUnitPath,
  rightUnitPath,
  leftUnitKey,
  rightUnitKey,
  modifiedBackgroundColor,
  hideUnitOnBar,
  showUnitInHover,
  pillarId,
  helpText,
  valueAndUnitOn2lines,
}) => {
  const backgroundColor = getColorByPillar(pillarId)
  const { t } = useTranslation()

  const convertedRightValues = convertValuesToReadable(
    rightDataInputs.filter((value) => value !== 0),
    rightUnitKey,
    DETERMINE_UNITS(rightUnitKey)
  )
  rightDataInputs = convertedRightValues.values
  rightUnitKey = convertedRightValues.unit

  const convertedLeftValues = convertValuesToReadable(
    leftDataInputs.filter((value) => value !== 0),
    leftUnitKey,
    DETERMINE_UNITS(leftUnitKey)
  )
  leftDataInputs = convertedLeftValues.values
  leftUnitKey = convertedLeftValues.unit

  const translatedUnit = (unitPath, unitKey) => t(`${unitPath}.${unitKey}`)
  const { filteredData, filteredLabels } = FilterTooLittleValues(
    rightDataInputs,
    labelKeys
  )
  const translatedLabels = useTranslatedLabels(labelPath, filteredLabels)

  const data = {
    labels: translatedLabels,
    datasets: [
      {
        label: rightDataBarTitle || "",
        data: filteredData,
        backgroundColor: modifiedBackgroundColor
          ? modifiedBackgroundColor
          : backgroundColor,
        yAxisID: "y-axis-1",
        order: 2,
        unit: translatedUnit(rightUnitPath, rightUnitKey),
      },
      {
        type: "line",
        label: leftDataBarTitle,
        data: leftDataInputs.map((value) => round(value)),
        order: 1,
        yAxisID: "y-axis-2",
        borderWidth: 2,
        fill: false,
        datalabels: { display: false },
        borderColor: Config.common.colors[1],
        backgroundColor: Config.common.colors[1],
        unit: translatedUnit(leftUnitPath, leftUnitKey),
      },
    ],
  }

  const options = {
    ...DEFAULT_OPTIONS,
    legend: BOTTOM_LEGEND,
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const datasetLabel =
            data.datasets[tooltipItem.datasetIndex].label || ""
          const value =
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
          const unit = showUnitInHover
            ? ` ${translatedUnit(rightUnitPath, rightUnitKey)}`
            : ""
          return showUnitInHover
            ? `${datasetLabel}: ${value}${unit}`
            : `${datasetLabel}: ${value} ${translatedUnit(
                tooltipItem.datasetIndex === 0 ? rightUnitPath : leftUnitPath,
                tooltipItem.datasetIndex === 0 ? rightUnitKey : leftUnitKey
              )}`
        },
      },
    },
    scales: {
      yAxes: [
        {
          ...MixedChartAxes(
            translatedUnit(leftUnitPath, leftUnitKey),
            "y-axis-2",
            "left"
          ),
        },
        {
          ...MixedChartAxes(
            translatedUnit(rightUnitPath, rightUnitKey),
            "y-axis-1",
            "right"
          ),
        },
      ],
    },
    plugins: dataLabelsPlugin(
      hideUnitOnBar ? undefined : t(`${rightUnitPath}.${rightUnitKey}`),
      undefined,
      valueAndUnitOn2lines
    ),
  }

  const uniLegendColor = useCallback(() => {
    if (!modifiedBackgroundColor) {
      return []
    }
    return [
      {
        beforeDraw(c) {
          EQUIPMENT_TYPE_VOLUME_DEFAULT_LEGEND_MODIFIERS.forEach(
            (modifier, index) => modifier(c.legend.legendItems[index])
          )
        },
      },
    ]
  }, [modifiedBackgroundColor])

  return (
    <ChartContainer title={title} helpText={helpText} pillar={pillarId}>
      <Bar
        data={data}
        options={options}
        plugins={[...uniLegendColor(), LINE_LEGEND_PLUGIN]}
      />
    </ChartContainer>
  )
}

export default MixedChartTemplate

MixedChartTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  leftDataInputs: PropTypes.array.isRequired,
  rightDataInputs: PropTypes.array.isRequired,
  leftDataBarTitle: PropTypes.string.isRequired,
  rightDataBarTitle: PropTypes.string.isRequired,
  labelPath: PropTypes.string,
  labelKeys: PropTypes.array.isRequired,
  leftUnitPath: PropTypes.string.isRequired,
  rightUnitPath: PropTypes.string.isRequired,
  leftUnitKey: PropTypes.string.isRequired,
  rightUnitKey: PropTypes.string.isRequired,
  modifiedBackgroundColor: PropTypes.array,
  hideUnitOnBar: PropTypes.bool,
  showUnitInHover: PropTypes.bool,
  pillarId: PropTypes.string,
  helpText: PropTypes.string.isRequired,
}

MixedChartTemplate.defaultProps = {
  pillarId: "environmental",
  modifiedBackgroundColor: undefined,
  hideUnitOnBar: false,
  showUnitInHover: false,
}
