import React from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import CumulativeBarChartTemplate from "views/molecules/charts/template/CumulativeBarChartTemplate"

const ChartCommuteVSTravel = ({ envScore }) => {
  const { t } = useTranslation()
  const commuteVsTravel = envScore?.odd13_commute_vs_travel
  const unitKey = envScore?.odd13_commute_vs_travel.unit_key
  const unit = t(`units:units.emission.${unitKey}`)

  const datasets = commuteVsTravel.datasets.map((dataset) => ({
    ...dataset,
    label: t(`score:score.13.ChartCommuteVSTravel.${dataset.label}`),
    unit,
  }))

  const commuteData = datasets.map((dataset) => +dataset.data[0])
  const commuteTotal = commuteData.reduce((acc, value) => acc + value, 0)

  const travelData = datasets.map((dataset) => +dataset.data[1])
  const travelTotal = travelData.reduce((acc, value) => acc + value, 0)

  if (commuteTotal < travelTotal) {
    datasets.forEach((dataset) => {
      dataset.data.reverse()
    })
    commuteVsTravel.labels.reverse()
  }

  return (
    <CumulativeBarChartTemplate
      datasets={datasets}
      title={t(`score:score.13.ChartCommuteVSTravel.title`)}
      helpText=""
      unitKey={unitKey}
      unitPath="units:units.emission"
      labelKeys={commuteVsTravel.labels}
      labelPath="score:score.13.ChartCommuteVSTravel"
      hiddenLegend
    />
  )
}

ChartCommuteVSTravel.propTypes = {
  envScore: PropTypes.object.isRequired,
}

export default ChartCommuteVSTravel
