import React, { createContext, useContext, useEffect, useState } from "react"
import useCurrentPeriod from "hooks/useCurrentPeriod"
import useObjectiveNavigation from "hooks/useObjectiveNavigation"
import useCurrentNode from "hooks/useCurrentNode"
import useRequest from "hooks/useRequest"
import EnvCollectEntity from "services/entities/EnvCollectEntity"
import SocialCollectEntity from "services/entities/SocialCollectEntity"
import ScoreEntity from "services/entities/ScoreEntity"
import ProfitCollectEntity from "../services/entities/ProfitCollectEntity"
import SubscriptionEntity from "services/entities/SubscriptionEntity"
import PropTypes from "prop-types"
import { useEntity } from "../hooks/useAxios"
import CountryEntity from "../services/entities/CountryEntity"
import UserEntity from "../services/entities/UserEntity"
import useAuth0 from "./auth/useAuth0"
import UserStateEntity from "services/entities/UserStateEntity"
import HistorizationChartEntity from "services/entities/HistorizationChartEntity"
import useDisabledFeatures from "hooks/useDisabledFeatures"
import useRedirection from "hooks/useRedirection"
import { TOAST_STATUS_INACTIVE } from "utils/constants/toastStatuses"
import ProductEntity from "services/entities/ProductEntity"
import useSentry from "hooks/useSentry"
import OrganizationEntity from "services/entities/OrganizationEntity"
import { markIsCurrentNodeBelow } from "components/widgets/Dashboard/OrganizationChart/ChartContext"

export const AppContext = createContext(null)

export const AppContextProvider = ({ children }) => {
  const { isAuthenticated, setIsAuthenticated } = useAuth0()

  const [shouldRefreshNotifications, setShouldRefreshNotifications] = useState(
    true
  )
  const [toastStatus, setToastStatus] = useState(TOAST_STATUS_INACTIVE)

  const envCollectEntity = useEntity(EnvCollectEntity)
  const socialCollectEntity = useEntity(SocialCollectEntity)
  const scoreEntity = useEntity(ScoreEntity)
  const userEntity = useEntity(UserEntity)
  const profitCollectEntity = useEntity(ProfitCollectEntity)
  const subscriptionEntity = useEntity(SubscriptionEntity)
  const countryEntity = useEntity(CountryEntity)
  const userStateEntity = useEntity(UserStateEntity)
  const historizationChartEntity = useEntity(HistorizationChartEntity)
  const productEntity = useEntity(ProductEntity)
  const organizationEntity = useEntity(OrganizationEntity)

  const {
    data: currentUser,
    setData: setCurrentUser,
    errorCode: userErrorCode,
    isLoading: isCurrentUserLoading,
  } = useRequest(() => userEntity.show())

  useEffect(() => userErrorCode === 401 && setIsAuthenticated(false), [
    userErrorCode,
  ])

  const {
    data: currentRootNode,
    setData: setCurrentRootNode,
    reload: reloadCurrentRoot,
    isLoading: isCurrentRootNodeLoading,
  } = useRequest(() => userStateEntity.getCurrentRoot())

  const { data: productDetails } = useRequest(() => productEntity.index(), [
    currentRootNode,
  ])

  const { data: subscription } = useRequest(
    () => subscriptionEntity.current(),
    [currentRootNode],
    { condition: !!currentRootNode }
  )

  const { currentPeriod, reload: reloadCurrentPeriod } = useCurrentPeriod(
    [subscription, currentRootNode],
    !!subscription && !subscription.is_provider
  )

  const {
    data: currentOrganization,
    isLoading: isCurrentOrganizationLoading,
  } = useRequest(() => userStateEntity.getOrganization(), [currentRootNode])

  const {
    pillarNavigation,
    objectiveNavigation,
    updateObjectiveNavigation,
    objectiveUri,
  } = useObjectiveNavigation()

  const {
    currentNode,
    updateCurrentNode,
    isCurrentNodeLoading,
    parentOrganization,
    isParentOrganizationLoading,
    reloadCurrentNode,
  } = useCurrentNode([currentRootNode], !!currentRootNode)

  const {
    data: envCollect,
    setData: setEnvCollect,
    isLoading: isEnvCollectLoading,
    reload: reloadEnvCollect,
  } = useRequest(() => envCollectEntity.show(), [currentNode, currentPeriod], {
    condition: !!currentNode,
  })

  const {
    data: socialCollect,
    setData: setSocialCollect,
    isLoading: isSocialCollectLoading,
    reload: reloadSocialCollect,
  } = useRequest(
    () => socialCollectEntity.show(),
    [currentNode, currentPeriod],
    {
      condition: !!currentNode,
    }
  )

  const profitCollectRequest = useRequest(
    () => profitCollectEntity.show(),
    [currentNode, currentPeriod],
    {
      condition: !!currentNode,
    }
  )
  const profitCollect = profitCollectRequest.data

  const {
    data: score,
    setData: setScore,
    isLoading: isScoreLoading,
    reload: reloadScore,
  } = useRequest(() => scoreEntity.getScore(), [currentNode], {
    condition: !!currentNode,
  })

  const {
    data: envScore,
    isLoading: isEnvScoreLoading,
    isError: isEnvScoreError,
    reload: reloadEnvScore,
  } = useRequest(() => scoreEntity.getEnvironmentalScore(), [currentNode], {
    condition: !!currentNode,
  })

  const {
    data: socialScore,
    isLoading: isSocialScoreLoading,
    isError: isSocialScoreError,
    reload: reloadSocialScore,
  } = useRequest(() => scoreEntity.getSocialScore(), [currentNode], {
    condition: !!currentNode,
  })

  const profitScoreRequest = useRequest(
    () => scoreEntity.getProfitScore(),
    [currentNode],
    {
      condition: !!currentNode,
    }
  )
  const profitScore = profitScoreRequest.data
  const isProfitScoreLoading = profitScoreRequest.isLoading
  const isProfitScoreError = profitScoreRequest.isError

  const { data: availableProducts, reload: reloadProducts } = useRequest(() =>
    userEntity.availableProducts()
  )

  const { data: countryOptions } = useRequest(
    () => countryEntity.getOptions(),
    [],
    { getter: ({ data }) => data.alpha2s }
  )

  const historizationRequest = useRequest(
    () => historizationChartEntity.chart(),
    [currentPeriod, currentNode, score],
    { condition: !!score }
  )

  const {
    data: tree,
    reload: reloadTree,
    isLoading: isTreeLoading,
  } = useRequest(
    () => organizationEntity.getCurrentOrganization(),
    [currentRootNode, currentNode],
    {
      condition: !!currentRootNode,
      getter: ({ data }) => {
        markIsCurrentNodeBelow(data)

        return data
      },
    }
  )

  const disabledFeatures = useDisabledFeatures({
    isAuthenticated,
    isCurrentRootNodeLoading,
    currentNode,
    isCurrentNodeLoading,
    score,
    isScoreLoading,
    envScore,
    isEnvScoreLoading,
    historizationRequest,
    currentOrganization,
    isCurrentOrganizationLoading,
    profitScoreRequest,
    socialScore,
    isSocialScoreLoading,
    currentRootNode,
  })

  const redirect = useRedirection({ disabledFeatures, score, currentNode })

  useSentry({
    currentUser,
    currentOrganization,
    currentRootNode,
    currentNode,
  })

  return (
    <AppContext.Provider
      value={{
        isAuthenticated,
        currentUser,
        setCurrentUser,
        isCurrentUserLoading,
        toastStatus,
        setToastStatus,
        currentRootNode,
        isCurrentRootNodeLoading,
        reloadCurrentRoot,
        currentOrganization,
        currentNode,
        reloadCurrentNode,
        updateCurrentNode,
        isCurrentNodeLoading,
        setCurrentRootNode,
        shouldRefreshNotifications,
        setShouldRefreshNotifications,
        currentPeriod,
        reloadCurrentPeriod,
        objectiveNavigation,
        updateObjectiveNavigation,
        pillarNavigation,
        objectiveUri,
        parentOrganization,
        isParentOrganizationLoading,
        envCollect,
        setEnvCollect,
        isEnvCollectLoading,
        reloadEnvCollect,
        socialCollect,
        setSocialCollect,
        isSocialCollectLoading,
        reloadSocialCollect,
        profitCollect,
        profitCollectRequest,
        score,
        setScore,
        reloadScore,
        isScoreLoading,
        envScore,
        reloadEnvScore,
        isEnvScoreLoading,
        isEnvScoreError,
        socialScore,
        reloadSocialScore,
        isSocialScoreLoading,
        isSocialScoreError,
        availableProducts,
        reloadProducts,
        subscription,
        profitScore,
        profitScoreRequest,
        isProfitScoreLoading,
        countryOptions,
        historizationRequest,
        disabledFeatures,
        redirect,
        productDetails,
        tree,
        reloadTree,
        isTreeLoading,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

AppContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export function useAppContext() {
  return useContext(AppContext)
}
