import React from "react"
import ChartAnalysisCloudByZone from "views/molecules/canvases/charts/cloud/ChartAnalysisCloudByZone"
import ChartAnalysisCloudByRegion from "views/molecules/canvases/charts/cloud/ChartAnalysisCloudByRegion"
import PropTypes from "prop-types"

const ChartCloudRegionsDispatcher = ({ envScore }) => {
  const zone_emissions = envScore?.cloud_score?.zone_emissions
  const clouder_emission_by_region =
    envScore?.cloud_score?.clouder_emission_by_region

  return zone_emissions ? (
    <ChartAnalysisCloudByZone
      datasets={zone_emissions?.datasets || []}
      labels={zone_emissions?.label_keys || []}
      unitKey={zone_emissions?.unit_key || ""}
    />
  ) : (
    <ChartAnalysisCloudByRegion
      datasets={clouder_emission_by_region?.datasets || []}
      labels={clouder_emission_by_region?.label_keys || []}
      unitKey={clouder_emission_by_region?.unit_key || ""}
    />
  )
}

ChartCloudRegionsDispatcher.propTypes = {
  envScore: PropTypes.object.isRequired,
}

export default ChartCloudRegionsDispatcher
