import React from "react"
import { Layout, Page } from "@shopify/polaris"
import BreadcrumbsOrganism from "views/organisms/commons/breadcrumbs/BreadcrumbsOrganism"
import { useAppContext } from "components/AppContext"
import FakeSimulationImage from "assets/images/fake-simulation.png"

const FakeSimulatePage = () => {
  const { currentPeriod, parentOrganization } = useAppContext()

  return (
    <Page fullWidth>
      <Layout>
        <Layout.Section>
          {currentPeriod && (
            <BreadcrumbsOrganism
              parentOrganization={parentOrganization}
              currentPeriodName={currentPeriod?.name}
            />
          )}
        </Layout.Section>

        <Layout.Section>
          <img src={FakeSimulationImage} alt="" width="100%" />
        </Layout.Section>
      </Layout>
    </Page>
  )
}

export default FakeSimulatePage
