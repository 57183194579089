import React, { useCallback, useEffect, useReducer, useState } from "react"
import {
  Banner,
  Button,
  Form as PForm,
  FormLayout,
  Icon,
  Modal,
  Select,
  TextField,
} from "@shopify/polaris"
import { CirclePlusMajor, EditMajor, ViewMajor } from "@shopify/polaris-icons"
import { useTranslation } from "react-i18next"
import { useEntity } from "hooks/useAxios"
import PropTypes from "prop-types"
import useFieldIsLocked from "components/widgets/CollectPage/forms/fields/useFieldIsLocked"
import TechTeamInventoryEntity from "services/entities/TechTeamInventoryEntity"
import { useAppContext } from "components/AppContext"
import { RETURN_OBJECTS } from "services/translations/config"

const formReducer = (state, action) => {
  return { ...state, ...action }
}

const EMPTY_VALUE = ""

const defaultFormData = {
  transport_mode: EMPTY_VALUE,
  avg_daily_commuting_distance: EMPTY_VALUE,
  remote_days: EMPTY_VALUE,
  days_on_site: EMPTY_VALUE,
  amount: EMPTY_VALUE,
}

const ModalITTeam = ({ item, postAction }) => {
  const { isLocked } = useFieldIsLocked()
  const [formData, dispatch] = useReducer(formReducer, item || defaultFormData)
  const [errors, setErrors] = useState(item?.errors || {})
  const [open, setOpen] = useState(false)
  const [deleteConfirm, setDeleteConfirm] = useState(false)
  const [placeholders, setPlaceholders] = useState({})
  const handleChange = useCallback(() => setOpen(!open), [open])
  const { t } = useTranslation()
  const techTeamInventoryEntity = useEntity(TechTeamInventoryEntity)
  const { reloadEnvCollect } = useAppContext()

  const translation = t("collect:questions.13.it_team_modal", RETURN_OBJECTS)
  const titleModal = item
    ? translation.title_update_team
    : translation.title_create_team

  const TRANSPORT_OPTIONS = [
    { value: "", label: "" },
    { value: "car", label: translation.car },
    { value: "electric_car", label: translation.electric_car },
    { value: "motorcycle", label: translation.motorcycle },
    { value: "electric_motorcycle", label: translation.electric_motorcycle },
    { value: "public_transit", label: translation.public_transit },
    { value: "no_vehicle", label: translation.no_vehicle },
  ]
  const onTransportModeChange = async (transportMode) => {
    const transport_mode = {
      car: 1,
      electric_car: 2,
      motorcycle: 3,
      electric_motorcycle: 4,
      public_transit: 5,
      no_vehicle: 6,
    }
    formData.transport_mode = transport_mode[transportMode]
  }

  const handleDelete = async () => {
    try {
      await techTeamInventoryEntity.delete(item.id)
      postAction()
      reloadEnvCollect()
    } catch (err) {
      console.log(err)
    }
  }

  const updateField = (value, id) => {
    if (id === "remote_days") {
      formData.days_on_site = 5 - value
    }
    if (id === "days_on_site") {
      formData.remote_days = 5 - value
    }
    dispatch({ [id]: value })

    if (errors[id]) {
      setErrors({ ...errors, [id]: undefined })
    }
  }

  useEffect(() => {
    placeholders.amount = formData.amount
    if (formData.remote_days) {
      updateField(5 - formData.remote_days, formData.days_on_site)
      placeholders.days_on_site = 5 - formData.remote_days
    }
    if (formData.days_on_site) {
      updateField(5 - formData.days_on_site, formData.remote_days)
      placeholders.remote_days = 5 - formData.days_on_site
    }
  }, [formData.remote_days, formData.days_on_site])

  const showDeleteBanner = () => setDeleteConfirm(true)

  const handleSubmit = async () => {
    onTransportModeChange(formData.transport_mode)
    try {
      formData.id
        ? await techTeamInventoryEntity.update(formData.id, formData)
        : await techTeamInventoryEntity.create(formData)
      setOpen(false)
      setDeleteConfirm(false)
      postAction()
      reloadEnvCollect()
    } catch (err) {
      if (err.isAxiosError) {
        setErrors(err.response.data)
      }
    }
  }

  const handleClose = () => {
    setOpen(false)
    setDeleteConfirm(false)
  }

  const handleCreate = () => {
    dispatch(defaultFormData)
    placeholders.days_on_site = ""
    placeholders.remote_days = ""
    placeholders.amount = ""
    handleChange()
  }
  const getSecondaryAction = () => {
    if (isLocked || !formData.id) {
      return {
        content: t("common:cancel"),
        onAction: handleClose,
      }
    }

    return {
      content: t("common:delete"),
      onAction: showDeleteBanner,
      destructive: true,
    }
  }

  const icon = !isLocked
    ? formData.id
      ? EditMajor
      : CirclePlusMajor
    : ViewMajor

  const activator = (
    <button onClick={!formData.id ? handleCreate : handleChange}>
      <div className="iconCollectType">
        <Icon source={icon} />
      </div>
    </button>
  )

  return (
    <Modal
      activator={isLocked && !formData.id ? null : activator}
      open={open}
      onClose={handleClose}
      title={titleModal}
      primaryAction={
        !isLocked && {
          content: t(
            "collect:questions.13.datacenter_external.dc.modal.submit"
          ),
          onAction: handleSubmit,
        }
      }
      secondaryActions={getSecondaryAction()}
    >
      {deleteConfirm ? (
        <Modal.Section>
          <Banner status="warning" title={translation.title_delete}>
            <p>{translation.description_delete}</p>
            <Button status="danger" onClick={handleDelete}>
              {t("common:delete")}
            </Button>
          </Banner>
        </Modal.Section>
      ) : (
        <Modal.Section>
          <div className="environmental">
            <PForm implicitSubmit={false} onSubmit={handleSubmit}>
              <FormLayout>
                <FormLayout.Group condensed>
                  <Select
                    id="transport_mode"
                    label={translation.transport_mode}
                    options={TRANSPORT_OPTIONS}
                    value={formData.transport_mode}
                    onChange={updateField}
                    error={errors.transport_mode}
                    disabled={isLocked}
                    placeholder={placeholders.transport_mode}
                  />
                  <TextField
                    id="amount"
                    type="number"
                    label={translation.amount}
                    value={formData.amount}
                    onChange={updateField}
                    error={errors.amount}
                    placeholder={placeholders.amount}
                    disabled={isLocked}
                  />
                </FormLayout.Group>
                <FormLayout.Group condensed>
                  <TextField
                    id="remote_days"
                    type="number"
                    label={translation.remote_days}
                    value={formData.remote_days}
                    suffix={translation.remote_days_unit}
                    onChange={updateField}
                    error={errors.remote_days}
                    placeholder={placeholders.remote_days}
                    disabled={isLocked}
                  />
                  <TextField
                    id="days_on_site"
                    type="number"
                    label={translation.days_on_site}
                    value={formData.days_on_site}
                    suffix={translation.remote_days_unit}
                    onChange={updateField}
                    error={errors.days_on_site}
                    placeholder={placeholders.days_on_site}
                    disabled={isLocked}
                  />
                </FormLayout.Group>
                <FormLayout.Group condensed>
                  <TextField
                    id="avg_daily_commuting_distance"
                    type="number"
                    label={translation.avg_daily_commuting_distance}
                    suffix={translation.distance_unit}
                    value={formData.avg_daily_commuting_distance}
                    onChange={updateField}
                    error={errors.avg_daily_commuting_distance}
                    placeholder={placeholders.avg_daily_commuting_distance}
                    disabled={isLocked}
                  />
                </FormLayout.Group>
              </FormLayout>
            </PForm>
          </div>
        </Modal.Section>
      )}
    </Modal>
  )
}

ModalITTeam.propTypes = {
  item: PropTypes.object,
  postAction: PropTypes.func,
}

ModalITTeam.defaultProps = {
  postAction: () => {},
}

export default ModalITTeam
