import React from "react"
import { Page } from "@shopify/polaris"
import Loading from "components/widgets/Loading"
import { useParams } from "react-router-dom"
import AnalyzeGHGTemplate from "views/templates/AnalyzeGHGTemplate"
import { useTranslation } from "react-i18next"
import GHGAnalyzeOrganism from "views/organisms/analyzes/GHGAnalyzeOrganism"
import {
  PILLAR_ENVIRONMENTAL,
  PILLAR_PROFIT,
  PILLAR_SOCIAL,
} from "utils/constants/pillars"
import { useAppContext } from "components/AppContext"
import ObjectiveNavigationTemplate from "views/templates/ObjectiveNavigationTemplate"
import withAuthRequired from "../../components/auth/withAuthRequired"
import usePillars from "views/organisms/pillars/usePillars"
import { FAKE_GHG_PROTOCOL_FEATURE, useFeatureFlag } from "hooks/useFeatureFlag"
import i18n from "i18next"

const AnalyzeGHGPage = () => {
  const { protocolId, synthesisId } = useParams()
  const { t } = useTranslation()
  const { isFeatureActive } = useFeatureFlag()

  const {
    currentPeriod,
    parentOrganization,
    envScore,
    isEnvScoreLoading,
    isCurrentNodeLoading,
  } = useAppContext()

  const tabs = [
    {
      name: t("analyze:tabs.GHGProtocolSynthesis"),
      protocolId: "ghg_protocol",
      synthesisId: "synthesis",
      selected: "ghg_protocol" === protocolId && synthesisId === "synthesis",
      url: "/analyze/ghg-scopes/environmental/13/ghg_protocol/synthesis",
      hidden: true,
    },
    {
      name: t("analyze:tabs.GHGProtocolDetail"),
      protocolId: "ghg_protocol",
      synthesisId: "detail",
      selected: "ghg_protocol" === protocolId && synthesisId === "detail",
      url: "/analyze/ghg-scopes/environmental/13/ghg_protocol/detail",
      hidden: true,
    },
    {
      name:
        isFeatureActive(FAKE_GHG_PROTOCOL_FEATURE) && i18n.language === "en"
          ? t("analyze:tabs.GHGProtocolSynthesis")
          : t("analyze:tabs.BilanCarboneSynthesis"),
      protocolId: "bilan_carbone",
      synthesisId: "synthesis",
      selected: "bilan_carbone" === protocolId && synthesisId === "synthesis",
      url: "/analyze/ghg-scopes/environmental/13/bilan_carbone/synthesis",
    },
    {
      name:
        isFeatureActive(FAKE_GHG_PROTOCOL_FEATURE) && i18n.language === "en"
          ? t("analyze:tabs.GHGProtocolDetail")
          : t("analyze:tabs.BilanCarboneDetail"),
      protocolId: "bilan_carbone",
      synthesisId: "detail",
      selected: "bilan_carbone" === protocolId && synthesisId === "detail",
      url: "/analyze/ghg-scopes/environmental/13/bilan_carbone/detail",
    },
  ]

  const objectives = [
    {
      objectiveId: 13,
      selected: true,
      url: "/analyze/ghg-scopes/environmental/13/ghg_protocol/synthesis",
    },
  ]

  const pillars = usePillars({
    pageName: "/analyze/ghg-scopes",
    [PILLAR_SOCIAL]: { disabled: true },
    [PILLAR_PROFIT]: { disabled: true },
  })

  if (isEnvScoreLoading || isCurrentNodeLoading) {
    return <Loading />
  }

  return (
    <ObjectiveNavigationTemplate>
      <Page fullWidth={!!envScore}>
        <AnalyzeGHGTemplate
          envScore={envScore}
          parentOrganization={parentOrganization}
          tabs={tabs}
          themeType={PILLAR_ENVIRONMENTAL}
          objectives={objectives}
          pillars={pillars}
          periodName={currentPeriod?.name}
        >
          <GHGAnalyzeOrganism
            protocolId={protocolId}
            synthesisId={synthesisId}
            envScore={envScore}
            parentOrganization={parentOrganization}
          />
        </AnalyzeGHGTemplate>
      </Page>
    </ObjectiveNavigationTemplate>
  )
}

export default withAuthRequired(AnalyzeGHGPage)
