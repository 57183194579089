import { Layout } from "@shopify/polaris"
import Columns from "components/widgets/Dashboard/Columns"
import ScoreGauge from "components/widgets/ScoreGauge"
import ScoreValue from "components/widgets/ScoreValue"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import loadingGif from "assets/images/loading-spinner.gif"
import { useTranslation } from "react-i18next"
import "stylesheets/ScorePage/Score.scss"
import { useAppContext } from "components/AppContext"
import { useEntity } from "../../../hooks/useAxios"
import ScoreEntity from "../../../services/entities/ScoreEntity"
import { PILLAR_ENVIRONMENTAL } from "utils/constants/pillars"
import CO2EValue from "components/widgets/CO2EValue"
import { useFeatureFlag } from "hooks/useFeatureFlag"
import { showMaturity } from "services/score/maturity/showMaturity"

const Score = ({ loading, setLoading, quantitative }) => {
  const { t } = useTranslation()
  const {
    reloadEnvScore,
    reloadSocialScore,
    reloadEnvCollect,
    reloadSocialCollect,
    profitCollectRequest,
    profitScoreRequest,
    profitScore,
    envScore,
    score,
    setScore,
    currentRootNode,
    currentOrganization,
    reloadCurrentNode,
    currentNode,
  } = useAppContext()
  const { isFeatureActive } = useFeatureFlag()
  const scoreEntity = useEntity(ScoreEntity)

  /**
   * Set interval to retrieve score until assessment is completed
   */
  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        console.log("Retrieving score ...")
        scoreEntity.getScore().then(async (response) => {
          setScore(response.data)
          if (response.data.failed) {
            setLoading(false)
            clearInterval(interval)
          } else if (response.data.processing === false) {
            await Promise.all([
              // Env collect is being reloaded because when consolidating it is getting populated
              reloadEnvCollect(),
              reloadEnvScore(),
              reloadSocialCollect(),
              reloadSocialScore(),
              profitCollectRequest.reload(),
              profitScoreRequest.reload(),
              reloadCurrentNode(),
            ])
            setLoading(false)
            clearInterval(interval)
          }
        })
      }, 3000)
      return () => {
        clearInterval(interval)
      }
    }
  }, [loading, score])

  // Display loading spinner if score is in progress
  return (
    <>
      {loading && (
        <Layout.Section>
          <div className="Verdikt-Score-Loading">
            <img src={loadingGif} alt={t("score:loading")} />
          </div>
        </Layout.Section>
      )}

      <Layout.Section>
        <header className="Verdikt-Score-Header">
          <>
            {!showMaturity(
              currentOrganization,
              currentRootNode,
              currentNode,
              isFeatureActive
            ) ? (
              <CO2EValue
                value={envScore?.odd13_CO2}
                pillarId={PILLAR_ENVIRONMENTAL}
              />
            ) : (
              <ScoreValue
                score={score?.maturity_index}
                pillarId={PILLAR_ENVIRONMENTAL}
              />
            )}
            <ScoreGauge score={score?.maturity_index} />
          </>
        </header>
      </Layout.Section>

      <Layout.Section>
        <Columns profitScore={profitScore} quantitative={quantitative} />
      </Layout.Section>
    </>
  )
}

Score.propTypes = {
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  setShowObjectiveSwitch: PropTypes.func,
  quantitative: PropTypes.bool,
}

Score.defaultProps = {
  loading: false,
  quantitative: false,
}

export default Score
