import React from "react"
import { useEntity } from "hooks/useAxios"
import ScoreEntity from "services/entities/ScoreEntity"
import ComparePageContentTemplate from "views/templates/ComparePageContentTemplate"
import NodeAnalysesOrganism from "views/organisms/canvases/charts/compare/NodeAnalysesOrganism"
import {
  COMPARE_WEBSITES_AND_ASSETS_FEATURE,
  useFeatureFlag,
} from "hooks/useFeatureFlag"
import SimpleNodeAnalysesOrganism from "views/organisms/canvases/charts/compare/SimpleNodeAnalysesOrganism"

const ComparePage = () => {
  const scoreEntity = useEntity(ScoreEntity)
  const { ifFeatureActive } = useFeatureFlag()

  return (
    <ComparePageContentTemplate
      compareRequester={scoreEntity.compare}
      CompareScoreComponent={ifFeatureActive(
        COMPARE_WEBSITES_AND_ASSETS_FEATURE,
        NodeAnalysesOrganism,
        SimpleNodeAnalysesOrganism
      )}
    />
  )
}

export default ComparePage
