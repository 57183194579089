import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import classNames from "classnames"

export const ChartContainer = ({
  title,
  helpText,
  children,
  pillar = "environmental",
}) => {
  return (
    <HelpWidgetChartInteractiveMolecule text={helpText}>
      <div className={classNames("Verdikt-Score-BarChart", pillar)}>
        <h1>{title}</h1>
        <div className="content">{children}</div>
      </div>
    </HelpWidgetChartInteractiveMolecule>
  )
}
