const averagesDataset = (defaultLabel, label, data) => {
  return {
      type: "line",
      label: label? label : defaultLabel,
      data: data,
      order: 5,
      fill: false,
      pointStyle: "line",
      pointRadius: 20,
      pointHoverRadius: 20,
      pointBorderWidth: 20,
      pointHoverBorderWidth: 20,
      pointBorderColor: "#333C37",
      showLine: false,
      borderColor: "#333C37",
      ticks: { min: 0 },
  }
}

export default averagesDataset;