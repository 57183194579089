import React from "react"
import ChartAnalysisCloudServicesAndCosts from "views/molecules/canvases/charts/cloud/ChartAnalysisCloudServicesAndCosts"
import ChartAnalysisCloudCo2VSCost from "views/molecules/canvases/charts/cloud/ChartAnalysisCloudCo2VSCost"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"

const ChartCloudServiceCostsDispatcher = ({ envScore }) => {
  const { t } = useTranslation()
  const services_and_costs = envScore?.cloud_score?.services_and_costs
  const cost_and_co2_by_provider =
    envScore?.cloud_score?.cost_and_co2_by_provider

  return services_and_costs ? (
    <ChartAnalysisCloudServicesAndCosts
      datasets={services_and_costs?.datasets || []}
      labels={services_and_costs?.label_keys || []}
      unitKey={services_and_costs?.unit_key || ""}
      lineUnitKey={services_and_costs?.line_unit_key || ""}
      title={t("analyze:it_domain.cloud.charts.services_and_costs.title")}
    />
  ) : (
    <ChartAnalysisCloudCo2VSCost
      co2_unit={t(
        `units:units.emission.${cost_and_co2_by_provider.co2_unit_key}`
      )}
      datasets={cost_and_co2_by_provider?.datasets || []}
      labels={cost_and_co2_by_provider?.label_keys || []}
    />
  )
}

ChartCloudServiceCostsDispatcher.propTypes = {
  envScore: PropTypes.object.isRequired,
}

export default ChartCloudServiceCostsDispatcher
