import React from "react"
import ChartODD13EquipmentsGHGEmissionsMolecule from "views/molecules/canvases/charts/ChartODD13EquipmentsGHGEmissionsMolecule"
import ChartODD13GHGEmissionsDistributionMolecule from "views/molecules/canvases/charts/ChartODD13GHGEmissionsDistributionMolecule"
import ChartCloudServiceCostsDispatcher from "views/organisms/canvases/charts/ITDomainAnalysis/dispatcher/ChartCloudServiceCostsDispatcher"
import ChartODD13GHGEmissionsInternalExternalMolecule from "views/molecules/canvases/charts/ChartODD13GHGEmissionsInternalExternalMolecule"
import ChartCloudRegionsDispatcher from "views/organisms/canvases/charts/ITDomainAnalysis/dispatcher/ChartCloudRegionsDispatcher"
import ChartODD13EmissionByMonth from "views/molecules/canvases/charts/ChartODD13EmissionByMonth"
import ChartOdd13ExternalAppEmissions from "views/molecules/canvases/charts/ChartOdd13ExternalAppEmissions"
import ChartQuantitativeCo2ImpactsMolecule from "views/molecules/canvases/charts/websites/ChartQuantitativeCo2ImpactsMolecule"
import ChartAssetsNetworkDispatcher from "views/organisms/canvases/charts/ITDomainAnalysis/dispatcher/ChartAssetsNetworkDispatcher"
import ChartAssetsHostingDispatcher from "views/organisms/canvases/charts/ITDomainAnalysis/dispatcher/ChartAssetsHostingDispatcher"
import ChartAssetsWorkplaceDispatcher from "views/organisms/canvases/charts/ITDomainAnalysis/dispatcher/ChartAssetsWorkplaceDispatcher"
import ChartCloudLowUseDispatcher from "views/organisms/canvases/charts/ITDomainAnalysis/dispatcher/ChartCloudLowUseDispatcher"
import { useTranslation } from "react-i18next"
import ChartCommuteVSTravel from "views/organisms/canvases/charts/ITDomainAnalysis/dispatcher/ChartCommuteVSTravel"
import ChartOdd13NetworkAndHostingEquipementsGHGEmissionsMolecule from "views/molecules/canvases/charts/ChartOdd13NetworkAndHostingEquipementsGHGEmissionsMolecule"

const Objective13FlexibilityChartMapping = ({ chartKey, envScore }) => {
  const { t } = useTranslation()

  const KEYS_TO_CHART_COMPONENT = {
    equipment_ghg_emission: (envScore) => {
      return <ChartODD13EquipmentsGHGEmissionsMolecule score={envScore} />
    },
    ghg_emission_distribution: (envScore) => (
      <ChartODD13GHGEmissionsDistributionMolecule score={envScore} />
    ),
    emissions_and_costs_per_service: (envScore) => (
      <ChartCloudServiceCostsDispatcher envScore={envScore} />
    ),
    service_emission_by_region: (envScore) => (
      <ChartCloudRegionsDispatcher envScore={envScore} />
    ),
    internal_external_emissions: (envScore) => (
      <ChartODD13GHGEmissionsInternalExternalMolecule
        ghgSplit={envScore?.odd13_ghg_internal_external_spit}
      />
    ),
    emissions_throughout_period: (envScore) => (
      <ChartODD13EmissionByMonth score={envScore} />
    ),
    most_emitting_applications: (envScore) => (
      <ChartOdd13ExternalAppEmissions score={envScore.saas} />
    ),
    ghg_consumption_of_digital_services: (envScore) => (
      <ChartQuantitativeCo2ImpactsMolecule
        mixedChart={envScore.website_analyse?.quantitative_co2_impacts}
        title={t(
          `analyze:it_domain.cloud.kpis.digital_services_emissions.title`
        )}
      />
    ),
    workplace_emissions: (envScore) => (
      <ChartAssetsWorkplaceDispatcher envScore={envScore} />
    ),
    network_emissions: (envScore) => (
      <ChartAssetsNetworkDispatcher envScore={envScore} />
    ),
    hosting_emissions: (envScore) => (
      <ChartAssetsHostingDispatcher envScore={envScore} />
    ),
    biggest_emissions_of_low_service_use: (envScore) => (
      <ChartCloudLowUseDispatcher envScore={envScore} />
    ),
    it_team_travel_vs_commute: (envScore) => (
      <ChartCommuteVSTravel envScore={envScore} />
    ),
    odd13_internal_hosting_equipments_emissions_top: (envScore) => (
      <ChartOdd13NetworkAndHostingEquipementsGHGEmissionsMolecule
        stackedChart={envScore?.odd13_internal_hosting_equipments_emissions_top}
      />
    ),
  }

  return KEYS_TO_CHART_COMPONENT[chartKey]
    ? KEYS_TO_CHART_COMPONENT[chartKey](envScore)
    : null
}

export default Objective13FlexibilityChartMapping
