import classNames from "classnames/bind"
import React, { useCallback } from "react"
import { useParams } from "react-router-dom"
import "stylesheets/CollectPage.scss"
// Environment forms
import Objective6Form from "components/widgets/CollectPage/forms/pillars/environment/Objective6Form"
import Objective7Form from "components/widgets/CollectPage/forms/pillars/environment/Objective7Form"
import Objective13Form from "components/widgets/CollectPage/forms/pillars/environment/Objective13Form"
import Objective15Form from "components/widgets/CollectPage/forms/pillars/environment/Objective15Form"
// Social forms
import Objective3Form from "components/widgets/CollectPage/forms/pillars/social/Objective3Form"
import Objective4Form from "components/widgets/CollectPage/forms/pillars/social/Objective4Form"
import Objective5Form from "components/widgets/CollectPage/forms/pillars/social/Objective5Form"
import Objective10Form from "components/widgets/CollectPage/forms/pillars/social/Objective10Form"
import Objective1_2_11_Form from "components/widgets/CollectPage/forms/pillars/social/Objective1_2_11_Form"
import Objective12Organism from "views/organisms/collects/objectives/12/Objective12Organism"
import { Form } from "@shopify/polaris"
import Objective9Form from "./forms/pillars/financial/Objective9Form"
import Objective16Form from "components/widgets/CollectPage/forms/pillars/financial/Objective16Form"
import Objective8Form from "./forms/pillars/financial/Objective8Form"
import Objective17Form from "./forms/pillars/financial/Objective17Form"
import { useAppContext } from "components/AppContext"

const ThemeFrame = () => {
  const { pillarId, objectiveId, themeId } = useParams()

  const { envCollect, currentRootNode } = useAppContext()

  const objectiveFormMarkup = useCallback(() => {
    switch (objectiveId) {
      // Env
      case "6":
        return <Objective6Form />
      case "7":
        return <Objective7Form />
      case "12":
        return <Objective12Organism />
      case "13":
        return <Objective13Form />
      case "15":
        return <Objective15Form />
      // Social
      case "3":
        return <Objective3Form />
      case "10":
        return <Objective10Form />
      case "5":
        return <Objective5Form />
      case "4":
        return <Objective4Form />
      case "1":
      case "2":
      case "11":
        return <Objective1_2_11_Form />
      // Profit
      case "8":
        return <Objective8Form />
      case "9":
        return <Objective9Form />
      case "16":
        return <Objective16Form />
      case "17":
        return <Objective17Form />
      default:
        return (
          <Form>
            <div className="columns">
              <div className="column column">
                <p>Aucun formulaire pour cet ODD.</p>
              </div>
            </div>
          </Form>
        )
    }
  }, [pillarId, objectiveId, themeId])

  // All positive impact themes have the same themeId
  const customTheme =
    themeId === "positive_impact" ? `odd${objectiveId}_${themeId}` : themeId

  const isActivated =
    currentRootNode.is_corporate ||
    envCollect?.activated_themes?.includes(customTheme)

  return (
    <div className={"themeFrame"}>
      <div
        className={classNames("themeForm", pillarId, {
          activated: isActivated,
        })}
      >
        {objectiveFormMarkup()}
      </div>
    </div>
  )
}

export default ThemeFrame
