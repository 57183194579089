import React from "react"
import { Icon } from "@shopify/polaris"
import { EditMajor, ViewMajor } from "@shopify/polaris-icons"
import PropTypes from "prop-types"
import useFieldIsLocked from "components/widgets/CollectPage/forms/fields/useFieldIsLocked"
import { useModuleContext } from "components/widgets/CollectPage/forms/Module"
import useThemes, { IT_TEAM_THEME } from "hooks/useTheme"
import classNames from "classnames"

const ModalInventoriesActivator = ({ onClick, disabled }) => {
  const { isLocked } = useFieldIsLocked()
  const { moduleIsActive } = useModuleContext()
  const { isThemeActive } = useThemes()

  const icon = disabled || isLocked ? ViewMajor : EditMajor

  if (!moduleIsActive) {
    return null
  }

  return (
    <button onClick={onClick} disabled={disabled}>
      <div
        className={classNames("iconCollectType", {
          disabled: !isThemeActive(IT_TEAM_THEME),
        })}
      >
        <Icon source={icon} />
      </div>
    </button>
  )
}

ModalInventoriesActivator.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

ModalInventoriesActivator.defaultProps = {
  disabled: false,
}

export default ModalInventoriesActivator
