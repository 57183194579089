import React, { useEffect } from "react"
import { Page } from "@shopify/polaris"
import Loading from "components/widgets/Loading"
import AnalyzeGHGTemplate from "views/templates/AnalyzeGHGTemplate"
import {
  PILLAR_ENVIRONMENTAL,
  PILLAR_PROFIT,
  PILLAR_SOCIAL,
} from "utils/constants/pillars"
import { useAppContext } from "components/AppContext"
import ObjectiveNavigationTemplate from "views/templates/ObjectiveNavigationTemplate"
import withAuthRequired from "../../components/auth/withAuthRequired"
import usePillars from "views/organisms/pillars/usePillars"
import { useHistory, useParams } from "react-router-dom"
import WebsiteAnalyzeOrganism from "views/organisms/analyzes/WebsiteAnalyzeOrganism"
import ScoreLoaderTemplate from "views/templates/ScoreLoaderTemplate"

const AnalyzeWebsitesPage = () => {
  const { id } = useParams()
  const history = useHistory()

  const {
    currentPeriod,
    parentOrganization,
    envScore,
    isEnvScoreLoading,
    isCurrentNodeLoading,
  } = useAppContext()

  const charts = envScore?.websites_charts

  useEffect(() => {
    if (isEnvScoreLoading || !envScore) return

    if (!envScore?.websites_charts) {
      history.push("/analyze/it-domain/environmental/13/equipments")
      return
    }

    if (!id || !envScore.websites_charts.charts[id]) {
      history.push(`/analyze/websites/${envScore.websites_charts.tabs[0].id}`)
    }
  }, [envScore, isEnvScoreLoading, location.pathname])

  const tabs = charts?.tabs.map((tab) => {
    return {
      name: tab.title,
      url: `/analyze/websites/${tab.id}`,
      selected: tab.id === +id,
    }
  })

  const objectives = [
    {
      objectiveId: 13,
      selected: true,
      url: "/analyze/ghg-scopes/environmental/13/ghg_protocol/synthesis",
    },
  ]

  const firstTabId = tabs?.[0]?.id

  const pillars = usePillars({
    pageName: `/analyze/websites/${firstTabId}`,
    [PILLAR_SOCIAL]: { disabled: true },
    [PILLAR_PROFIT]: { disabled: true },
  }).map((pillar) => {
    return { ...pillar, selected: pillar.pillar === PILLAR_ENVIRONMENTAL }
  })

  if (isEnvScoreLoading || isCurrentNodeLoading) {
    return <Loading />
  }

  return (
    <ObjectiveNavigationTemplate>
      <Page fullWidth={!!envScore}>
        <ScoreLoaderTemplate pillarScore={envScore}>
          <AnalyzeGHGTemplate
            envScore={envScore}
            parentOrganization={parentOrganization}
            tabs={tabs}
            themeType={PILLAR_ENVIRONMENTAL}
            objectives={objectives}
            pillars={pillars}
            periodName={currentPeriod?.name}
            forceSize
          >
            <WebsiteAnalyzeOrganism envScore={envScore} websiteId={id} />
          </AnalyzeGHGTemplate>
        </ScoreLoaderTemplate>
      </Page>
    </ObjectiveNavigationTemplate>
  )
}

export default withAuthRequired(AnalyzeWebsitesPage)
