import React from "react"

import { Checkbox } from "@shopify/polaris"

const ThemeTabActivator = ({ isActivated, toggle, isEditable }) => {
  return (
    <div>
      <Checkbox
        label=""
        checked={isActivated}
        onChange={toggle}
        disabled={!isEditable}
      />
    </div>
  )
}

export default ThemeTabActivator
