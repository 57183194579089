import { useTranslation } from "react-i18next"
import React from "react"
import PropTypes from "prop-types"
import MixedChartTemplate from "views/molecules/charts/template/MixedChartTemplate"
import { convertValuesToReadable } from "services/units/valueConversion"
import { CO2_EMISSION_UNITS, T_CO2E } from "services/units/units"

const ChartOdd13InternalAppsBreakdown = ({
  data,
  localPrefix,
  labelGetter,
}) => {
  const { t } = useTranslation()

  const { bar, line, label } = data

  const convertedBarData = convertValuesToReadable(
    bar.filter((value) => value !== 0),
    T_CO2E,
    CO2_EMISSION_UNITS
  )

  const data_inputs = {
    bar_data: {
      label: t(`${localPrefix}.emissions`),
      data: convertedBarData.values,
    },
    line_data: {
      label: t(`${localPrefix}.apps`),
      data: line,
    },
  }

  return (
    <MixedChartTemplate
      title={t(`${localPrefix}.title`)}
      leftDataInputs={data_inputs.line_data.data}
      leftDataBarTitle={data_inputs.line_data.label}
      rightDataInputs={data_inputs.bar_data.data}
      rightDataBarTitle={data_inputs.bar_data.label}
      labelKeys={label.map(
        (value) => labelGetter(value) ?? value ?? t(`${localPrefix}.empty`)
      )}
      leftUnitPath={localPrefix}
      leftUnitKey="number"
      rightUnitPath="units:units.emission"
      rightUnitKey={convertedBarData.unit}
      helpText={t(`${localPrefix}.help`)}
      valueAndUnitOn2lines
    />
  )
}

ChartOdd13InternalAppsBreakdown.propTypes = {
  data: PropTypes.shape({
    bar: PropTypes.arrayOf(PropTypes.number).isRequired,
    line: PropTypes.arrayOf(PropTypes.number).isRequired,
    label: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  localPrefix: PropTypes.string.isRequired,
  labelGetter: PropTypes.func,
}

ChartOdd13InternalAppsBreakdown.defaultProps = {
  labelGetter: null,
}

export default ChartOdd13InternalAppsBreakdown
