import React from "react"
import ChartODD13RegionNetworkBreakdownMolecule from "views/molecules/canvases/charts/ChartODD13RegionNetworkBreakdownMolecule"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import ChartODD13EquipmentTypeVolumeTotalGHGMolecule from "views/molecules/canvases/charts/ChartODD13EquipmentTypeVolumeTotalGHGMolecule"

const ChartAssetsNetworkDispatcher = ({ envScore }) => {
  const { t } = useTranslation()

  if (envScore.odd13_global_node_children_defined) {
    return (
        <ChartODD13RegionNetworkBreakdownMolecule score={envScore} />
    )
  } else {
    return (
        <ChartODD13EquipmentTypeVolumeTotalGHGMolecule
          mixedChart={envScore?.odd13_network_volume_emission_equipment}
          type="network"
          helpText={t(`analyze:it_domain.per_node.chartRegionNetworkBreakdown.help`)}
        />
    )
  }
}

ChartAssetsNetworkDispatcher.propTypes = {
  envScore: PropTypes.object.isRequired,
}

export default ChartAssetsNetworkDispatcher
