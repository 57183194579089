import NodeOrganization from "services/organizations/NodeOrganization"
import i18n from "i18next"
import { convertValueToReadable } from "services/units/valueConversion"
import { CO2_EMISSION_UNITS, T_CO2E } from "services/units/units"
import { round } from "services/units/round"

const prepareDoughnutChartData = (
  doughnutChartsData = [],
  doughnutChartsDataTotal = 0,
  doughnutChartsDataUnitBase = T_CO2E
) => {
  const chartsData = doughnutChartsData.map((node) => {
    const label = NodeOrganization.getName(node.label, node.type, {
      countryShortName: true,
    })

    return {
      label,
      data: node.data,
      unit: i18n.t(`units:units.emission.${node.unit}`),
    }
  })

  const {
    value: total,
    unit: totalUnit,
  } = convertValueToReadable(
    doughnutChartsDataTotal,
    doughnutChartsDataUnitBase,
    CO2_EMISSION_UNITS,
    (value) => round(value, 0)
  )

  return {
    formatedTotal: total,
    totalUnit: i18n.t(`units:units.emission.${totalUnit}`),
    chartsData,
  }
}

export default prepareDoughnutChartData
