import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { Toast } from "@shopify/polaris"
import { useTranslation } from "react-i18next"
import {
  TOAST_STATUS_ALL_CHILDREN_NOT_KEPT,
  TOAST_STATUS_COLLECT_CLOSED,
  TOAST_STATUS_COLLECT_UPDATED,
  TOAST_STATUS_ERROR,
  TOAST_STATUS_FILE_PROCESSED,
  TOAST_STATUS_FILE_REJECTED,
  TOAST_STATUS_INACTIVE,
  TOAST_STATUS_INVALID_COLLECT,
  TOAST_STATUS_PREFERRED_SELECTED_VERSION,
  TOAST_STATUS_PREFERRED_UNSELECTED_VERSION,
  TOAST_STATUS_VERSION_CHILDREN_KEPT,
  TOAST_STATUS_NODE_BEING_DELETED,
} from "utils/constants/toastStatuses"

const TOAST_ERRORS = [
  TOAST_STATUS_INVALID_COLLECT,
  TOAST_STATUS_ERROR,
  TOAST_STATUS_FILE_PROCESSED,
  TOAST_STATUS_FILE_REJECTED,
  TOAST_STATUS_ALL_CHILDREN_NOT_KEPT,
  TOAST_STATUS_VERSION_CHILDREN_KEPT,
]

const TOAST_DURATION = 5000

const ToastAtom = ({ toastStatus, setToastStatus, duration }) => {
  const { t } = useTranslation()
  const toastMessage = useCallback(() => {
    switch (toastStatus) {
      case TOAST_STATUS_COLLECT_UPDATED:
        return t(`common:toast_messages.saved`)
      case TOAST_STATUS_INVALID_COLLECT:
        return t(`common:toast_messages.error_check_data`)
      case TOAST_STATUS_COLLECT_CLOSED:
        return t(`common:toast_messages.consolidation_launched`)
      case TOAST_STATUS_ERROR:
        return t(`common:toast_messages.consolidation_unavailable`)
      case TOAST_STATUS_FILE_PROCESSED:
        return t(`common:toast_messages.consolidation_wrong_employees_number`)
      case TOAST_STATUS_FILE_REJECTED:
        return t(`common:toast_messages.consolidation_failure`)
      case TOAST_STATUS_ALL_CHILDREN_NOT_KEPT:
        return t(`common:toast_messages.all_children_not_kept`)
      case TOAST_STATUS_VERSION_CHILDREN_KEPT:
        return t(`common:toast_messages.several_version_of_a_children_are_kept`)
      case TOAST_STATUS_PREFERRED_SELECTED_VERSION:
        return t(`common:toast_messages.preferredVersionSelected`)
      case TOAST_STATUS_PREFERRED_UNSELECTED_VERSION:
        return t(`common:toast_messages.preferredVersionUnselected`)
      case TOAST_STATUS_NODE_BEING_DELETED:
        return t(`common:toast_messages.nodeBeingDeleted`)
      default:
        return null
    }
  }, [toastStatus])

  const isError = TOAST_ERRORS.includes(toastStatus)

  if (toastStatus === TOAST_STATUS_INACTIVE) return null

  return (
    <Toast
      error={isError}
      content={toastMessage()}
      duration={duration ? duration : TOAST_DURATION}
      onDismiss={() => {
        setToastStatus(TOAST_STATUS_INACTIVE)
      }}
    />
  )
}

ToastAtom.propTypes = {
  setToastStatus: PropTypes.func,
  toastStatus: PropTypes.number,
  duration: PropTypes.number,
}

export default ToastAtom
