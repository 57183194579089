import {
  Button,
  DropZone,
  Form as PForm,
  FormLayout,
  Icon,
  Modal,
  SkeletonBodyText,
  Stack,
  TextStyle,
} from "@shopify/polaris"
import { IMPORT_STATUS_WAITING_FOR_FILE } from "utils/constants/imports/importStatus"

import {
  CirclePlusMajor,
  CirclePlusMinor,
  EditMinor,
  ViewMinor,
} from "@shopify/polaris-icons"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useEntity } from "hooks/useAxios"

import UploadCentralizedOrganism from "views/organisms/upload/UploadCentralizedOrganism"
import UploadOrganism from "views/organisms/upload/UploadOrganism"
import { INVENTORY_WEBSITES } from "utils/constants/centralization/inventoryItems"
import {
  ExternalHelp,
  ExternalLabel,
  Select,
  TextField,
} from "../../fields/CollectFields"

import useFieldIsLocked from "../../fields/useFieldIsLocked"
import { useFormContext } from "../../FormContext"
import { useCollectContext } from "../../../CollectContextProvider"
import WebsitesEntity from "services/entities/WebsitesEntity"
import { locations } from "helpers/CollectHelper"
import CountryApp from "services/apps/countryApp"
import YesNoRadio from "components/widgets/CollectPage/forms/fields/YesNoRadio"
import StatusUploadOrganism from "views/organisms/upload/StatusUploadOrganism"
import DropZoneCaptionTemplate from "views/templates/DropZoneCaptionTemplate"
import DropZoneFileNameMolecule from "views/molecules/text/DropZoneFileNameMolecule"
import { createFormDataFromData } from "services/collect/websites"
import { displayUrl } from "utils/UrlReformater"
import { useAppContext } from "components/AppContext"
import { downloadFile } from "services/download/download"
import ActiveStorageAttachmentEntity from "services/entities/ActiveStorageAttachmentEntity"

const translationPath = "13.software.website_modal"

const WebsiteInventory = () => {
  const { t } = useTranslation()
  const { reloadProgress } = useCollectContext()
  const isMounted = useRef(null)
  const [websites, setWebsites] = useState(null)
  const [isModalOpen, setModalOpen] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [shouldLoad, setShouldLoad] = useState(true)
  const websitesEntity = useEntity(WebsitesEntity)
  const activeStorageAttachmentsEntity = useEntity(
    ActiveStorageAttachmentEntity
  )
  const { collect, setToastStatus } = useCollectContext()
  const [filesStatuses, setFilesStatuses] = useState({})

  const {
    formData,
    setFormData,
    setErrors,
    setTranslationPath,
  } = useFormContext()

  const { reloadEnvCollect } = useAppContext()

  useEffect(() => {
    if (formData?.website?.id) {
      setFilesStatuses(formData.website.reports)
    }
  }, [formData])
  useEffect(() => setTranslationPath(translationPath), [])

  const translationPrefix = "collect:questions.13.software."

  const businesses = [
    {
      label: t(`common:website_businesses.${"corporate"}`),
      value: "corporate",
    },
    {
      label: t(`common:website_businesses.${"commerce"}`),
      value: "commerce",
    },
    {
      label: t(`common:website_businesses.${"other"}`),
      value: "other",
    },
  ]

  const { isLocked } = useFieldIsLocked()

  const getStatus = (fileKey) => {
    return () => {
      if (filesStatuses[fileKey]) {
        return filesStatuses[fileKey]
      } else {
        return { processing_status: IMPORT_STATUS_WAITING_FOR_FILE }
      }
    }
  }

  const handleDrop = (key) => {
    return async (files, acceptedFiles) => {
      if (acceptedFiles.length < 1) {
        setToastStatus(6)
        return
      }
      setFormData({
        ...formData,
        [key]: { data: acceptedFiles[0] },
      })
    }
  }

  const handleDropLighthouse = handleDrop("lighthouse_report")
  const getLighthouseStatus = getStatus("lighthouse_report")

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(async () => {
    if (!shouldLoad) return
    setShouldLoad(false)
    const { data } = await websitesEntity.list()
    if (!isMounted) return
    setWebsites(data)
  }, [shouldLoad])

  const persistWebsite = useCallback(() => {
    const successCallback = () => {
      setIsSending(false)
      setShouldLoad(true)
      toggleModalOpen()
      reloadProgress()
      reloadEnvCollect()
    }
    const errorCallback = (error) => {
      setIsSending(false)
      setErrors(error.response?.data)
    }

    const data = {
      ...formData,
      percentage_usage_desktop:
        formData.percentage_usage_mobile != null
          ? 100 - Number(formData.percentage_usage_mobile)
          : null,
    }

    const newFormData = createFormDataFromData(data)

    setErrors({})
    setIsSending(true)
    const promise = formData.id
      ? websitesEntity.update(formData.id, newFormData)
      : websitesEntity.create(newFormData)
    promise.then(successCallback).catch(errorCallback)
  }, [formData])

  const deleteWebsite = useCallback(() => {
    if (!formData.id) return
    setIsSending(true)
    websitesEntity
      .delete(formData.id)
      .then(() => {
        setIsSending(false)
        setShouldLoad(true)
        reloadProgress()
        reloadEnvCollect()
        toggleModalOpen()
      })
      .catch(() => {
        setIsSending(false)
      })
  }, [formData])

  const toggleModalOpen = useCallback(() => {
    if (isModalOpen) {
      setErrors({})
      setFormData({})
      setFilesStatuses({})
    }
    setModalOpen(!isModalOpen)
  }, [isModalOpen])

  useEffect(() => {
    if (formData?.percentage_usage_mobile) {
      setFormData({
        ...formData,
        percentage_usage_desktop:
          100 - Number(formData?.percentage_usage_mobile),
      })
    }
  }, [formData?.percentage_usage_mobile])

  const modalMarkup = () => {
    const countries = locations({
      additionalKeys: [
        {
          label: t(`${translationPrefix}website_modal.country_location.empty`),
          value: null,
        },
      ],
    })
    const title = formData.id
      ? t(translationPrefix + "website_modal.edit")
      : t(translationPrefix + "website_modal.add")
    const secondaryAction = formData.id
      ? {
          content: t("common:delete"),
          onAction: deleteWebsite,
          destructive: true,
          loading: isSending,
          disabled: isLocked,
        }
      : null
    return (
      <Modal
        open={isModalOpen}
        onClose={toggleModalOpen}
        title={title}
        primaryAction={{
          content: t("common:save"),
          onAction: persistWebsite,
          loading: isSending,
          disabled: isLocked,
        }}
        secondaryActions={secondaryAction}
      >
        <div className="environmental">
          <Modal.Section>
            <PForm onSubmit={persistWebsite} implicitSubmit={false}>
              <FormLayout>
                <TextField id="url" type="url" />
                <YesNoRadio id="behind_authentication" />
                <div className={"dropzone_title"}>
                  {!!formData?.behind_authentication && (
                    <>
                      <p>
                        {t(
                          "collect:questions.13.software.website_modal.dropzone_title"
                        )}
                      </p>
                      {getDropZoneComponent(
                        handleDropLighthouse,
                        getLighthouseStatus,
                        !!collect.closed_at,
                        formData?.lighthouse_report?.data
                      )}
                    </>
                  )}
                </div>
                <Select id="website_type" options={businesses} />
                <TextField id="period_visitors" type="number" />
                <YesNoRadio id="is_hosted_on_prem" />
                <Select id="country_location" options={countries} />
                <TextField
                  id="average_duration_on_website_in_min"
                  type="number"
                />
                <TextField id="percentage_usage_mobile" type="number" />
                <TextField
                  id="percentage_usage_desktop"
                  type="number"
                  disabled
                />
                <TextField
                  id="average_visited_pages_per_session"
                  type="number"
                />
              </FormLayout>
            </PForm>
            <br />
            <TextStyle variation="subdued">
              {t("collect:questions.common.nota_bene")}
            </TextStyle>
            <ExternalHelp>
              {t("collect:questions.13.software.website_modal.sources")}
            </ExternalHelp>
          </Modal.Section>
        </div>
      </Modal>
    )
  }

  const openModalForNewWebsite = () => {
    setFormData({ is_hosted_on_prem: false })
    toggleModalOpen()
  }

  const onDownloadFile = async (activeStorageAttachmentId) => {
    const { data: url } = await activeStorageAttachmentsEntity.show(
      activeStorageAttachmentId
    )

    downloadFile(url)
  }

  const getDropZoneComponent = (handleDrop, status, disabled, file) => {
    if (file) {
      return (
        <DropZone
          accept="application/json"
          allowMultiple={false}
          onDrop={handleDrop}
          disabled={disabled}
        >
          <DropZoneCaptionTemplate>
            <DropZoneFileNameMolecule file={file} />
          </DropZoneCaptionTemplate>
        </DropZone>
      )
    } else {
      return (
        <StatusUploadOrganism
          accept="application/json"
          allowMultiple={false}
          onDrop={handleDrop}
          disabled={disabled}
          inventoryStatus={status()}
          setInventoryStatus={() => {}}
          displayQualityIcon={false}
          onDownloadFile={onDownloadFile}
        />
      )
    }
  }

  const tableMarkup = useCallback(() => {
    if (!websites) return <SkeletonBodyText />
    const items = []
    if (websites.length === 0) {
      items.push(
        <tr key="0">
          <td colSpan={6}>
            <Stack
              distribution="center"
              alignment="center"
              spacing="extraTight"
            >
              <p>{t(translationPrefix + "website_table.empty")}</p>
              {!isLocked && (
                <Button plain onClick={openModalForNewWebsite}>
                  <Icon source={CirclePlusMajor} />
                </Button>
              )}
            </Stack>
          </td>
        </tr>
      )
    } else {
      websites.forEach((website, index) => {
        let formattedUrl = displayUrl(website)

        items.push(
          <tr key={index}>
            <td>{formattedUrl}</td>
            <td>{website.period_visitors}</td>
            <td>
              {website.average_duration_on_website_in_min == null
                ? "N/A"
                : Math.round(
                    Number(website.average_duration_on_website_in_min)
                  ) +
                  "" +
                  t(
                    translationPrefix +
                      "website_modal.average_duration_on_website_in_min.suffix"
                  )}
            </td>
            <td>
              {website.average_visited_pages_per_session == null
                ? "N/A"
                : Math.round(Number(website.average_visited_pages_per_session))}
            </td>
            <td>
              {website.percentage_usage_mobile == null
                ? "N/A"
                : website.percentage_usage_mobile +
                  t(
                    translationPrefix +
                      "website_modal.percentage_usage_mobile.suffix"
                  )}
            </td>
            <td>
              {website.country_location
                ? CountryApp.getNameI18n(website.country_location)
                : t(
                    "collect:questions.13.datacenter_external.dc.table.own_collect"
                  )}
            </td>
            <td>
              <Button
                plain
                onClick={() => {
                  setFormData(website)
                  toggleModalOpen()
                  setFilesStatuses(website.reports)
                }}
              >
                <Icon source={isLocked ? ViewMinor : EditMinor} />
              </Button>
            </td>
          </tr>
        )
      })
    }

    console.log(websites)
    return (
      <div>
        <Stack vertical>
          <table className="Verdikt-DynamicTable">
            <thead>
              <tr className="leftAligned">
                <th>{t(translationPrefix + "website_table.url")}</th>
                <th>
                  {t(translationPrefix + "website_table.period_visitors")}
                </th>
                <th>
                  {t(
                    translationPrefix +
                      "website_table.average_duration_on_website_in_min"
                  )}
                </th>
                <th>
                  {t(
                    translationPrefix +
                      "website_table.average_visited_pages_per_session"
                  )}
                </th>
                <th>
                  {t(
                    translationPrefix + "website_table.percentage_usage_mobile"
                  )}
                </th>
                <th>
                  {t(translationPrefix + "website_table.country_location")}
                </th>
                {!isLocked && (
                  <th>
                    <Button plain onClick={openModalForNewWebsite}>
                      <Icon source={CirclePlusMinor} />
                    </Button>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>{items}</tbody>
          </table>
        </Stack>
      </div>
    )
  }, [websites])

  return (
    <div>
      <ExternalLabel
        pathOverride="13.software"
        translationPathName="website_table"
      />

      <UploadOrganism
        centralisedComponent={
          <UploadCentralizedOrganism inventoryType={INVENTORY_WEBSITES} />
        }
        decentralisedComponent={
          <>
            {modalMarkup()}
            {tableMarkup()}
          </>
        }
      />
    </div>
  )
}

export default WebsiteInventory
