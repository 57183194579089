import Loading from "components/widgets/Loading"
import Error from "components/widgets/ScorePage/Error"
import React from "react"
import ObjectiveSwitch from "components/widgets/ScorePage/ObjectiveSwitch"
import Message from "components/widgets/Dashboard/Message"
import { useAppContext } from "components/AppContext"
import ObjectiveNavigationTemplate from "views/templates/ObjectiveNavigationTemplate"
import withAuthRequired from "../auth/withAuthRequired"
import useDefaultPillar from "hooks/useDefaultPillar"
import {
  PILLAR_ENVIRONMENTAL,
  PILLAR_PROFIT,
  PILLAR_SOCIAL,
  PILLARS_OBJECTIVES,
} from "utils/constants/pillars"
import AnalyzeTemplate from "views/templates/AnalyzeTemplate"
import usePillars from "views/organisms/pillars/usePillars"
import { useParams } from "react-router-dom"
import { Page } from "@shopify/polaris"

export const SCORE_URL = "/score"

const ScorePage = () => {
  const { pillarId, objectiveId } = useParams()

  const {
    currentPeriod,
    parentOrganization,
    isParentOrganizationLoading,
    envScore,
    score,
    currentRootNode,
    profitScore,
    socialScore,
    isEnvScoreLoading,
    isSocialCollectLoading,
    isSocialScoreLoading,
    isProfitScoreLoading,
    isCurrentNodeLoading,
    isEnvScoreError,
    isSocialScoreError,
    isProfitScoreError,
  } = useAppContext()

  useDefaultPillar({})

  const pillars = usePillars({
    pageName: SCORE_URL,
    [PILLAR_ENVIRONMENTAL]: { disabled: !envScore },
    [PILLAR_SOCIAL]: { disabled: !socialScore },
    [PILLAR_PROFIT]: { disabled: !profitScore },
  })
  const objectiveSelected = (id) => +objectiveId === id

  const objectiveTemplate = (sdg, url, selection = objectiveSelected(sdg)) => ({
    objectiveId: sdg,
    selected: selection,
    url,
  })

  const environmentalObjectives = PILLARS_OBJECTIVES[
    PILLAR_ENVIRONMENTAL
  ].map((sdg) => objectiveTemplate(sdg, `/score/environmental/${sdg}`))

  const socialObjectives = PILLARS_OBJECTIVES[PILLAR_SOCIAL].map((sdg) =>
    objectiveTemplate(
      sdg,
      `/score/social/${sdg}`,
      [1, 2, 11].includes(sdg)
        ? objectiveSelected(1) || objectiveSelected(2) || objectiveSelected(11)
        : objectiveSelected(sdg)
    )
  )

  const pillarObjectives = PILLARS_OBJECTIVES[PILLAR_PROFIT].map((sdg) =>
    objectiveTemplate(sdg, `/score/financial/${sdg}`)
  )

  const objectives = {
    [PILLAR_ENVIRONMENTAL]: environmentalObjectives,
    [PILLAR_SOCIAL]: socialObjectives,
    [PILLAR_PROFIT]: pillarObjectives,
  }

  const isLoadingPillar = {
    [PILLAR_ENVIRONMENTAL]: isEnvScoreLoading,
    [PILLAR_SOCIAL]: isSocialScoreLoading || isSocialCollectLoading,
    [PILLAR_PROFIT]: isProfitScoreLoading,
  }

  const isPillarError = {
    [PILLAR_ENVIRONMENTAL]: isEnvScoreError,
    [PILLAR_SOCIAL]: isSocialScoreError,
    [PILLAR_PROFIT]: isProfitScoreError,
  }

  if ((currentRootNode && !currentRootNode.has_children) || !score)
    return <Message messageId="beginning" show />

  if (
    isLoadingPillar[pillarId] ||
    isCurrentNodeLoading ||
    isParentOrganizationLoading
  )
    return <Loading />

  if (score?.failed) return <Error />

  return (
    <ObjectiveNavigationTemplate>
      <Page fullWidth={!!score}>
        <AnalyzeTemplate
          parentOrganization={parentOrganization}
          themeType={pillarId}
          objectives={objectives[pillarId]}
          pillars={pillars}
          periodName={currentPeriod?.name}
        >
          {isPillarError[pillarId] ? <Error /> : <ObjectiveSwitch />}
        </AnalyzeTemplate>
      </Page>
    </ObjectiveNavigationTemplate>
  )
}

export default withAuthRequired(ScorePage)
