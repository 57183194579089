import React from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import { round } from "services/units/round"
import { convertValuesToReadable } from "services/units/valueConversion"
import { ML_UNIT, VOLUME_UNITS_KEYS } from "services/units/units"
import MixedChartTemplate from "views/molecules/charts/template/MixedChartTemplate"

const ChartSDG13WaterConsumptionMolecule = ({
  mixedChart = [],
  defaultUnit,
}) => {
  const { t } = useTranslation()
  const labels = mixedChart.map((websiteData) => websiteData.label)
  const bar = mixedChart.map((websiteData) => websiteData.bar)

  const lineValues = mixedChart.map((websiteData) => websiteData.line * 10)
  const { values: line, unit: lineUnitKey } = convertValuesToReadable(
    lineValues,
    ML_UNIT,
    VOLUME_UNITS_KEYS,
    round
  )

  return (
    <MixedChartTemplate
      title={t("score:score.6.chart6.title")}
      leftDataInputs={line}
      leftDataBarTitle={t("score:score.6.chart6.legend.line")}
      rightDataInputs={bar}
      rightDataBarTitle={t("score:score.6.chart6.legend.bar")}
      labelKeys={labels}
      leftUnitPath="units:units.volume"
      leftUnitKey={lineUnitKey}
      rightUnitPath="units:units.volume"
      rightUnitKey={mixedChart[0]?.bar_unit || defaultUnit}
      helpText={t(`score:score.6.chart6.help`)}
    />
  )
}

ChartSDG13WaterConsumptionMolecule.propTypes = {
  mixedChart: PropTypes.array,
  defaultUnit: PropTypes.string,
}

ChartSDG13WaterConsumptionMolecule.defaultProps = {
  defaultUnit: "l_unit",
}

export default ChartSDG13WaterConsumptionMolecule
