import React from "react"
import { useTranslation } from "react-i18next"
import ChartODD13EquipmentTypeVolumeTotalGHGMolecule from "views/molecules/canvases/charts/ChartODD13EquipmentTypeVolumeTotalGHGMolecule"

const ChartOdd13WorkplaceVolumeAverageGhg = ({ chartData }) => {
  const { t } = useTranslation()
  return (
    <ChartODD13EquipmentTypeVolumeTotalGHGMolecule
      mixedChart={chartData}
      type="workplace"
      helpText={t(
        `analyze:it_domain.per_node.workplace.chartRegionWorkplaceBreakdown.help`
      )}
    />
  )
}

export default ChartOdd13WorkplaceVolumeAverageGhg
