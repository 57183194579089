import React from "react"
import ChartODD13EquipmentTypeVolumeAverageGHGMolecule from "views/molecules/canvases/charts/ChartODD13EquipmentTypeVolumeAverageGHGMolecule"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import ChartODD13RegionHostingBreakdownMolecule from "views/molecules/canvases/charts/ChartODD13RegionHostingBreakdownMolecule"
import ChartODD13EquipmentTypeVolumeTotalGHGMolecule from "views/molecules/canvases/charts/ChartODD13EquipmentTypeVolumeTotalGHGMolecule"

const ChartAssetsHostingDispatcher = ({ envScore }) => {
  const { t } = useTranslation()

  if (envScore.odd13_global_node_children_defined) {
    return <ChartODD13RegionHostingBreakdownMolecule score={envScore} />
  } else {
    return envScore?.odd13_business_equipment_type_average ? (
      <ChartODD13EquipmentTypeVolumeAverageGHGMolecule
        mixedChart={envScore?.odd13_business_equipment_type_average}
        type="business"
        helpText={t(
          `analyze:it_domain.per_node.workplace.chartRegionWorkplaceBreakdown.help`
        )}
      />
    ) : (
      <ChartODD13EquipmentTypeVolumeTotalGHGMolecule
        mixedChart={envScore?.odd13_internal_hosting_volume_emission_equipments}
        type="hosting"
        helpText={t(
          `analyze:it_domain.per_node.hosting.chartRegionHostingBreakdown.help`
        )}
      />
    )
  }
}

ChartAssetsHostingDispatcher.propTypes = {
  envScore: PropTypes.object.isRequired,
}

export default ChartAssetsHostingDispatcher
