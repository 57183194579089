import React, { useEffect } from "react"
import { Page } from "@shopify/polaris"
import Objective13ITDomainAnalysisOrganism from "views/organisms/canvases/charts/Objective13ITDomainAnalysisOrganism"
import Loading from "components/widgets/Loading"
import { useAppContext } from "components/AppContext"
import AnalyzeTemplate from "views/templates/AnalyzeTemplate"
import {
  PILLAR_ENVIRONMENTAL,
  PILLAR_PROFIT,
  PILLAR_SOCIAL,
} from "utils/constants/pillars"
import ObjectiveNavigationTemplate from "views/templates/ObjectiveNavigationTemplate"
import withAuthRequired from "../../components/auth/withAuthRequired"
import usePillars from "views/organisms/pillars/usePillars"
import ScoreLoaderTemplate from "views/templates/ScoreLoaderTemplate"
import { useITDomainTabs } from "components/ITDomainTabs"
import { useHistory, useParams } from "react-router-dom"

const AnalyzeITDomainPage = () => {
  const {
    currentPeriod,
    currentNode,
    parentOrganization,
    envScore,
    isEnvScoreLoading,
    isCurrentNodeLoading,
  } = useAppContext()

  const { themeId } = useParams()
  const history = useHistory()

  const { tabs, redirectIfNoAvailableTab } = useITDomainTabs({
    envScore,
    currentNode,
  })

  const pillars = usePillars({
    pageName: "/analyze-ghg-scopes",
    [PILLAR_SOCIAL]: { disabled: true },
    [PILLAR_PROFIT]: { disabled: true },
  })

  useEffect(() => {
    redirectIfNoAvailableTab(isEnvScoreLoading, isCurrentNodeLoading)
  }, [
    themeId,
    isEnvScoreLoading,
    isCurrentNodeLoading,
    envScore,
    tabs,
    history,
  ])

  const objectives = [
    {
      objectiveId: 13,
      selected: true,
      url: "/analyze/it-domain/environmental/13/equipments",
    },
  ]

  if (isEnvScoreLoading || isCurrentNodeLoading) {
    return <Loading />
  }

  return (
    <ObjectiveNavigationTemplate>
      <Page fullWidth={!!envScore}>
        <ScoreLoaderTemplate pillarScore={envScore}>
          <AnalyzeTemplate
            parentOrganization={parentOrganization}
            themeType={PILLAR_ENVIRONMENTAL}
            objectives={objectives}
            pillars={pillars}
            tabs={tabs}
            periodName={currentPeriod?.name}
          >
            <Objective13ITDomainAnalysisOrganism envScore={envScore} />
          </AnalyzeTemplate>
        </ScoreLoaderTemplate>
      </Page>
    </ObjectiveNavigationTemplate>
  )
}

export default withAuthRequired(AnalyzeITDomainPage)
