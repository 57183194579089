import React from "react"
import { useTranslation } from "react-i18next"
import { Bar } from "react-chartjs-2"
import { round } from "services/units/round"
import useTranslatedLabels from "services/charts/translatedLabels"
import { BOTTOM_LEGEND, HIDDEN_LEGEND } from "services/charts/legend"
import { dataLabelsPlugin } from "services/charts/plugins/datalabeles"
import { getColorByPillar, getCompareBarColors } from "services/charts/ColorBar"
import { yAxes } from "services/charts/yAxes"
import { DEFAULT_OPTIONS } from "services/charts/default"
import { ChartContainer } from "views/organisms/organization/tables/ChartContainer"
import PropTypes from "prop-types"
import { convertValuesToReadable } from "services/units/valueConversion"
import { DETERMINE_UNITS } from "services/units/units"

const BarChartTemplate = ({
  title,
  data,
  compareBarColors,
  modifiedBarColors,
  dataBarTitle,
  labelPath,
  labelKeys,
  unitPath,
  unitKey,
  pillar,
  showLegend,
  helpText,
}) => {
  const { t } = useTranslation()
  const translatedLabels = useTranslatedLabels(labelPath, labelKeys)
  const convertedValue = convertValuesToReadable(
    data,
    unitKey,
    DETERMINE_UNITS(unitKey)
  )
  const roundedData = convertedValue.values.map(round)
  const backgroundColor = compareBarColors
    ? getCompareBarColors(data)
    : modifiedBarColors
    ? modifiedBarColors
    : getColorByPillar(pillar)

  const datasets = {
    labels: translatedLabels,
    datasets: [
      {
        label: dataBarTitle || "",
        data: roundedData,
        backgroundColor: backgroundColor,
      },
    ],
  }

  const options = {
    ...DEFAULT_OPTIONS,
    legend: HIDDEN_LEGEND,
    ...yAxes(t(`${unitPath}.${unitKey}`)),
    plugins: dataLabelsPlugin(undefined, undefined),
  }

  if (showLegend) {
    options.legend = BOTTOM_LEGEND
  }

  return (
    <ChartContainer title={title} helpText={helpText} pillar={pillar}>
      <Bar data={datasets} options={options} />
    </ChartContainer>
  )
}

export default BarChartTemplate

BarChartTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  compareBarColors: PropTypes.bool,
  dataBarTitle: PropTypes.string,
  labelPath: PropTypes.string,
  labelKeys: PropTypes.array.isRequired,
  unitPath: PropTypes.string.isRequired,
  unitKey: PropTypes.string.isRequired,
  pillar: PropTypes.string,
  helpText: PropTypes.string.isRequired,
  modifiedBarColors: PropTypes.array,
  showLegend: PropTypes.bool,
}

BarChartTemplate.defaultProps = {
  pillar: "environmental",
  compareBarColors: false,
  modifiedBarColors: null,
  dataBarTitle: "",
  showLegend: false,
}
