import React from "react"
import CumulativeBarChartTemplate from "views/molecules/charts/template/CumulativeBarChartTemplate"
import { COMPARE_LEGENDS_GHG_SPLIT_TREND } from "services/charts/legend"
import { useTranslation } from "react-i18next"

const prefixTranslation = "analyze:compare."
const CompareWebsiteOnlyChar3 = ({ currentNode, comparedScores }) => {
  const { t } = useTranslation()
  const dataChart2Only1Website = () =>
    comparedScores.chart2_only_1_website.datasets.map((dataset) => {
      return {
        label: t(`score:score.trends.${dataset.label}`),
        data: dataset.data,
        backgroundColor: dataset.background_color,
      }
    })

  if (comparedScores.chart2_only_1_website) {
    return (
      <CumulativeBarChartTemplate
        title={t(prefixTranslation + "charts.chart2Only1Website.title")}
        datasets={dataChart2Only1Website()}
        labelKeys={comparedScores.chart2_only_1_website.label_keys}
        unitPath="units:units.emission"
        unitKey={comparedScores.chart2_only_1_website.unit_key}
        helpText={t(prefixTranslation + "charts.chart2Only1Website.help")}
        modifiedLegend={COMPARE_LEGENDS_GHG_SPLIT_TREND}
        useBackgroundColorsInDatasets
        doNotConvert
      />
    )
  }

  if (currentNode.has_websites_only) {
    return (
      <CumulativeBarChartTemplate
        title={t(prefixTranslation + "charts.chart2WebsiteOnly.title")}
        datasets={comparedScores.chart2_website_only.datasets}
        labelKeys={comparedScores.chart2_website_only.label_keys}
        unitPath="units:units.emission"
        unitKey={comparedScores.chart2_website_only.unit_key}
        helpText={t(prefixTranslation + "charts.chart2WebsiteOnly.help")}
        hiddenLegend
        compareBarColors
        doNotConvert
      />
    )
  }
}

export default CompareWebsiteOnlyChar3
