import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import { compareNodeLabels } from "services/score/charts/labels/compareNodeLabels"
import MixedChartTemplate from "views/molecules/charts/template/MixedChartTemplate"

const ChartODD13VolumeEmissionsTopLocMolecule = ({ mixedChart = [] }) => {
  const { t } = useTranslation()
  const translationPrefix = "score:score.13."

  const labels = mixedChart.map((node) =>
    compareNodeLabels(node.label, true, true)
  )
  const bar = mixedChart.map((node) => node.bar)
  const line = mixedChart.map((node) => node.line)

  return (
    <MixedChartTemplate
      title={t(translationPrefix + "chartVolumeEmissionsTopLoc.title")}
      leftDataInputs={line}
      leftDataBarTitle={t(
        translationPrefix + "chartVolumeEmissionsTopLoc.legend.line"
      )}
      rightDataInputs={bar}
      rightDataBarTitle={t(
        translationPrefix + "chartVolumeEmissionsTopLoc.legend.bar"
      )}
      labelKeys={labels}
      leftUnitPath="units:units.emission"
      leftUnitKey="kg_unit"
      rightUnitPath="units:units.count_no_conversion"
      rightUnitKey="hash"
      hideUnitOnBar
      helpText={t(
        `analyze:it_domain.consolidated.workplace.chartVolumeEmissionsTopLoc.help`
      )}
    />
  )
}

ChartODD13VolumeEmissionsTopLocMolecule.propTypes = {
  mixedChart: PropTypes.array.isRequired,
}

export default ChartODD13VolumeEmissionsTopLocMolecule
