import PropTypes from "prop-types"
import React from "react"
import {
  TextChart,
  TextChartMajor,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import { useTranslation } from "react-i18next"
import Config from "assets/configs/score.js"
import "stylesheets/ScorePage/HorizontalBarChart.scss"
import { COLORS } from "utils/constants/colors"
import ScoreLayoutOrganism from "views/organisms/scores/ScoreLayoutOrganism"
import NoChartDataOrganism from "views/molecules/canvases/charts/profit/NoChartDataOrganism"
import HorizontalCumulativeBarChartTemplate from "views/molecules/charts/template/HorizontalCumulativeBarChartTemplate"
import DonutChartTemplate from "views/molecules/charts/template/DonutChartTemplate"
import StackedBarChartTemplate from "views/molecules/charts/template/StackedBarChartTemplate"

const NoPartners = () => {
  const { t } = useTranslation()

  return <NoChartDataOrganism description={t("score:score.17.no_partners")} />
}

const Objective17Score = ({ score }) => {
  const { t } = useTranslation()
  const chart1 = () => {
    if (score.inventories.partners === 0) return <NoPartners />

    const content = {
      kpi: score.odd17_partners_good_sustainable_grade_percentage,
      kpi_unit: "%",
      kpi_text: t(`score:score.17.chart1.kpi_text`),
      reference: score.odd17_partners_budget_good_sustainable_grade_percentage,
      reference_unit: " %",
      reference_text: t(`score:score.17.chart1.reference_text`),
    }

    const contentForDisplay = (
      <TextChartMajor content={content} noSubtextHover />
    )

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(`score:score.17.chart1.help`)}
      >
        <TextChart content={contentForDisplay} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }

  const chart2 = () => {
    const content = score.odd17_partners_grade_per_budget_weighted_average
      ? {
          kpi: score.odd17_partners_grade_per_budget_weighted_average,
          kpi_text: t(`score:score.17.chart2.kpi_text`),
        }
      : {
          kpi: score.sustainable_partnerships_percentage,
          kpi_text: t(`score:score.17.chart2.backup_text`),
          kpi_unit: "%",
        }

    const contentForDisplay = (
      <TextChartMinor content={content} noSubtextHover />
    )

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(`score:score.17.chart2.help`)}
      >
        <TextChart content={contentForDisplay} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }

  const chart3 = () => {
    if (score.inventories.partners === 0) return <NoPartners />
    const odd17_partner_grouped_by_reach_chart =
      score.odd17_partner_grouped_by_reach_chart
    const data = odd17_partner_grouped_by_reach_chart.map(
      (dataset) => dataset.value
    )
    const labels = odd17_partner_grouped_by_reach_chart.map((dataset) =>
      t(`score:score.17.chart3.labels.${dataset.label_key}`)
    )
    const legendOrder = [
      t(`score:score.17.chart3.labels.worldwide`),
      t(`score:score.17.chart3.labels.continent`),
      t(`score:score.17.chart3.labels.country`),
      t(`score:score.17.chart3.labels.local`),
    ]

    function sortByLegendOrder(labels, legendOrder) {
      return legendOrder
        .filter((legend) => labels.indexOf(legend) !== -1)
        .map((legend) => labels[labels.indexOf(legend)])
    }

    const sortedLabels = sortByLegendOrder(labels, legendOrder)

    return (
      <DonutChartTemplate
        title={t("score:score.17.chart3.title")}
        data={data}
        labelKeys={sortedLabels}
        unitPath={undefined}
        unitKey={undefined}
        pillar="financial"
        showValueOnBar
        helpText={t("score:score.17.chart3.help")}
      />
    )
  }

  const chart4 = () => {
    if (score.odd17_partner_grouped_by_reach_chart.length === 0)
      return <NoPartners />

    const {
      datasets,
      label_keys,
    } = score.odd17_budget_partner_by_delivery_model_chart

    const colors = [
      [Config.common.colors[2], COLORS.warning.lighter, COLORS.danger.darker],
      [Config.common.colors[1], COLORS.warning.light, COLORS.danger.light],
      [Config.common.colors[0], COLORS.warning.darker, COLORS.danger.secondary],
    ]

    const label_key = (item, index) =>
      item.label_key
        ? {
            label: t(`score:score.17.chart4.labels.${item.label_key}`),
            data: item.values,
            backgroundColor: colors[index],
            stack: 1,
          }
        : null

    const formattedDatasets = datasets.map(label_key).filter(Boolean)

    const labels = label_keys.map((item) =>
      t(`score:score.17.chart4.labels.${item}`)
    )

    return (
      <HorizontalCumulativeBarChartTemplate
        helpText={t("score:score.17.chart4.help")}
        title={t("score:score.17.chart4.title")}
        datasets={formattedDatasets}
        labelKeys={labels}
        unitPath="units:units.money"
        unitKey="k_euro_unit"
        pillar="financial"
        useBackgroundColorsInDatasets
        showUnitOnBar
        showLabelsOnBar
        hiddenLegend
      />
    )
  }

  const sustainableWeight = () => {
    const content = {
      kpi: score.odd17_partners_weight_per_budget_weighted_average,
      kpi_unit: "%",
      kpi_text: t(`score:score.17.chart5.sustainableWeight.kpi_text`),
    }

    const contentForDisplay = (
      <TextChartMinor content={content} noSubtextHover />
    )

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(`score:score.17.chart5.sustainableWeight.help`)}
      >
        <TextChart content={contentForDisplay} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }

  const clausesImportance = () => {
    const content = {
      kpi: score.odd17_partnerships_sourcing_csr_clauses_importance_maturity,
      kpi_unit: "",
      kpi_text: t(`score:score.17.chart5.clausesImportance.kpi_text`),
    }

    const contentForDisplay = (
      <TextChartMinor content={content} noSubtextHover />
    )

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(`score:score.17.chart5.clausesImportance.help`)}
      >
        <TextChart content={contentForDisplay} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }

  const chart6 = () => {
    const {
      sustainable_values,
      not_sustainable_values,
      label_keys,
    } = score?.odd17_sustainable_chart
    const data = {
      labels: label_keys.map((item) =>
        t(`score:score.17.chart6.labels.${item}`)
      ),
      datasets: [
        {
          label: t("score:score.17.chart6.labels.sustainable"),
          data: sustainable_values,
          values: sustainable_values,
          stack: 1,
          backgroundColor: Config.common.colors[1],
        },
        {
          label: t("score:score.17.chart6.labels.not_sustainable"),
          data: not_sustainable_values,
          values: not_sustainable_values,
          stack: 1,
          backgroundColor: COLORS.danger.secondary,
        },
      ],
    }

    return (
      <StackedBarChartTemplate
        title={t("score:score.17.chart6.title")}
        datasets={data.datasets}
        labelKeys={data.labels}
        unitPath="units:units"
        unitKey="percentage"
        hiddenLegend
        useBackgroundColorsInDatasets
        helpText={t("score:score.17.chart6.help")}
      />
    )
  }

  const chart5 =
    score.odd17_partners_weight_per_budget_weighted_average > 0
      ? sustainableWeight()
      : clausesImportance()

  return (
    <ScoreLayoutOrganism
      maturity={score.odd17_maturity ?? 0}
      chart1={chart1()}
      chart2={chart2()}
      chart3={chart3()}
      chart4={chart4()}
      chart5={chart5}
      chart6={chart6()}
    />
  )
}

Objective17Score.propTypes = {
  score: PropTypes.object.isRequired,
}

export default Objective17Score
