import React from "react"
import {
  generateCollectedEquipmentName,
  reusablePersonalWorkplaceEquipmentTypes
} from "helpers/CollectHelper"
import { TextField } from "components/widgets/CollectPage/forms/fields/CollectFields"
import PropTypes from "prop-types"
import { WORKPLACE } from "utils/constants/equipmentDomains"
import _ from "lodash"
import { useFormContext } from "components/widgets/CollectPage/forms/FormContext"

const ResponsibleConsumptionTableBodyLines = ({ equipmentTypes, domain }) => {
  const { formData } = useFormContext()
  const isReusableWorkplaceEquipment = (equipmentType) =>
    domain === WORKPLACE &&
    reusablePersonalWorkplaceEquipmentTypes.some(
      (equipment) => equipment === equipmentType
    )

  let getUpstreamLifetimeArray = []
  const hiddenUpstreamLifetimeColumn = equipmentTypes.every((equipmentType) => {
    const collectedSecondHandRatio = _.get(formData, `collected_equipments.[${domain}].[${equipmentType}].second_hand_ratio`, null)
    getUpstreamLifetimeArray.push(collectedSecondHandRatio)
    return +collectedSecondHandRatio === 0 || collectedSecondHandRatio === null || collectedSecondHandRatio === undefined
  })

  return equipmentTypes.map((equipmentType, index) => {
    const collectedSecondHandRatio = _.get(formData, `collected_equipments.[${domain}].[${equipmentType}].second_hand_ratio`, null)
    const hiddenUpstreamLifetime = +collectedSecondHandRatio === 0 || collectedSecondHandRatio === null || collectedSecondHandRatio === undefined
    getUpstreamLifetimeArray.push(collectedSecondHandRatio)

    return (
      <tr key={index}>
        <td>{generateCollectedEquipmentName(equipmentType)}</td>
        <td className="top">
          <TextField
            id={`collected_equipments.[${domain}].[${equipmentType}].second_hand_ratio`}
            type="number"
          />
        </td>
        <td className="top" hidden={hiddenUpstreamLifetimeColumn}>
          {
            !hiddenUpstreamLifetime && (
              <TextField
                id={`collected_equipments.[${domain}].[${equipmentType}].upstream_lifetime`}
                type="number"
                hidden={hiddenUpstreamLifetime}
              />
            )
          }
        </td>
        <td className="top">
          {isReusableWorkplaceEquipment(equipmentType) && (
            <TextField
              id={`collected_equipments.[${domain}].[${equipmentType}].internal_reuse_ratio`}
              type="number"
            />
          )}
        </td>
      </tr>
    )
  })
}

export default ResponsibleConsumptionTableBodyLines

ResponsibleConsumptionTableBodyLines.propTypes = {
  equipmentTypes: PropTypes.array.isRequired,
  domain: PropTypes.string.isRequired
}
