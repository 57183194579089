import React from "react"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import MixedChart from "components/widgets/ScorePage/widgets/MixedChart"
import NumberFormatterApp from "services/apps/numberFormatterApp"
import { EMISSION_UNIT } from "utils/constants/numbers"
import ChartSDG13WaterConsumptionMolecule from "views/molecules/canvases/charts/websites/ChartSDG13WaterConsumptionMolecule"
import { COLORS } from "utils/constants/colors"
import { round } from "services/units/round"

const ChartQuantitativeCo2ImpactsMolecule = ({
  mixedChart,
  defaultUnit,
  title,
}) => {
  const { t } = useTranslation()

  const labels = mixedChart?.map((websiteData) => websiteData.label)
  const bar = mixedChart?.map((websiteData) => round(websiteData.bar))
  const line = mixedChart?.map((websiteData) => websiteData.line)
  const barUnit = mixedChart?.[0]?.bar_unit || defaultUnit

  const barData = {
    label: t(
      "score:score.13.chartGHGWebsitesEmissions.modal.chartQuantitativeCO2Impacts.legend.bar"
    ),
    data: bar,
  }

  const lineData = {
    label: t(
      "score:score.13.chartGHGWebsitesEmissions.modal.chartQuantitativeCO2Impacts.legend.line"
    ),
    data: line,
  }

  const data = { bar_data: barData, line_data: lineData }

  return (
    <HelpWidgetChartInteractiveMolecule
      text={t(`analyze:websites.ghg_emissions.help`)}
    >
      <MixedChart
        title={title}
        data_inputs={data}
        labels={labels}
        barBackgroundColors={COLORS.planet.primary}
        unit_left_axis={t(
          "score:score.13.chartGHGWebsitesEmissions.modal.chartQuantitativeCO2Impacts.unit_left_axis"
        )}
        unit_right_axis={NumberFormatterApp.getI18nUnit(EMISSION_UNIT, barUnit)}
      />
    </HelpWidgetChartInteractiveMolecule>
  )
}

ChartQuantitativeCo2ImpactsMolecule.propTypes = {
  mixedChart: PropTypes.array.isRequired,
  defaultUnit: PropTypes.string,
  title: PropTypes.string.isRequired,
}

ChartSDG13WaterConsumptionMolecule.defaultProps = {
  defaultUnit: "l_unit",
}

export default ChartQuantitativeCo2ImpactsMolecule
