import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { COLORS } from "utils/constants/colors"
import MixedChartTemplate from "views/molecules/charts/template/MixedChartTemplate"
import BarChartTemplate from "views/molecules/charts/template/BarChartTemplate"

export const EMPTY_LINE_LEGEND = [
  (legendItem) => (legendItem.fillStyle = COLORS.transparent),
]

const ChartOdd13ExternalAppEmissions = ({ score }) => {
  const { t } = useTranslation()
  const tPrefix = "analyze:it_domain.consolidated.saas.applications"

  const { bar, bar_unit, line, line_unit } = score?.applications

  const units =
    line_unit === null || line_unit === undefined ? "g_unit" : line_unit

  if (line.some((value) => value === null)) {
    return (
      <BarChartTemplate
        title={t(`${tPrefix}.title`)}
        data={bar}
        labelKeys={score?.applications?.label}
        unitPath="units:units.emission"
        unitKey={bar_unit}
        helpText={t(`${tPrefix}.help`)}
      />
    )
  }

  return (
    <MixedChartTemplate
      title={t(`${tPrefix}.title`)}
      leftDataInputs={line}
      leftDataBarTitle={t(`${tPrefix}.emission_factor`)}
      rightDataInputs={bar}
      rightDataBarTitle={t(`${tPrefix}.emissions`)}
      labelKeys={score?.applications?.label}
      leftUnitPath="units:units.emission_per_GB"
      leftUnitKey={units}
      rightUnitPath="units:units.emission"
      rightUnitKey={bar_unit}
      helpText={t(`${tPrefix}.help`)}
    />
  )
}

ChartOdd13ExternalAppEmissions.propTypes = {
  score: PropTypes.shape({
    applications: PropTypes.object.isRequired,
  }).isRequired,
}

export default ChartOdd13ExternalAppEmissions
